var EStoCriteria = {
    equal: "is",
    not_equal: "is not",
    is_null: "is null",
    is_not_null: "is not null",
    gt: "greater than",
    gte: "greater than equal to",
    lt: "less than",
    lte: "less than equal to",
    includes: "includes",
    excludes: "excludes",
};
export var createJsonFilters = function (ESfilter) {
    var result = [];
    for (var _i = 0, ESfilter_1 = ESfilter; _i < ESfilter_1.length; _i++) {
        var esRow = ESfilter_1[_i];
        //Checking for "is/(is not)" criterias :
        if (Object.keys(esRow).includes("allowed_values") &&
            esRow["allowed_values"] != null) {
            for (var _a = 0, _b = esRow["allowed_values"]; _a < _b.length; _a++) {
                var val = _b[_a];
                result.push({
                    column: esRow.field_name,
                    criteria: esRow.invert_filter ? "is not" : "is",
                    value: String(val),
                });
            }
        }
        //Checking for "is null, is not null" criterias":
        if (esRow["is_null"] != null) {
            result.push({
                column: esRow.field_name,
                criteria: esRow.is_null ? "is null" : "is not null",
            });
        }
        if (esRow["wildcard"] != null) {
            // remove the asterisks on either end
            result.push({
                column: esRow.field_name,
                criter: esRow.invert_filter ? "excludes" : "includes",
                value: esRow["wildcard"].slice(1, -1),
            });
        }
        if (Object.keys(esRow).includes("includes_text") &&
            esRow["includes_text"] != null) {
            //Checking for "includes/(excludes)" criterias :
            result.push({
                column: esRow.field_name,
                criter: esRow.invert_filter ? "excludes" : "includes",
                value: String(esRow["includes_text"]),
            });
        }
        //Checking for "range" criterias :
        if (Object.keys(esRow).includes("range") && esRow["range"] != null) {
            for (var _c = 0, _d = Object.keys(esRow["range"]); _c < _d.length; _c++) {
                var keyRange = _d[_c];
                if (esRow["range"][keyRange] == null)
                    continue;
                result.push({
                    column: esRow.field_name,
                    criter: EStoCriteria[keyRange],
                    value: String(esRow["range"][keyRange]),
                });
            }
        }
    }
    return result;
};
