var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Stack, Typography } from "@mui/material";
import { Button } from "@inspera/component-library";
import { ErrorType } from "../../Model/model";
import { apiCore } from "../../Api/apiCore";
import useStore from "../../Store/store";
import { Api } from "../../Api/api";
import { handleError } from "../../Utils/handleError";
import { hashCode } from "../../Utils/util";
import { VISUALISATION_VERSION } from "../../Utils/constants";
var OverwriteView = function (_a) {
    var _b;
    var view = _a.view, setModal = _a.setModal, setColumnChange = _a.setColumnChange, setQueryChange = _a.setQueryChange, setAggHeaderChange = _a.setAggHeaderChange, setVisChange = _a.setVisChange;
    var _c = useStore(), token = _c.token, current = _c.current, setError = _c.setError, setView = _c.setView, mode = _c.mode, chart = _c.chart, aggHeaders = _c.aggHeaders, queryOptions = _c.queryOptions, columns = _c.columns, columnCategories = _c.columnCategories, userId = _c.userId, deduplicate = _c.deduplicate;
    var updateHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        var visibleColCat, visibleCol, newQueryOptions, chartOptions, body, hashUserId;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    visibleColCat = [];
                    columnCategories.forEach(function (cat) {
                        if (cat.show)
                            visibleColCat.push(cat.categoryName);
                    });
                    visibleCol = [];
                    columns.forEach(function (col) {
                        if (col.show)
                            visibleCol.push(col.column_name);
                    });
                    newQueryOptions = JSON.parse(JSON.stringify(queryOptions));
                    newQueryOptions.columns = visibleCol;
                    chartOptions = JSON.parse(JSON.stringify(chart));
                    (_a = chartOptions.dataset) === null || _a === void 0 ? true : delete _a.source;
                    body = {
                        parameters: {
                            body: newQueryOptions,
                            ddt_frontend: {
                                visualization: ((_b = chart === null || chart === void 0 ? void 0 : chart.dataset) === null || _b === void 0 ? void 0 : _b.source)
                                    ? {
                                        version: VISUALISATION_VERSION,
                                        chart: chartOptions,
                                    }
                                    : undefined,
                                column_categories: visibleColCat,
                                agg_headers: aggHeaders,
                                mode: mode,
                                deduplicate: deduplicate,
                            },
                        },
                        data_source: (_c = current === null || current === void 0 ? void 0 : current.source) === null || _c === void 0 ? void 0 : _c.data_source_url_slug,
                    };
                    return [4 /*yield*/, apiCore
                            .updateView(token, view === null || view === void 0 ? void 0 : view.view_id, body)
                            .catch(function (err) {
                            handleError(setError, err, ErrorType.ERROR, "");
                        })];
                case 1:
                    _d.sent();
                    return [4 /*yield*/, Api.get("/view_definitions", token)
                            .then(function (views) {
                            setView(views);
                            if (setColumnChange)
                                setColumnChange(false);
                            if (setQueryChange)
                                setQueryChange(false);
                            if (setAggHeaderChange)
                                setAggHeaderChange(false);
                            if (setVisChange)
                                setVisChange(false);
                        })
                            .catch(function (err) {
                            handleError(setError, err, ErrorType.ERROR, "");
                        })];
                case 2:
                    _d.sent();
                    setModal(false);
                    hashUserId = hashCode(String(userId));
                    localStorage.removeItem("options".concat(hashUserId));
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Stack, __assign({ spacing: 3, sx: { width: 400 } }, { children: [_jsx(Divider, {}), _jsxs(Typography, { children: ["Are you sure you want to overwrite ", _jsx(Typography, __assign({ component: "span", display: "inline", fontWeight: "bold" }, { children: (_b = current === null || current === void 0 ? void 0 : current.viewDef) === null || _b === void 0 ? void 0 : _b.name }))] }), _jsxs(Stack, __assign({ direction: "row", spacing: 3 }, { children: [_jsx(Button, __assign({ color: "primary", variant: "contained", onClick: updateHandler }, { children: "Update" })), _jsx(Button, __assign({ color: "info", variant: "contained", onClick: function () { return setModal(false); } }, { children: "Cancel" }))] }))] })));
};
export default OverwriteView;
