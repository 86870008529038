var getColumnIndex = function (colName, columns) {
    for (var i = 0; i < columns.length; i++) {
        if (columns[i]["column_name"] == colName)
            return i;
    }
    return -1;
};
export var filterCheck = function (filterRows, columns, itemBankIds) {
    var copyFilterRows = JSON.parse(JSON.stringify(filterRows));
    var errorMsg = "";
    for (var _i = 0, copyFilterRows_1 = copyFilterRows; _i < copyFilterRows_1.length; _i++) {
        var obj = copyFilterRows_1[_i];
        var columnName = obj.column;
        var columnIndex = getColumnIndex(columnName, columns);
        if (columnIndex == -1)
            continue;
        var colMetadata = columns[columnIndex];
        var currErrorMsg = "";
        if (obj.column == "item_bank_id" &&
            !isNaN(obj.value) &&
            obj.criteria == "is") {
            if (!itemBankIds.includes(parseInt(obj.value))) {
                errorMsg += "You don't have access to Item Bank ".concat(obj.value, ".");
            }
        }
        switch (colMetadata.column_data_type) {
            case "short":
            case "long":
            case "float":
                if (isNaN(obj.value)) {
                    currErrorMsg = "Only numbers allowed in ".concat(columns[columnIndex].human_friendly_column_name, " column.");
                    if (!errorMsg.includes(currErrorMsg))
                        errorMsg += currErrorMsg;
                }
                else {
                    if (obj.value.trim().length == 0 &&
                        !["is null", "is not null"].includes(obj.criteria)) {
                        errorMsg += "".concat(colMetadata.human_friendly_column_name, " cannot be empty.");
                    }
                }
                break;
            case "boolean":
                if (obj.value != "true" &&
                    obj.value != "false" &&
                    !["is null", "is not null"].includes(obj.criteria)) {
                    currErrorMsg = "Only true/false allowed in ".concat(columns[columnIndex].human_friendly_column_name, " column.");
                    if (!errorMsg.includes(currErrorMsg))
                        errorMsg += currErrorMsg;
                }
                break;
            case "text":
            case "object":
                break;
            default:
                console.log("Unknown column data type : " + colMetadata.column_data_type);
        }
    }
    return errorMsg;
};
