var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Modal } from "@inspera/component-library";
import { Alert, Box, Grid, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import useStore from "../../Store/store";
import FunctionsIcon from "@mui/icons-material/Functions";
import ListIcon from "@mui/icons-material/List";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SortIcon from "@mui/icons-material/Sort";
import CalendarMonthIcon from "@mui/icons-material/CalendarToday";
import PresetTable from "../Modal/PresetTable/PresetTable";
import { AggregationMenu, ColCatMenu, FilterMenu, SortMenu, SourceMenu, TimeMenu, } from "./Filters";
import { MenuOptionsBtn } from "./Filters/MenuOptionsBtn";
import CollapseBtn from "./CollapseBtn";
import VisualisationMenu from "./Filters/Visualisation/VisualisationMenu";
export var FilterWizard = function (_a) {
    var collapse = _a.collapse, setCollapse = _a.setCollapse, isColumnsLoading = _a.isColumnsLoading;
    var _b = useStore(), columns = _b.columns, variableToFriendlyColumn = _b.variableToFriendlyColumn, sortCount = _b.sortCount, presets = _b.presets;
    var _c = useState(false), disable = _c[0], setDisable = _c[1];
    var _d = useState(false), openModal = _d[0], setOpenModal = _d[1];
    var handleOpenSnack = function () {
        setDisable(true);
    };
    var handleCloseModal = function () {
        setOpenModal(false);
    };
    return (_jsxs(Grid, __assign({ item: true, width: collapse ? "1rem" : "264px", height: "calc(100vh - 229px)", bgcolor: "white", borderRight: "1px solid #EDEDED", sx: { transition: "width 0.2s" } }, { children: [_jsx(CollapseBtn, { collapse: collapse, setStatus: setCollapse }), presets && presets.length > 0 && (_jsx(Box, __assign({ sx: { textAlign: "right", padding: "10px 17px 15px 0" } }, { children: _jsx(Typography, __assign({ onClick: function () { return setOpenModal(true); }, component: "span", sx: { color: "#004080", cursor: "pointer" } }, { children: "Use presets" })) }))), _jsx(SourceMenu, { collapse: collapse }), columns.length > 0 && !isColumnsLoading ? (_jsxs(_Fragment, { children: [_jsx(ColCatMenu, {}), !collapse && _jsx(Divider, { sx: { pt: "25px", mb: "7px" } }), _jsx(FilterMenu, { variableToFriendlyColumn: variableToFriendlyColumn, variant: "normal" }), _jsx(TimeMenu, {}), _jsx(SortMenu, { variableToFriendlyColumn: variableToFriendlyColumn, sortCount: sortCount }), !collapse && _jsx(Divider, { sx: { pt: "25px", mb: "7px" } }), _jsx(AggregationMenu, { variableToFriendlyColumn: variableToFriendlyColumn }), _jsx(VisualisationMenu, { variableToFriendlyColumn: variableToFriendlyColumn })] })) : (_jsxs(_Fragment, { children: [_jsx(MenuOptionsBtn, { label: "Column Category", btnText: "Select Column Categories", icon: _jsx(ListIcon, { sx: { color: "#595959" } }), disabled: true, onClick: handleOpenSnack, collapse: collapse }), !collapse && (_jsx(Divider, { sx: {
                            pt: "25px",
                            mb: "7px",
                            background: !collapse ? "#FFF3DC" : "#FFFFFF",
                        } })), _jsx(MenuOptionsBtn, { label: "Filter", btnText: " Select Filter", icon: _jsx(FilterAltIcon, { sx: { color: "#595959" } }), disabled: true, onClick: handleOpenSnack, collapse: collapse }), _jsx(MenuOptionsBtn, { label: "Date range", btnText: " Select Date Range", icon: _jsx(CalendarMonthIcon, { sx: { color: "#595959" } }), disabled: true, onClick: handleOpenSnack, collapse: collapse }), _jsx(MenuOptionsBtn, { label: "Sort", btnText: " Select Sort", icon: _jsx(SortIcon, { sx: { color: "#595959" } }), disabled: true, onClick: handleOpenSnack, collapse: collapse }), !collapse && _jsx(Divider, { sx: { pt: "25px", mb: "7px" } }), _jsx(MenuOptionsBtn, { label: "Aggregation", btnText: " Select Aggregation", icon: _jsx(FunctionsIcon, { sx: { color: "#595959" } }), disabled: true, onClick: handleOpenSnack, collapse: collapse }), _jsx(MenuOptionsBtn, { label: "Visualisation", btnText: " Select visualisation", icon: _jsx(FunctionsIcon, { sx: { color: "#595959" } }), disabled: true, onClick: handleOpenSnack, collapse: collapse })] })), _jsx(Modal, __assign({ open: openModal, onBackdropClick: handleCloseModal, onClose: handleCloseModal, width: "lg", fullWidth: false }, { children: _jsx(PresetTable, { handleClose: handleCloseModal }) })), _jsx(Snackbar, __assign({ open: disable, autoHideDuration: 6000, onClose: function () { return setDisable(false); } }, { children: _jsx(Alert, __assign({ variant: "filled", onClose: function () { return setDisable(false); }, severity: "error" }, { children: "Select Source before selecting columns or applying filters" })) }))] })));
};
