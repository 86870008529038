var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useEffect } from "react";
import { init, getInstanceByDom } from "echarts";
export function ReactECharts(_a) {
    var option = _a.option, style = _a.style, settings = _a.settings, loading = _a.loading, theme = _a.theme;
    var chartRef = useRef(null);
    useEffect(function () {
        // Initialize chart
        var chart;
        if (chartRef.current !== null) {
            chart = init(chartRef.current, theme);
        }
        // Add chart resize listener
        function resizeChart() {
            chart === null || chart === void 0 ? void 0 : chart.resize();
        }
        window.addEventListener("resize", resizeChart);
        // Return cleanup function
        return function () {
            chart === null || chart === void 0 ? void 0 : chart.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    }, [theme]);
    useEffect(function () {
        //Removing chart and adding it again to dom
        var chart;
        function resizeChart() {
            chart === null || chart === void 0 ? void 0 : chart.resize();
        }
        if (chartRef.current !== null) {
            chart = getInstanceByDom(chartRef.current);
            if (chart) {
                chart === null || chart === void 0 ? void 0 : chart.dispose();
                window.removeEventListener("resize", resizeChart);
            }
            chart = init(chartRef.current, theme);
            window.addEventListener("resize", resizeChart);
            chart.setOption(option, settings);
        }
    }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function
    useEffect(function () {
        // Update chart
        if (chartRef.current !== null) {
            var chart = getInstanceByDom(chartRef.current);
            if (chart)
                loading === true ? chart.showLoading() : chart.hideLoading();
        }
    }, [loading, theme]);
    return (_jsx("div", { ref: chartRef, style: __assign({ width: "100%", height: "100px" }, style) }));
}
