export var createJSONSorts = function (viewSort) {
    var result = [];
    for (var _i = 0, viewSort_1 = viewSort; _i < viewSort_1.length; _i++) {
        var row = viewSort_1[_i];
        if (row.agg_name)
            continue;
        var sortRow = {
            field_name: "",
        };
        sortRow.field_name = row.field_name;
        sortRow.order = row.order;
        result.push(sortRow);
    }
    return result;
};
