import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorPageLayout } from "../Layouts/ErrorPageLayout";
import Desc from "./Desc";
import Title from "./Title";
import pageNotFound from "../../Assets/images/404.svg";
var EmptySource = function () {
    var msg = " You don't have access to any data source. Please ask your admin to grant you DDT permissions ";
    return (_jsxs(ErrorPageLayout, { children: [_jsx(Title, { children: "Oops.. No datasources found" }), _jsx(Desc, { src: pageNotFound, alt: "Warning", desc: msg })] }));
};
export default EmptySource;
