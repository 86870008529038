var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
//TODO: get state setter for categories as props, change the category.select to !category.select onClick
var Category = function (_a) {
    var category = _a.category;
    var selectedStyle = {
        display: "flex",
        cursor: "pointer",
        flexDirection: "column",
        padding: "12px",
        background: "#F0F7FF",
        border: "1px solid #595959",
    };
    var notSelectedStyle = {
        display: "flex",
        cursor: "pointer",
        flexDirection: "column",
        padding: "12px",
        color: "#404040",
    };
    var style = category.show ? selectedStyle : notSelectedStyle;
    return (_jsxs(Box, __assign({ sx: style }, { children: [_jsx(Typography, __assign({ sx: { fontSize: "16px", fontWeight: "500", color: "#404040" } }, { children: category.humanFriendlyCategoryName })), _jsxs(Typography, __assign({ sx: { fontSize: "14px", fontWeight: "400" } }, { children: [category.count, " columns"] }))] })));
};
export default Category;
