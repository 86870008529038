var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Typography, Popper, ClickAwayListener, Grid, } from "@mui/material";
import FocusTrap from "@mui/base/TrapFocus";
import { Button, Checkbox, CloseButton, Input, Tooltip, } from "@inspera/component-library";
import { useEffect, useState } from "react";
import Category from "./Category";
import useStore from "../../../../Store/store";
import ListIcon from "@mui/icons-material/List";
import { Search } from "@mui/icons-material";
import VirtualColumns from "./FetchVirtualCols";
import _ from "lodash";
var ColCatMenu = function () {
    var _a, _b, _c, _d, _e;
    var _f = useStore(), current = _f.current, visibleColumns = _f.visibleColumns, visibleCategories = _f.visibleCategories, virtualColumnsMetadata = _f.virtualColumnsMetadata, setVirtualColumnsMetadata = _f.setVirtualColumnsMetadata, fetchVirtualColumns = _f.fetchVirtualColumns, columns = _f.columns, setColumns = _f.setColumns, columnCategories = _f.columnCategories, setColumnCategories = _f.setColumnCategories, deduplicate = _f.deduplicate, queryOptions = _f.queryOptions, setQueryOptions = _f.setQueryOptions;
    var _g = useState(null), anchorEl = _g[0], setAnchorEl = _g[1];
    //array of filtered columns name
    var _h = useState([]), filteredColumns = _h[0], setFilteredColumns = _h[1];
    var _j = useState([]), orderedByRankColumns = _j[0], setOrderedByRankColumns = _j[1];
    var _k = useState(""), filter = _k[0], setFilter = _k[1];
    var handleClick = function (event) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    var open = Boolean(anchorEl);
    var handleClose = function () { return setAnchorEl(null); };
    var _l = useState("cat"), colCat = _l[0], setColCat = _l[1];
    var _m = useState(false), error = _m[0], setError = _m[1];
    var _o = useState(false), hideShow = _o[0], setHideShow = _o[1];
    //For handling delay when column is toggled with deduplication on
    var _p = useState(), timer = _p[0], setTimer = _p[1];
    var waitTime = 4000;
    useEffect(function () {
        setColCat("cat");
        setFilter("");
        setError(false);
        setHideShow(false);
        var newFilterColumns = [];
        columns.forEach(function (col) {
            newFilterColumns.push(col.column_name);
        });
        setFilteredColumns(newFilterColumns);
        //Columns are already updated in store when current is changed
        //sorting the columns by display order rank(ascending order), this will be used to list the columns in a fix order in columnList function
        var newOrderedByRankColumns = _.cloneDeep(columns).sort(function (a, b) {
            return (Number(a.column_display_order_rank) -
                Number(b.column_display_order_rank));
        });
        setOrderedByRankColumns(newOrderedByRankColumns);
    }, [current]);
    var handleInput = function (e) {
        setColCat("col");
        setFilter(e.target.value);
        var newFilterColumns = [];
        columns.forEach(function (col) {
            if (new RegExp(".*" + e.target.value + ".*", "ig").test(col.human_friendly_column_name))
                newFilterColumns.push(col.column_name);
        });
        if (newFilterColumns.length == 0)
            setError(true);
        else
            setError(false);
        setFilteredColumns(newFilterColumns);
    };
    var menuSelectStyle = {
        fontSize: "16px",
        fontWeight: "500",
        textDecoration: "underline",
        textDecorationColor: "#004080",
        textDecorationThickness: "3px",
        textUnderlineOffset: "9px",
        cursor: "pointer",
    };
    var handleCategoryToggle = function (i, categoryName) {
        var newCategories = __spreadArray([], columnCategories, true);
        var currentShow = columnCategories[i].show;
        newCategories[i] = __assign(__assign({}, newCategories[i]), { show: !currentShow });
        setColumnCategories(newCategories);
        var newColumns = _.cloneDeep(columns);
        var newOrderedByRankColumns = _.cloneDeep(orderedByRankColumns);
        var keepOn = [];
        // When turning the column category off
        if (currentShow) {
            newColumns.forEach(function (column) {
                if (column.column_categories.includes(categoryName)) {
                    if (column.column_categories.length > 1) {
                        //For columns having multiple column_categroies
                        //Check if any of them are ON, keep the column ON
                        for (var _i = 0, _a = column.column_categories; _i < _a.length; _i++) {
                            var category = _a[_i];
                            if (category != categoryName) {
                                for (var _b = 0, newCategories_1 = newCategories; _b < newCategories_1.length; _b++) {
                                    var st = newCategories_1[_b];
                                    if (st.categoryName == category && st.show)
                                        keepOn.push(column.column_name);
                                }
                            }
                        }
                    }
                }
            });
        }
        newColumns.forEach(function (column) {
            if (column.column_categories.includes(categoryName) &&
                !keepOn.includes(column.column_name))
                column.show = !currentShow;
        });
        newOrderedByRankColumns.forEach(function (column) {
            if (column.column_categories.includes(categoryName) &&
                !keepOn.includes(column.column_name))
                column.show = !currentShow;
        });
        newColumns.sort(function (a, b) {
            return (Number(b.show) - Number(a.show) || //sorting by visiblity (displayed columns(true) is shown first)
                Number(a.column_display_order_rank) - //then sorting by display order rank(ascending order)
                    Number(b.column_display_order_rank));
        });
        setColumns(newColumns);
        setOrderedByRankColumns(newOrderedByRankColumns);
        //Update queryOptions when deduplication is on
        if (deduplicate) {
            var newQueryOption = _.cloneDeep(queryOptions);
            var selectedCols = newColumns.map(function (col) {
                if (col.show)
                    return col.column_name;
            });
            selectedCols = selectedCols.filter(Boolean);
            newQueryOption.deduplication_columns = selectedCols;
            setQueryOptions(newQueryOption);
        }
    };
    //add delay to checkbox selection when deduplication is on
    var handleDelayedColumnToggle = function (colName) {
        var newColumns = _.cloneDeep(columns);
        var newOrderedByRankColumns = _.cloneDeep(orderedByRankColumns);
        for (var _i = 0, newColumns_1 = newColumns; _i < newColumns_1.length; _i++) {
            var col = newColumns_1[_i];
            if (col.column_name == colName) {
                col.show = !col.show;
                break;
            }
        }
        for (var _a = 0, newOrderedByRankColumns_1 = newOrderedByRankColumns; _a < newOrderedByRankColumns_1.length; _a++) {
            var col = newOrderedByRankColumns_1[_a];
            if (col.column_name == colName) {
                col.show = !col.show;
                break;
            }
        }
        setOrderedByRankColumns(newOrderedByRankColumns);
        newColumns.sort(function (a, b) {
            return (Number(b.show) - Number(a.show) || //sorting by visiblity (displayed columns(true) is shown first)
                Number(a.column_display_order_rank) - //then sorting by display order rank(ascending order)
                    Number(b.column_display_order_rank));
        });
        setColumns(newColumns);
        if (deduplicate) {
            if (timer)
                clearTimeout(timer);
            setTimer(setTimeout(function () {
                var newQueryOption = _.cloneDeep(queryOptions);
                var selectedCols = newColumns.map(function (col) {
                    if (col.show)
                        return col.column_name;
                });
                selectedCols = selectedCols.filter(Boolean);
                newQueryOption.deduplication_columns = selectedCols;
                setQueryOptions(newQueryOption);
            }, waitTime));
        }
    };
    var handleHideShowAll = function () {
        var _a;
        var changedColumn = _.cloneDeep(columns);
        var newOrderedByRankColumns = _.cloneDeep(orderedByRankColumns);
        var changedCategory = _.cloneDeep(columnCategories);
        changedColumn.forEach(function (col) { return (col.show = !hideShow); });
        newOrderedByRankColumns.forEach(function (col) { return (col.show = !hideShow); });
        changedCategory.forEach(function (category) { return (category.show = !hideShow); });
        if (((_a = current === null || current === void 0 ? void 0 : current.source) === null || _a === void 0 ? void 0 : _a.data_source_url_slug) == "irt_value") {
            var changedLiveCols = _.cloneDeep(virtualColumnsMetadata);
            if (fetchVirtualColumns) {
                //Change visiblity only if the virtual columns are fetched
                changedLiveCols.forEach(function (col) { return (col.show = !hideShow); });
                setVirtualColumnsMetadata(changedLiveCols);
            }
        }
        changedColumn.sort(function (a, b) {
            return (Number(b.show) - Number(a.show) || //sorting by visiblity (displayed columns(true) is shown first)
                Number(a.column_display_order_rank) - //then sorting by display order rank(ascending order)
                    Number(b.column_display_order_rank));
        });
        setColumns(changedColumn);
        setColumnCategories(changedCategory);
        setOrderedByRankColumns(newOrderedByRankColumns);
        setHideShow(function (prevHideShow) { return !prevHideShow; });
        if (deduplicate) {
            var newQueryOption = _.cloneDeep(queryOptions);
            var selectedCols = changedColumn.map(function (col) {
                if (col.show)
                    return col.column_name;
            });
            selectedCols = selectedCols.filter(Boolean);
            newQueryOption.deduplication_columns = selectedCols;
            setQueryOptions(newQueryOption);
        }
    };
    var categoryList = function () {
        var gridItem = [];
        columnCategories.map(function (cat, i) {
            gridItem.push(_jsx(Grid, __assign({ onClick: function () { return handleCategoryToggle(i, cat.categoryName); }, tabIndex: 0, onKeyDown: function (event) {
                    event.key == "Enter" && handleCategoryToggle(i, cat.categoryName);
                }, item: true, maxWidth: 300, xs: 6 }, { children: _jsx(Category, { category: cat }) }), cat.categoryName));
        });
        return (_jsx(Grid, __assign({ container: true, spacing: 2, sx: { height: "285px", overflow: "scroll" } }, { children: gridItem })));
    };
    var columnList = function () {
        var gridItem = [];
        orderedByRankColumns.map(function (col, i) {
            if (filteredColumns.includes(col.column_name))
                gridItem.push(_jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(Checkbox, { checked: col.show, onChange: function (e) { return handleDelayedColumnToggle(col.column_name); }, onKeyDown: function (event) {
                            event.key == "Enter" &&
                                handleDelayedColumnToggle(col.column_name);
                        }, label: col.human_friendly_column_name, style: { color: "#272727", fontWeight: "400", fontSize: "16px" } }) }), col.column_name));
        });
        return (_jsxs(Grid, __assign({ container: true, spacing: 2, sx: { height: "285px", overflowY: "scroll" } }, { children: [gridItem, error && (_jsx(Typography, __assign({ sx: {
                        backgroundColor: "#edc0c0",
                        borderRadius: "4px",
                        color: "#d10000",
                        margin: "20px 0 0 15px",
                        padding: "5px 8px",
                        height: "25px",
                        fontSize: "14px",
                    } }, { children: "\u2715 No such column found" })))] })));
    };
    return (_jsx(ClickAwayListener, __assign({ onClickAway: handleClose }, { children: _jsxs(Box, __assign({ sx: {
                padding: "17px  17px 0px 17px",
                width: "229px",
            } }, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                        color: "#595959",
                        marginBottom: "6px",
                    } }, { children: "Column Category" })), _jsxs(Box, { children: [_jsxs(Typography, __assign({ onClick: handleClick, role: "button", onKeyDown: function (event) {
                                event.key == "Enter" && handleClick(event);
                            }, variant: "body2", tabIndex: 0, sx: {
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "12px 17px",
                                boxSizing: "border-box",
                                color: "#595959",
                                cursor: "pointer",
                                width: "227px",
                                height: "44px",
                                alignItems: "center",
                                border: "1px solid #949494",
                                borderRadius: "3px",
                                fontWeight: "400",
                                fontSize: "16px",
                                "&:hover": {
                                    boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(0deg 0% 74%) 0 0 0 3px",
                                },
                                "&:focus-visible": {
                                    boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(210deg 100% 35%) 0 0 0 2px, hsl(210deg 100% 85%) 0 0 0 4px",
                                    outline: "none",
                                },
                            } }, { children: [visibleColumns == 0 ? (_jsx(Typography, __assign({ component: "span", whiteSpace: "nowrap", variant: "body2", sx: {
                                        color: "#595959",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    } }, { children: "Select Column Categories" }))) : (_jsxs(Typography, __assign({ component: "span", variant: "body2", sx: { color: "#404040" } }, { children: [visibleCategories <= 1
                                            ? "".concat(visibleCategories, " category")
                                            : "".concat(visibleCategories, " categories"), "\u00A0+", _jsx("br", {}), visibleColumns <= 1
                                            ? " ".concat(visibleColumns, " column")
                                            : " ".concat(visibleColumns, " columns")] }))), _jsx(ListIcon, {})] })), _jsx(Popper, __assign({ open: open, anchorEl: anchorEl, placement: "auto-start", modifiers: [
                                {
                                    name: "offset",
                                    options: {
                                        offset: [0, 15],
                                    },
                                },
                            ], sx: { zIndex: "1000" } }, { children: _jsx(FocusTrap, __assign({ open: open }, { children: _jsx(Box, __assign({ role: "alert", sx: {
                                        display: "flex",
                                        flexDirection: "column",
                                        border: "1px solid #EDEDED",
                                        margin: "2px",
                                        width: "675px",
                                        zIndex: "100000",
                                        height: "fit-content",
                                        background: "#FFFFFF",
                                    }, tabIndex: -1 }, { children: _jsxs(Box, __assign({ sx: { margin: "24px" } }, { children: [_jsxs(Box, __assign({ sx: {
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "0 2px",
                                                } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: {
                                                            color: "#272727",
                                                            marginBottom: 0,
                                                            alignSelf: "center",
                                                            fontSize: "18px",
                                                            fontWeight: "500",
                                                        } }, { children: "Columns" })), _jsx(CloseButton, { size: "small", "aria-label": "close", onClick: handleClose })] })), _jsxs(Box, __assign({ sx: {
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    marginTop: "27px",
                                                } }, { children: [_jsx(Input, { value: filter, onChange: handleInput, sx: { width: "520px" }, placeholder: "Search Column", startIcon: _jsx(Search, { style: { color: "#595959" } }), "aria-label": "Search Column" }), _jsx(Button, __assign({ onClick: handleHideShowAll, sx: { height: "44px" }, variant: "contained", color: "secondary" }, { children: hideShow ? "Hide all" : "Show all" }))] })), _jsxs(Box, __assign({ sx: {
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    margin: "28px 0",
                                                    width: "540px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whitespace: "nowrap",
                                                    color: "#272727",
                                                } }, { children: [colCat == "cat" ? (_jsx(Tooltip, __assign({ title: "Column categories belonging to ".concat((_a = current === null || current === void 0 ? void 0 : current.source) === null || _a === void 0 ? void 0 : _a.human_friendly_data_source_name, " data source") }, { children: _jsxs(Typography, __assign({ sx: menuSelectStyle }, { children: ["Categories", " "] })) }))) : (_jsx(Tooltip, __assign({ title: "Column categories belonging to ".concat((_b = current === null || current === void 0 ? void 0 : current.source) === null || _b === void 0 ? void 0 : _b.human_friendly_data_source_name, " data source") }, { children: _jsx(Typography, __assign({ onClick: function () { return setColCat("cat"); }, tabIndex: 0, onKeyDown: function (event) {
                                                                event.key == "Enter" && setColCat("cat");
                                                            }, sx: {
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                cursor: "pointer",
                                                            } }, { children: "Categories" })) }))), colCat == "col" ? (_jsx(Tooltip, __assign({ title: "Columns belonging to ".concat((_c = current === null || current === void 0 ? void 0 : current.source) === null || _c === void 0 ? void 0 : _c.human_friendly_data_source_name, " data source") }, { children: _jsx(Typography, __assign({ sx: menuSelectStyle }, { children: "Columns " })) }))) : (_jsx(Tooltip, __assign({ title: "Columns belonging to ".concat((_d = current === null || current === void 0 ? void 0 : current.source) === null || _d === void 0 ? void 0 : _d.human_friendly_data_source_name, " data source") }, { children: _jsx(Typography, __assign({ onClick: function () { return setColCat("col"); }, tabIndex: 0, onKeyDown: function (event) {
                                                                event.key == "Enter" && setColCat("col");
                                                            }, sx: {
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                cursor: "pointer",
                                                            } }, { children: "Columns" })) }))), ((_e = current === null || current === void 0 ? void 0 : current.source) === null || _e === void 0 ? void 0 : _e.data_source_url_slug) == "irt_value" ? (colCat == "otherCol" ? (_jsx(Tooltip, __assign({ title: "Columns fetched from other data sources that cannot be filtered on", placement: "top" }, { children: _jsx(Typography, __assign({ sx: menuSelectStyle }, { children: "Virtual columns" })) }))) : (_jsx(Tooltip, __assign({ title: "Columns fetched from other data sources that cannot be filtered on" }, { children: _jsx(Typography, __assign({ onClick: function () { return setColCat("otherCol"); }, tabIndex: 0, onKeyDown: function (event) {
                                                                event.key == "Enter" && setColCat("otherCol");
                                                            }, sx: {
                                                                fontSize: "16px",
                                                                fontWeight: "400",
                                                                cursor: "pointer",
                                                            } }, { children: "Virtual columns" })) })))) : (_jsx(_Fragment, {}))] })), colCat == "cat" ? (_jsx(_Fragment, { children: categoryList() })) : colCat == "col" ? (_jsx(_Fragment, { children: columnList() })) : (_jsx(VirtualColumns, {}))] })) })) })) }))] })] })) })));
};
export default ColCatMenu;
