var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@inspera/component-library";
import { Box } from "@mui/material";
import useStore from "../../../Store/store";
export var MenuOptionsBtn = function (_a) {
    var label = _a.label, btnText = _a.btnText, icon = _a.icon, _b = _a.disabled, disabled = _b === void 0 ? false : _b, onClick = _a.onClick, collapse = _a.collapse;
    var current = useStore().current;
    return (_jsxs(Box, __assign({ sx: {
            width: "229px",
            padding: "14px  17px 3px 17px",
            background: "".concat(label == "Column Category" && !collapse && !(current === null || current === void 0 ? void 0 : current.source) ? "#fff3dc" : "#ffffff"),
        } }, { children: [_jsx(Typography, __assign({ sx: {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#595959",
                    marginBottom: "6px",
                }, "aria-hidden": "true" }, { children: label })), _jsxs(Box, __assign({ tabIndex: 0, onClick: function () { return onClick(); }, sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px 17px",
                    boxSizing: "border-box",
                    // pointerEvents: disabled ? "none" : "auto",
                    cursor: disabled ? "default" : "pointer",
                    width: "229px",
                    height: "44px",
                    alignItems: "center",
                    border: "1px solid ",
                    borderColor: disabled ? "#bdbdbd" : "#949494",
                    borderRadius: "3px",
                    fontWeight: "400",
                    "&:hover": {
                        boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(0deg 0% 74%) 0 0 0 3px",
                    },
                    "&:focus-visible": {
                        boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(210deg 100% 35%) 0 0 0 2px, hsl(210deg 100% 85%) 0 0 0 4px",
                        outline: "none",
                    },
                } }, { children: [_jsx(Typography, __assign({ whiteSpace: "nowrap", component: "span", variant: "body2", sx: {
                            color: disabled ? "#00000042" : "#595959",
                            margin: "0px",
                            fontSize: "14px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        } }, { children: btnText })), icon] }))] })));
};
