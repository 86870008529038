var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, MenuItem, Modal, Select, Typography, } from "@inspera/component-library";
import { Box, Breadcrumbs, Grid, Link, CircularProgress, Chip, } from "@mui/material";
import { Stack } from "@mui/system";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useEffect, useState, } from "react";
import useStore from "../../Store/store";
import EditView from "../Modal/EditView";
import CloneView from "../Modal/CloneView";
import ViewCurl from "../Modal/ViewCurl";
import DeleteView from "../Modal/DeleteView";
import ShareView from "../Modal/ShareView";
import { EmptyState } from "./EmptyState";
import Dropdown from "./Dropdown";
import ViewTable from "./ViewTable";
import { hashCode } from "../../Utils/util";
export var SavedViews = function (_a) {
    var setIsSavedView = _a.setIsSavedView, hidden = _a.hidden;
    var _b = useStore(), views = _b.views, sources = _b.sources, setCurrent = _b.setCurrent, restoreState = _b.restoreState, setRestoreState = _b.setRestoreState, userId = _b.userId;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(""), sourceFitler = _d[0], setSourceFilter = _d[1];
    var _e = useState(""), search = _e[0], setSearch = _e[1];
    var _f = useState(views), searchedViews = _f[0], setSearchedViews = _f[1];
    // window.history.replaceState(null, "Saved views", "/savedViews");
    useEffect(function () {
        setSearch("");
        setSourceFilter("");
        setSearchedViews(views);
    }, [views]);
    var handleSourceSelector = function (event) {
        var sourceSelected = event.target.value;
        var searchViewResult = getSearchedViews(sourceSelected, search);
        setSearchedViews(searchViewResult);
        setSourceFilter(sourceSelected);
    };
    var handleSeachInput = function (e) {
        var searchInput = e.target.value;
        var searchViewResult = getSearchedViews(sourceFitler, searchInput);
        setSearchedViews(searchViewResult);
        setSearch(searchInput);
    };
    var getSearchedViews = function (source, search) {
        return views.filter(function (x) {
            var _a, _b, _c, _d;
            if (source == "")
                return true && ((_b = (_a = x.name) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === null || _b === void 0 ? void 0 : _b.includes(search.toUpperCase()));
            else
                return (source == x.data_source &&
                    ((_d = (_c = x.name) === null || _c === void 0 ? void 0 : _c.toUpperCase()) === null || _d === void 0 ? void 0 : _d.includes(search.toUpperCase())));
        });
    };
    var getSourceObject = function (source) {
        if (source) {
            var value = sources.find(function (x) {
                return x.data_source_url_slug == source;
            });
            return value;
        }
    };
    var handleClick = function (view) { return __awaiter(void 0, void 0, void 0, function () {
        var hashUserId, dsource;
        return __generator(this, function (_a) {
            // set restoreState to false when changing to view, as session was already restored in all components
            if (restoreState)
                setRestoreState(false);
            hashUserId = hashCode(String(userId));
            localStorage.removeItem("options".concat(hashUserId));
            if (view.data_source) {
                dsource = getSourceObject(view.data_source);
                setLoading(true);
                setCurrent({ source: dsource, viewDef: view, viewOwn: true });
                setIsSavedView(false);
                setLoading(false);
            }
            return [2 /*return*/];
        });
    }); };
    //Dropdown menu
    var _g = useState(null), anchorEl = _g[0], setAnchorEl = _g[1];
    var menuValues = ["Edit", "View cURL", "Clone", "Share"];
    var _h = useState(), selectedView = _h[0], setSelectedView = _h[1];
    var handleDropdownMenu = function (event, row) {
        event.stopPropagation();
        setSelectedView(row);
        setAnchorEl(event.currentTarget);
    };
    var _j = useState(false), openModal = _j[0], setOpenModal = _j[1];
    var _k = useState("Title"), modalTitle = _k[0], setModalTitle = _k[1];
    var _l = useState(_jsx(_Fragment, {})), modal = _l[0], setModalContent = _l[1];
    var handleModalClick = function (event) {
        setAnchorEl(null);
        switch (event.currentTarget.dataset.myValue) {
            case "Edit":
                setModalTitle("Edit View");
                setModalContent(_jsx(EditView, { view: selectedView, setModal: setOpenModal }));
                break;
            case "Clone":
                setModalTitle("Clone View");
                setModalContent(_jsx(CloneView, { view: selectedView, setModal: setOpenModal }));
                break;
            case "View cURL":
                setModalTitle("View cURL");
                setModalContent(_jsx(ViewCurl, { view: selectedView }));
                break;
            case "Delete":
                setModalTitle("Delete View");
                setModalContent(_jsx(DeleteView, { view: selectedView, setModal: setOpenModal }));
                break;
            case "Share":
                setModalTitle("Share");
                setModalContent(_jsx(ShareView, { view: selectedView }));
                break;
            default:
                // TODO
                break;
        }
        setOpenModal(true);
    };
    var handleCloseModal = function () { return setOpenModal(false); };
    return (_jsx(Box, __assign({ sx: { display: hidden ? "none" : "initial" } }, { children: loading ? (_jsx(Box, __assign({ sx: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "calc(50vh - 59px)",
            } }, { children: _jsx(CircularProgress, {}) }))) : (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: 12, zIndex: 10, sx: { py: "17.5px", ml: "30px" } }, { children: _jsx(Breadcrumbs, { children: _jsx(Link, __assign({ underline: "hover", color: "#272727", sx: { cursor: "pointer" }, onClick: function () { return setIsSavedView(false); }, onKeyUp: function (e) { return e.key == "Enter" && setIsSavedView(false); }, tabIndex: 0 }, { children: _jsxs(Stack, __assign({ direction: "row", sx: { textAlign: "center" } }, { children: [_jsx(ArrowBackIosNewRoundedIcon, {}), _jsx(Typography, __assign({ m: 0, ml: "10px", fontWeight: "500" }, { children: "Back to data discovery" }))] })) })) }) })), _jsxs(Stack, __assign({ sx: {
                        height: "calc( 100vh - 210px )",
                        bgcolor: "white",
                        margin: "24px",
                        padding: "24px",
                        mt: "0px",
                        borderRadius: "8px",
                    } }, { children: [_jsxs(Box, __assign({ sx: {
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "16px",
                            } }, { children: [_jsx(Typography, __assign({ variant: "h4", marginBottom: 0, color: "#272727" }, { children: "Saved views" })), _jsx(Chip, { sx: { marginLeft: "8px", fontSize: "14px" }, label: searchedViews.length })] })), _jsxs(Stack, __assign({ direction: "row", spacing: 1 }, { children: [_jsx(Input, { placeholder: "Search for ...", sx: { width: "238px" }, startIcon: _jsx(SearchTwoToneIcon, {}), onChange: handleSeachInput }), _jsxs(Select, __assign({ variant: "outlined", value: sourceFitler, onChange: handleSourceSelector, displayEmpty: true, renderValue: sourceFitler !== ""
                                        ? undefined
                                        : function () { return (_jsx(Typography, __assign({ component: "span", fontSize: "14px", color: "#595959" }, { children: "Source" }))); } }, { children: [_jsx(MenuItem, __assign({ value: "" }, { children: _jsx(Typography, __assign({ sx: {
                                                    color: "#595959",
                                                    fontSize: "14px",
                                                    margin: "0px",
                                                } }, { children: "All" })) })), sources
                                            .sort(function (a, b) {
                                            return a.data_source_display_order_rank -
                                                b.data_source_display_order_rank;
                                        })
                                            .map(function (source, index) { return (_jsx(MenuItem, __assign({ value: source.data_source_url_slug }, { children: _jsx(Typography, __assign({ sx: {
                                                    color: "#595959",
                                                    fontSize: "14px",
                                                    margin: "0px",
                                                } }, { children: source.human_friendly_data_source_name })) }), index)); })] }))] })), _jsx(ViewTable, { customFilter: function () { return searchedViews; }, handleRowClick: handleClick, handleDropdownMenu: handleDropdownMenu }), views.length == 0 && _jsx(EmptyState, { redirectBtn: true }), searchedViews.length != 0 && searchedViews.length == 0 && (_jsx(EmptyState, { redirectBtn: false }))] })), _jsx(Dropdown, { anchorEl: anchorEl, setAnchorEl: setAnchorEl, values: menuValues, handleModalClick: handleModalClick }), _jsx(Modal, __assign({ id: "modal-default", open: openModal, onBackdropClick: handleCloseModal, onClose: handleCloseModal, title: modalTitle, width: "sm", fullWidth: false }, { children: modal }))] })) })));
};
