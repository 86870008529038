var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch, Typography } from "@inspera/component-library";
import { Box } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import useStore from "../../../../../Store/store";
var ShowInteraction = function () {
    var _a = useStore(), dataViewQueryOptions = _a.dataViewQueryOptions, setDataViewQueryOptions = _a.setDataViewQueryOptions;
    var _b = useState(true), showInteraction = _b[0], setShowInteraction = _b[1];
    var handleInteractionChange = function () {
        var newInteraction = !showInteraction;
        setShowInteraction(newInteraction);
        var newDataViewQueryOptions = _.cloneDeep(dataViewQueryOptions);
        if (newDataViewQueryOptions != null) {
            newDataViewQueryOptions.showInteractions = newInteraction;
            setDataViewQueryOptions(newDataViewQueryOptions);
        }
    };
    return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "17px  17px 0px 17px" }, { children: [_jsx(Typography, __assign({ sx: { margin: "0 16px 0 0 ", fontSize: "14px" } }, { children: "Show interactions" })), _jsx(Switch, { checked: showInteraction, onClick: handleInteractionChange })] })));
};
export default ShowInteraction;
