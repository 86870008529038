import { ErrorType, StatusCode } from "../Model/model";
export var handleError = function (setError, error, type, message) {
    var _a, _b;
    switch (error.code) {
        case StatusCode.UAUTHENTICATED:
            setError({
                type: ErrorType.ERROR,
                code: error.code,
                message: message,
            });
            break;
        case StatusCode.EMPTYSOURCE:
            setError({
                type: ErrorType.ERROR,
                code: error.code,
                message: message,
            });
            break;
        default:
            if (type == ErrorType.WARNING)
                setError({
                    type: ErrorType.WARNING,
                    code: error.code,
                    message: message,
                });
            else
                setError({
                    type: ErrorType.ERROR,
                    code: error.code,
                    message: message,
                    "ddt-api-request-id": (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.headers["ddt-api-request-id"]) !== null && _b !== void 0 ? _b : undefined,
                });
    }
};
