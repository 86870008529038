var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var createAuthSlice = function (set, get) { return ({
    marketplaceId: null,
    setMarketplaceId: function (id) {
        set(function (state) { return (__assign(__assign({}, state), { marketplaceId: id })); });
    },
    itemBankId: [],
    token: "",
    isAuth: false,
    setAuth: function (status) {
        set({ isAuth: status });
    },
    userId: null,
    domain: "",
    setUser: function (token, user) {
        set({
            marketplaceId: user.data.marketplaceId,
            token: token,
            isAuth: true,
            userId: user.data.userId,
            domain: user.domain,
            itemBankId: user.data.itemBankIds,
        });
    },
}); };
