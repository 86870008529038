var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import useStore from "../../../Store/store";
export var PresetModalCard = function (_a) {
    var preset = _a.preset, handleClose = _a.handleClose;
    var _b = useStore(), setCurrent = _b.setCurrent, sources = _b.sources;
    var getSourceObject = function (source) {
        if (source) {
            var value = sources.find(function (x) {
                return x.data_source_url_slug == source;
            });
            return value;
        }
    };
    var handlePresetSelection = function (preset) {
        if (preset.parameters.body) {
            var dataSource = getSourceObject(preset.parameters.data_source);
            setCurrent({
                source: dataSource,
                viewDef: null,
                viewOwn: false,
                preset: preset,
            });
            handleClose();
        }
    };
    return (_jsxs(Box, __assign({ sx: {
            border: "1px solid #D9D9D9",
            alignItems: "center",
            padding: "20px",
            margin: "15px",
            flex: "0 0 33%",
            cursor: "pointer",
            "&:hover": {
                background: "#e0e0e0",
            },
        }, onClick: function () { return handlePresetSelection(preset); } }, { children: [_jsx(Typography, __assign({ sx: { fontSize: "16px", color: "#272727", marginBottom: "4px" } }, { children: preset.title })), _jsx(Typography, __assign({ sx: { fontSize: "14px", color: "#272727", marginBottom: "4px" } }, { children: preset.description }))] })));
};
