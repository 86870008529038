var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography } from "@inspera/component-library";
import { Box, Stack } from "@mui/material";
import emptyViewIcon from "../../Assets/images/empty_views.svg";
import { useNavigate } from "react-router-dom";
export var EmptyState = function (_a) {
    var redirectBtn = _a.redirectBtn;
    var navigate = useNavigate();
    return (_jsxs(Stack, __assign({ py: "24px", margin: "auto", bgcolor: "#F7F7F7", width: "100%", m: "0px", justifyItems: "center", alignItems: "center" }, { children: [_jsx(Box, { component: "img", sx: {
                    mb: "8px",
                    height: "24px",
                    bgcolor: "white",
                    width: "24px",
                    padding: "5px",
                    borderRadius: "32px",
                }, src: emptyViewIcon }), redirectBtn ? (_jsx(Typography, { children: "No saved views" })) : (_jsx(Typography, { children: "No views found based on search criteria" })), redirectBtn && (_jsx(Button, __assign({ variant: "contained", onClick: function () {
                    navigate(0);
                } }, { children: "Create new view" })))] })));
};
