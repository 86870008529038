export var theme = {
    seriesCnt: 4,
    backgroundColor: "white",
    titleColor: "#404040",
    subtitleColor: "#aaaaaa",
    textColorShow: false,
    textColor: "#333",
    markTextColor: "#404040",
    color: [
        "#0059b3",
        "#3399ff",
        "#1BA76D",
        "#595959",
        "#ed457d",
        "#9F88D7",
        "#404040",
        "#f04242",
    ],
    borderColor: "#ccc",
    borderWidth: "1",
    visualMapColor: [
        "#0059b3",
        "#3399ff",
        "#1BA76D",
        "#595959",
        "#ed457d",
        "#9F88D7",
        "#404040",
        "#f04242",
    ],
    legendTextColor: "#404040",
    kColor: "#c23531",
    kColor0: "#314656",
    kBorderColor: "#c23531",
    kBorderColor0: "#314656",
    kBorderWidth: 1,
    lineWidth: 2,
    symbolSize: "6",
    symbol: "emptyCircle",
    symbolBorderWidth: 1,
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: "#aaa",
    mapLabelColor: "#000000",
    mapLabelColorE: "rgb(100,0,0)",
    mapBorderColor: "#444444",
    mapBorderColorE: "#444444",
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: "#eeeeee",
    mapAreaColorE: "rgba(255,215,0,0.8)",
    axes: [
        {
            type: "all",
            name: "all axis",
            axisLineShow: true,
            axisLineColor: "#333",
            axisTickShow: true,
            axisTickColor: "#333",
            axisLabelShow: true,
            axisLabelColor: "#333",
            splitLineShow: true,
            splitLineColor: ["#ccc"],
            splitAreaShow: false,
            splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
        },
        {
            type: "category",
            name: "category axis",
            axisLineShow: true,
            axisLineColor: "#404040",
            axisTickShow: true,
            axisTickColor: "#595959",
            axisLabelShow: true,
            axisLabelColor: "#404040",
            splitLineShow: false,
            splitLineColor: ["#ccc"],
            splitAreaShow: false,
            splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
        },
        {
            type: "value",
            name: "value axis",
            axisLineShow: true,
            axisLineColor: "#404040",
            axisTickShow: true,
            axisTickColor: "#404040",
            axisLabelShow: true,
            axisLabelColor: "#404040",
            splitLineShow: true,
            splitLineColor: ["#d9d9d9"],
            splitAreaShow: false,
            splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
        },
        {
            type: "log",
            name: "log axis",
            axisLineShow: true,
            axisLineColor: "#595959",
            axisTickShow: true,
            axisTickColor: "#595959",
            axisLabelShow: true,
            axisLabelColor: "#404040",
            splitLineShow: true,
            splitLineColor: ["#d9d9d9"],
            splitAreaShow: false,
            splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
        },
        {
            type: "time",
            name: "time axis",
            axisLineShow: true,
            axisLineColor: "#595959",
            axisTickShow: true,
            axisTickColor: "#595959",
            axisLabelShow: true,
            axisLabelColor: "#404040",
            splitLineShow: true,
            splitLineColor: ["#d9d9d9"],
            splitAreaShow: false,
            splitAreaColor: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
        },
    ],
    axisSeperateSetting: true,
    toolboxColor: "#999999",
    toolboxEmphasisColor: "#666666",
    tooltipAxisColor: "#d9d9d9",
    tooltipAxisWidth: 1,
    timelineLineColor: "#293c55",
    timelineLineWidth: 1,
    timelineItemColor: "#293c55",
    timelineItemColorE: "#a9334c",
    timelineCheckColor: "#e43c59",
    timelineCheckBorderColor: "#c23531",
    timelineItemBorderWidth: 1,
    timelineControlColor: "#293c55",
    timelineControlBorderColor: "#293c55",
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: "#293c55",
    datazoomBackgroundColor: "rgba(47,69,84,0)",
    datazoomDataColor: "rgba(47,69,84,0.3)",
    datazoomFillColor: "rgba(167,183,204,0.4)",
    datazoomHandleColor: "#a7b7cc",
    datazoomHandleWidth: "100",
    datazoomLabelColor: "#333333",
};
