import { ModeType, } from "./model";
export var initialQueryOptions = {
    filters: [],
    size: 10,
    sort: [],
};
export var initialCurrentSelection = {
    source: null,
    viewDef: null,
    viewOwn: true,
};
export var initialSharedView = {
    source: null,
    viewDef: null,
    id: null,
};
export var initialErrorState = {
    message: "",
    code: null,
    type: null,
    title: "",
};
export var initalModeState = {
    active: ModeType.data,
    availableModes: [ModeType.data],
};
export var initialChartState = {
    chartType: "Bar chart",
    options: {
        legend: {},
        tooltip: {},
        yAxis: [],
        xAxis: [],
        YAxis: [],
        series: [],
    },
    title: "Add title",
    description: "Add description",
    labelXAxis: "",
    ySelected: "",
    highlightColor: "",
    color: "#0059b3",
    highlightValue: "",
    labelYAxis: "",
    id: null,
};
export var initialDDTChartState = {
    title: "Add title",
    description: "Add description",
    labelXAxis: "",
    labelYAxis: "",
    y: "",
    series: {
        type: "bar",
    },
    x: "",
    xAxis: {
        type: undefined,
    },
    yAxis: {
        type: undefined,
    },
    dataset: { dimension: [] },
};
export var initialVirtualColumnMetadata = [
    {
        column_name: "item_status-current",
        human_friendly_column_name: "Item status",
        column_description: "Item status from Content data source",
        column_display_order_rank: 48,
        column_categories: [],
        column_data_type: "text",
        provide_auto_selection: true,
        show: false,
    },
    {
        column_name: "item_lifecycle_state-current",
        human_friendly_column_name: "Item lifecycle state",
        column_description: "Lifecycle state from Content data source",
        column_display_order_rank: 49,
        column_categories: [],
        column_data_type: "text",
        provide_auto_selection: true,
        show: false,
    },
];
export var initialDataViewQueryOptions = {
    body: {
        filters: [],
        size: 10,
    },
    showInteractions: true,
};
