var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Grid, useTheme } from "@mui/material";
import Navbar from "../Navbar";
export var ErrorPageLayout = function (_a) {
    var children = _a.children;
    var theme = useTheme().palette.secondary;
    return (_jsxs(_Fragment, { children: [_jsx(Navbar, { title: "Data discovery" }), _jsx(Grid, __assign({ container: true, spacing: 0, direction: "column", justifyContent: "center", sx: { minHeight: "calc(100vh - 64px)", background: "#EDEDED" } }, { children: _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Container, __assign({ fixed: true, sx: {
                            background: "white",
                            margin: "auto",
                            borderRadius: "32px",
                            border: 1,
                            borderColor: "#EDEDED",
                            height: "75vh",
                            transform: "translateY(-20px)",
                        } }, { children: children })) })) }))] }));
};
