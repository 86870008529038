var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Table, TableBody, TableCell, TableContainer, TableRow, } from "@inspera/component-library";
import dayjs from "dayjs";
import React from "react";
import useStore from "../../Store/store";
import { getComparator, getSourceObject, stableSort } from "./util";
import ViewTableHead from "./ViewTableHead";
var ViewTable = function (_a) {
    var customFilter = _a.customFilter, handleRowClick = _a.handleRowClick, handleDropdownMenu = _a.handleDropdownMenu;
    var _b = React.useState("desc"), order = _b[0], setOrder = _b[1];
    var _c = React.useState("timestamp"), orderBy = _c[0], setOrderBy = _c[1];
    var _d = useStore(), views = _d.views, sources = _d.sources;
    var handleRequestSort = function (event, property) {
        var isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    return (_jsx(TableContainer, __assign({ sx: { marginTop: "17px" } }, { children: _jsxs(Table, __assign({ stickyHeader: true }, { children: [_jsx(ViewTableHead, { order: order, orderBy: orderBy, onRequestSort: handleRequestSort }), views.length != 0 && (_jsx(TableBody, { children: stableSort(customFilter(), getComparator(order, orderBy)).map(function (row, idx) {
                        var _a;
                        return (_jsxs(TableRow, __assign({ onClick: function () { return handleRowClick(row); }, sx: {
                                height: "58px",
                                "&:hover": {
                                    backgroundColor: "#F7F7F7",
                                    cursor: "pointer",
                                },
                            } }, { children: [_jsx(TableCell, __assign({ sx: {
                                        maxWidth: "120px",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        color: "#404040",
                                    } }, { children: row.name })), _jsx(TableCell, __assign({ sx: {
                                        maxWidth: "100px",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        color: "#404040",
                                    } }, { children: row.description })), _jsx(TableCell, __assign({ sx: {
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        color: "#404040",
                                    } }, { children: (_a = getSourceObject(row.data_source, sources)) === null || _a === void 0 ? void 0 : _a.human_friendly_data_source_name })), _jsx(TableCell, __assign({ sx: {
                                        maxWidth: "60px",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        color: "#404040",
                                    } }, { children: dayjs(row.timestamp).format("DD.MM.YYYY hh:mm A") })), _jsx(TableCell, __assign({ sx: {
                                        width: "35px",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        color: "#404040",
                                    } }, { children: _jsx(Button, __assign({ variant: "contained", sx: {
                                            zIndex: "100",
                                            height: "32px",
                                            width: "84px",
                                            "&.MuiButton-containedSecondary": {
                                                border: "1px solid #BCBCBC",
                                            },
                                        }, color: "secondary", onClick: function (e) { return handleDropdownMenu(e, row); } }, { children: "Options" })) }))] }), idx));
                    }) }))] })) })));
};
export default ViewTable;
