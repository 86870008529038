var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableHead, TableRow, } from "@inspera/component-library";
import { Skeleton } from "@mui/material";
export var TableLoading = function () {
    var noCell = 4;
    var noRow = 14;
    return (_jsxs(Table, __assign({ stickyHeader: true, size: "small" }, { children: [_jsx(TableHead, { children: _jsx(TableRow, __assign({ sx: { height: "56px", margin: "2px" } }, { children: __spreadArray([], Array(noCell), true).map(function (_, i) { return (_jsx(TableCell, { children: _jsx(Skeleton, { animation: "wave", height: 28 }) }, "skeleton header " + i)); }) })) }), _jsx(TableBody, { children: __spreadArray([], Array(noRow), true).map(function (_, index) { return (_jsx(TableRow, __assign({ sx: {
                        overflow: "clip",
                        height: "56px",
                    } }, { children: __spreadArray([], Array(noCell), true).map(function (_, i) { return (_jsx(TableCell, __assign({ component: "th", align: "center", padding: "none", scope: "row", sx: {
                            width: "200px",
                            px: "16px",
                        } }, { children: _jsx(Skeleton, { animation: "wave", variant: "text", sx: { fontSize: "1.8rem" } }) }), "skeleton body cell" + i)); }) }), "skeleton row" + index)); }) })] })));
};
export default TableLoading;
