var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { initalModeState, initialCurrentSelection, initialErrorState, initialVirtualColumnMetadata, initialQueryOptions, initialDataViewQueryOptions, } from "../Model/initialStates";
import { columnCategoriesMetadata, sourceColumns } from "../Utils/constants";
import { hashCode } from "../Utils/util";
export var createAppSlice = function (set, get) { return ({
    queryOptions: initialQueryOptions,
    setQueryOptions: function (option) {
        set(function (state) { return (__assign(__assign({}, state), { queryOptions: option })); });
    },
    aggHeaders: null,
    setAggHeaders: function (option) {
        set(function (state) { return (__assign(__assign({}, state), { aggHeaders: option })); });
    },
    loading: true,
    setLoading: function (loading) {
        set(function (state) { return (__assign(__assign({}, state), { loading: loading })); });
    },
    page: 1,
    setPage: function (page) {
        set(function (state) { return (__assign(__assign({}, state), { page: page })); });
    },
    //Set mode as agg | data | vis
    mode: initalModeState,
    setMode: function (mode) {
        set(function (state) {
            return __assign(__assign({}, state), { mode: mode });
        });
    },
    setModeType: function (modeType) {
        set(function (state) {
            var mode = get().mode;
            mode.active = modeType;
            return __assign(__assign({}, state), { mode: mode });
        });
    },
    addModes: function (modeType) {
        set(function (state) {
            var mode = get().mode;
            if (!mode.availableModes.includes(modeType))
                mode.availableModes.push(modeType);
            return __assign(__assign({}, state), { mode: mode });
        });
    },
    removeModes: function (modeType) {
        set(function (state) {
            var mode = get().mode;
            mode.availableModes = mode.availableModes.filter(function (item) { return item !== modeType; });
            return __assign(__assign({}, state), { mode: mode });
        });
    },
    current: initialCurrentSelection,
    setCurrent: function (curr) {
        if (curr.dataView) {
            //TODO : Refactor for multiple sources later when there's a usecase, currently only single source dataview is present
            var colCatMetadata = columnCategoriesMetadata;
            var dataSourceColumns = sourceColumns;
            var columns_1 = [];
            //TODO: Add support for selecting columns from multiple sources when that use case is required
            dataSourceColumns.forEach(function (dataSource) {
                var _a;
                if (curr.dataView && curr.dataView.data_sources.length > 0)
                    if (dataSource.data_source_url_slug == ((_a = curr.dataView) === null || _a === void 0 ? void 0 : _a.data_sources[0])) {
                        columns_1 = dataSource.columns;
                    }
            });
            document.title = "Data Discovery Tool - ".concat(curr.dataView.title);
            var variableHumanCol_1 = {};
            columns_1.forEach(function (col) {
                col.show = true;
                variableHumanCol_1[col.column_name] = col.human_friendly_column_name;
            });
            columns_1.sort(function (a, b) {
                return (Number(a.column_display_order_rank) - // sorting by display order rank(ascending order)
                    Number(b.column_display_order_rank));
            });
            get().setVariableToFriendlyColumn(variableHumanCol_1);
            get().setColumns(columns_1);
        }
        else if ((curr === null || curr === void 0 ? void 0 : curr.source) != null) {
            var colCatMetadata_1 = columnCategoriesMetadata;
            var dataSourceColumns = sourceColumns;
            var columns_2 = [];
            dataSourceColumns.forEach(function (dataSource) {
                if (curr.source)
                    if (dataSource.data_source_url_slug == (curr === null || curr === void 0 ? void 0 : curr.source.data_source_url_slug)) {
                        columns_2 = dataSource.columns;
                    }
            });
            var sessionData = localStorage.getItem("options".concat(hashCode(String(get().userId))));
            //Saved data
            var restoreSessionData = sessionData
                ? JSON.parse(sessionData)
                : null;
            // Check which type of session have to be restored
            var options_1;
            if (get().restoreState) {
                options_1 = restoreSessionData.parameters;
            }
            else if (curr.viewDef) {
                options_1 = curr.viewDef.parameters;
                document.title = "Data Discovery Tool - ".concat(curr.viewDef.name);
            }
            else if (curr.preset) {
                options_1 = curr.preset.parameters;
                document.title = "Data Discovery Tool - ".concat(curr.preset.title);
            }
            var variableHumanCol_2 = {};
            if (options_1 && options_1.ddt_frontend.virtual_columns) {
                //Virtual columns
                if (options_1 === null || options_1 === void 0 ? void 0 : options_1.ddt_frontend.virtual_columns) {
                    get().setFetchVirtualColumns(options_1.ddt_frontend.virtual_columns.fetchVirtualColumns);
                    get().setVirtualColumnsMetadata(options_1.ddt_frontend.virtual_columns.virtualColumnsMetadata);
                }
            }
            if (!options_1) {
                //if only source is set, not view/restore state/preset
                document.title = "Data Discovery Tool - ".concat(curr.source.human_friendly_data_source_name);
                columns_2.forEach(function (col) {
                    col.show = false;
                    variableHumanCol_2[col.column_name] = col.human_friendly_column_name;
                });
            }
            else {
                columns_2.forEach(function (col) {
                    if (options_1.body.columns.includes(col.column_name))
                        col.show = true;
                    else
                        col.show = false;
                    variableHumanCol_2[col.column_name] = col.human_friendly_column_name;
                });
                // //sort the columns in order of their visiblity, then by display order rank
                columns_2.sort(function (a, b) {
                    return (Number(b.show) - Number(a.show) || //sorting by visiblity (displayed columns(true) is shown first)
                        Number(a.column_display_order_rank) - //then sorting by display order rank(ascending order)
                            Number(b.column_display_order_rank));
                });
            }
            get().setVariableToFriendlyColumn(variableHumanCol_2);
            get().setColumns(columns_2);
            //Setting Column Category metadata (no. of cols in each category, it's human friendly name etc.)
            var uColCat_2 = [];
            columns_2.forEach(function (col) {
                col.column_categories.forEach(function (category) {
                    if (!uColCat_2.includes(category))
                        uColCat_2.push(category);
                });
            });
            var countObj_1 = {};
            var _loop_1 = function (ucol) {
                columns_2.forEach(function (col) {
                    if (col.column_categories.includes(ucol)) {
                        countObj_1[ucol] = countObj_1[ucol] ? countObj_1[ucol] + 1 : 1;
                    }
                });
            };
            for (var _i = 0, uColCat_1 = uColCat_2; _i < uColCat_1.length; _i++) {
                var ucol = uColCat_1[_i];
                _loop_1(ucol);
            }
            var ColCat = uColCat_2.map(function (cat) {
                var humanFriendlyCategoryName = "";
                for (var i = 0; i < colCatMetadata_1.length; i++) {
                    if (colCatMetadata_1[i].active_column_category_name == cat) {
                        humanFriendlyCategoryName =
                            colCatMetadata_1[i].active_column_category_human_friendly_name;
                        break;
                    }
                }
                if (humanFriendlyCategoryName === "") {
                    humanFriendlyCategoryName = cat;
                }
                if (!options_1) {
                    //default state,when only source is selected
                    return {
                        categoryName: cat,
                        humanFriendlyCategoryName: humanFriendlyCategoryName,
                        show: false,
                        count: countObj_1[cat],
                    };
                }
                else {
                    if (options_1.ddt_frontend.column_categories.includes(cat))
                        return {
                            categoryName: cat,
                            humanFriendlyCategoryName: humanFriendlyCategoryName,
                            show: true,
                            count: countObj_1[cat],
                        };
                    else
                        return {
                            categoryName: cat,
                            humanFriendlyCategoryName: humanFriendlyCategoryName,
                            show: false,
                            count: countObj_1[cat],
                        };
                }
            });
            get().setColumnCategories(ColCat);
        }
        set(function (state) { return (__assign(__assign({}, state), { current: curr })); });
    },
    error: initialErrorState,
    setError: function (err) {
        set(function (state) { return (__assign(__assign({}, state), { error: err })); });
    },
    deepLinkQuery: false,
    setDeepLinkQuery: function (deep) {
        set(function (state) { return (__assign(__assign({}, state), { deepLinkQuery: deep })); });
    },
    deepLinkQueryOption: null,
    setDeepLinkQueryOption: function (q) {
        set(function (state) { return (__assign(__assign({}, state), { deepLinkQueryOption: q })); });
    },
    tabSwitching: false,
    setTabSwitching: function (b) {
        set(function (state) { return (__assign(__assign({}, state), { tabSwitching: b })); });
    },
    fetchVirtualColumns: false,
    setFetchVirtualColumns: function (fetch) {
        set(function (state) { return (__assign(__assign({}, state), { fetchVirtualColumns: fetch })); });
    },
    virtualColumnsMetadata: initialVirtualColumnMetadata,
    setVirtualColumnsMetadata: function (live) {
        set(function (state) { return (__assign(__assign({}, state), { virtualColumnsMetadata: live })); });
    },
    columns: [],
    setColumns: function (data) {
        set(function (state) { return (__assign(__assign({}, state), { columns: data })); });
    },
    columnCategories: [],
    setColumnCategories: function (ColCat) {
        set(function (state) { return (__assign(__assign({}, state), { columnCategories: ColCat })); });
    },
    restoreState: false,
    setRestoreState: function (data) {
        set(function (state) { return (__assign(__assign({}, state), { restoreState: data })); });
    },
    variableToFriendlyColumn: {},
    setVariableToFriendlyColumn: function (humanFriendly) {
        set(function (state) { return (__assign(__assign({}, state), { variableToFriendlyColumn: humanFriendly })); });
    },
    setColumnCategoryConfig: function (curr) { },
    deduplicate: false,
    setDeduplicate: function (dedup) {
        set(function (state) { return (__assign(__assign({}, state), { deduplicate: dedup })); });
    },
    presets: null,
    setPresets: function (presets) {
        set(function (state) { return (__assign(__assign({}, state), { presets: presets })); });
    },
    dataViews: null,
    setDataViews: function (dataViews) {
        set(function (state) { return (__assign(__assign({}, state), { dataViews: dataViews })); });
    },
    dataViewQueryOptions: initialDataViewQueryOptions,
    setDataViewQueryOptions: function (dataViewQueryOptions) {
        set(function (state) { return (__assign(__assign({}, state), { dataViewQueryOptions: dataViewQueryOptions })); });
    },
}); };
