var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, IconButton, Stack, TextareaAutosize } from "@mui/material";
import { APP_URL } from "../../Utils/constants";
import useStore from "../../Store/store";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
var ShareView = function (_a) {
    var view = _a.view;
    var marketplaceId = useStore().marketplaceId;
    var shareLink = "".concat(APP_URL, "/share/view/").concat(view === null || view === void 0 ? void 0 : view.view_id, "?mp=").concat(marketplaceId);
    var copyHandler = function () {
        navigator.clipboard.writeText(shareLink);
    };
    return (_jsxs(Stack, __assign({ spacing: 3 }, { children: [_jsx(Divider, {}), _jsxs(Stack, __assign({ alignItems: "center", direction: "row" }, { children: [_jsx(TextareaAutosize, { maxRows: 4, value: shareLink, "aria-label": "maximum height", placeholder: "Share link", style: { width: "100%" } }), _jsx(IconButton, __assign({ onClick: copyHandler }, { children: _jsx(ContentCopyRoundedIcon, {}) }))] })), _jsx(Stack, { direction: "row", spacing: 3 })] })));
};
export default ShareView;
