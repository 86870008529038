var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, MenuItem } from "@inspera/component-library";
var Dropdown = function (_a) {
    var anchorEl = _a.anchorEl, setAnchorEl = _a.setAnchorEl, values = _a.values, handleModalClick = _a.handleModalClick;
    var openMenu = Boolean(anchorEl);
    var handleMenuClose = function (event) {
        setAnchorEl(null);
        event.stopPropagation();
    };
    return (_jsxs(Menu, __assign({ sx: { mt: "5px" }, elevation: 6, anchorEl: anchorEl, open: openMenu, anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        }, transformOrigin: {
            vertical: "top",
            horizontal: "right",
        }, onClose: handleMenuClose, MenuListProps: {
            "aria-labelledby": "options",
        } }, { children: [values.map(function (val) {
                return (_jsx(MenuItem, __assign({ sx: {
                        py: "10px",
                        fontWeight: "400",
                        fontSize: "16px",
                        width: "242px",
                        color: "#595959",
                    }, "data-my-value": val, onClick: handleModalClick }, { children: val }), val));
            }), _jsx(MenuItem, __assign({ "data-my-value": "Delete", sx: {
                    color: "#A50D0D",
                    pr: "5rem",
                    py: "10px",
                    fontSize: "16px",
                }, onClick: handleModalClick }, { children: "Delete" }))] })));
};
export default Dropdown;
