var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem } from "@inspera/component-library";
var dataOptionMap = {
    boolean: ["is", "is not", "is null", "is not null"],
    number: [
        "is",
        "is not",
        "is null",
        "is not null",
        "greater than",
        "greater than equal to",
        "less than",
        "less than equal to",
    ],
    text: ["is", "is not", "is null", "is not null", "includes", "excludes"],
    metadata: ["is null", "is not null", "includes", "excludes"],
    object: ["is null", "is not null"],
};
var getCriteriaOptions = function (colType, colName) {
    if (colName == "section_metadata" || colName == "question_metadata")
        return optionGenerate(dataOptionMap["metadata"]);
    else if (colType == "short" || colType == "long" || colType == "float")
        return optionGenerate(dataOptionMap["number"]);
    else {
        if (dataOptionMap[colType])
            return optionGenerate(dataOptionMap[colType]);
        else
            return optionGenerate(dataOptionMap["text"]);
    }
};
var optionGenerate = function (dataType) {
    var options = [];
    for (var _i = 0, dataType_1 = dataType; _i < dataType_1.length; _i++) {
        var op = dataType_1[_i];
        options.push(_jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: op }, { children: op }), op));
    }
    return options;
};
export default getCriteriaOptions;
