var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Box, Typography, Popper, ClickAwayListener, Snackbar, Alert, ListSubheader, } from "@mui/material";
import FocusTrap from "@mui/base/TrapFocus";
import FunctionsIcon from "@mui/icons-material/Functions";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import plus from "../../../../Assets/images/plus.svg";
import useStore from "../../../../Store/store";
import { ModeType, } from "../../../../Model/model";
import { Button, Chip, CloseButton, Divider, MenuItem, Select, } from "@inspera/component-library";
import aggRowsToJson from "./aggRowsToJson";
import { initialDDTChartState } from "../../../../Model/initialStates";
import _ from "lodash";
import { hashCode } from "../../../../Utils/util";
var AggregationMenu = function (_a) {
    var _b;
    var variableToFriendlyColumn = _a.variableToFriendlyColumn;
    var _c = useStore(), queryOptions = _c.queryOptions, setQueryOptions = _c.setQueryOptions, current = _c.current, setModeType = _c.setModeType, mode = _c.mode, addModes = _c.addModes, removeModes = _c.removeModes, deepLinkQuery = _c.deepLinkQuery, deepLinkQueryOption = _c.deepLinkQueryOption, visibleColumns = _c.visibleColumns, setChart = _c.setChart, restoreState = _c.restoreState, columns = _c.columns, setRestoreState = _c.setRestoreState, userId = _c.userId;
    var _d = useState(false), disable = _d[0], setDisable = _d[1];
    var _e = useState(false), error = _e[0], setError = _e[1];
    var _f = useState(""), errorMsg = _f[0], setErrorMsg = _f[1];
    var _g = useState([]), groupByColumns = _g[0], setGroupByColumns = _g[1];
    var _h = useState([
        {
            function: "Min",
            functionColumn: columns[0].column_name,
            distinctByColumn: "None",
            sortOrder: "None",
        },
    ]), aggRows = _h[0], setAggRows = _h[1];
    var _j = useState(1), currentNumAggs = _j[0], setCurrentNumAggs = _j[1];
    var _k = useState(null), anchorEl = _k[0], setAnchorEl = _k[1];
    var showAggsError = function () {
        var eMsg = errorMsg;
        eMsg = eMsg.trim();
        var output = eMsg.split(".").map(function (x, idx) { return (_jsx(Typography, __assign({ sx: {
                margin: "5px 0",
                color: "#d10000",
                fontSize: "13px",
                padding: "2px",
                borderRadius: "4px",
                backgroundColor: "#edc0c0",
                width: "fit-content",
            } }, { children: x }), idx)); });
        output.pop();
        return output;
    };
    useEffect(function () {
        if ((current === null || current === void 0 ? void 0 : current.viewDef) != null || restoreState || (current === null || current === void 0 ? void 0 : current.preset))
            return;
        setError(false);
        setAggRows([
            {
                function: "Min",
                functionColumn: columns[0].column_name,
                distinctByColumn: "None",
                sortOrder: "None",
            },
        ]);
        setGroupByColumns([]);
        setCurrentNumAggs(1);
    }, [current === null || current === void 0 ? void 0 : current.source]);
    useEffect(function () {
        if (queryOptions.aggs != null && mode.active == ModeType.data) {
            setError(true);
        }
        else
            setError(false);
    }, [mode.active, queryOptions.aggs]);
    useEffect(function () {
        var _a;
        var options;
        var hashUserId = hashCode(String(userId));
        var savedState = localStorage.getItem("options".concat(hashUserId));
        if (restoreState && savedState) {
            options = JSON.parse(savedState).parameters;
        }
        else {
            options = (_a = current === null || current === void 0 ? void 0 : current.viewDef) === null || _a === void 0 ? void 0 : _a.parameters;
        }
        setError(false);
        if (deepLinkQuery || deepLinkQueryOption || !options)
            return;
        if (!options.body.aggs) {
            setAggRows([
                {
                    function: "Min",
                    functionColumn: columns[0].column_name,
                    distinctByColumn: "None",
                    sortOrder: "None",
                },
            ]);
            setCurrentNumAggs(1);
            setGroupByColumns([]);
            return;
        }
        var viewGroupBy = options.body.aggs.group_by;
        var humanFriendlGroupBy = viewGroupBy.map(function (groupBy) { return variableToFriendlyColumn[groupBy]; });
        setGroupByColumns(humanFriendlGroupBy);
        var viewAggs = options.body.aggs.aggs;
        var newAggRows = [];
        var _loop_1 = function (key) {
            var aggObj = {
                function: viewAggs[key].function.charAt(0).toUpperCase() +
                    viewAggs[key].function.slice(1).replace("_", " "),
                functionColumn: viewAggs[key].field_name,
                distinctByColumn: viewAggs[key].distinct_by == null ? "None" : viewAggs[key].field_name,
                sortOrder: "None",
            };
            options.body.sort.forEach(function (sort) {
                if (sort.agg_name && sort.order && sort.agg_name == key) {
                    aggObj.sortOrder = sort.order;
                }
            });
            newAggRows.push(aggObj);
        };
        for (var key in viewAggs) {
            _loop_1(key);
        }
        setAggRows(newAggRows);
    }, [current === null || current === void 0 ? void 0 : current.viewDef]);
    //useEffect for setting presets
    useEffect(function () {
        if (current === null || current === void 0 ? void 0 : current.preset) {
            var presetData = current.preset;
            setError(false);
            if (!presetData.parameters.body.aggs) {
                setAggRows([
                    {
                        function: "Min",
                        functionColumn: columns[0].column_name,
                        distinctByColumn: "None",
                        sortOrder: "None",
                    },
                ]);
                setCurrentNumAggs(1);
                setGroupByColumns([]);
            }
            else {
                var presetGroupBy = presetData.parameters.body.aggs.group_by;
                var humanFriendlGroupBy = presetGroupBy.map(function (groupBy) { return variableToFriendlyColumn[groupBy]; });
                setGroupByColumns(humanFriendlGroupBy);
                var presetAggs = presetData.parameters.body.aggs.aggs;
                var newAggRows = [];
                var _loop_2 = function (key) {
                    var aggObj = {
                        function: presetAggs[key].function.charAt(0).toUpperCase() +
                            presetAggs[key].function.slice(1).replace("_", " "),
                        functionColumn: presetAggs[key].field_name,
                        distinctByColumn: presetAggs[key].distinct_by == null
                            ? "None"
                            : presetAggs[key].field_name,
                        sortOrder: "None",
                    };
                    presetData.parameters.body.sort.forEach(function (sort) {
                        if (sort.agg_name && sort.order && sort.agg_name == key) {
                            aggObj.sortOrder = sort.order;
                        }
                    });
                    newAggRows.push(aggObj);
                };
                for (var key in presetAggs) {
                    _loop_2(key);
                }
                setAggRows(newAggRows);
            }
        }
    }, [current === null || current === void 0 ? void 0 : current.preset]);
    useEffect(function () {
        if ((deepLinkQueryOption === null || deepLinkQueryOption === void 0 ? void 0 : deepLinkQueryOption.aggs) && deepLinkQueryOption.aggs.aggs) {
            var viewGroupBy = deepLinkQueryOption.aggs.group_by;
            var humanFriendlGroupBy = viewGroupBy.map(function (groupBy) { return variableToFriendlyColumn[groupBy]; });
            setGroupByColumns(humanFriendlGroupBy);
            var viewAggs = deepLinkQueryOption.aggs.aggs;
            var newAggRows = [];
            var _loop_3 = function (key) {
                var aggObj = {
                    function: viewAggs[key].function.charAt(0).toUpperCase() +
                        viewAggs[key].function.slice(1).replace("_", " "),
                    functionColumn: viewAggs[key].field_name,
                    distinctByColumn: viewAggs[key].distinct_by == null
                        ? "None"
                        : viewAggs[key].field_name,
                    sortOrder: "None",
                };
                deepLinkQueryOption.sort.forEach(function (sort) {
                    if (sort.agg_name && sort.order && sort.agg_name == key) {
                        aggObj.sortOrder = sort.order;
                    }
                });
                newAggRows.push(aggObj);
            };
            for (var key in viewAggs) {
                _loop_3(key);
            }
            setAggRows(newAggRows);
        }
    }, [deepLinkQueryOption]);
    var handleClick = function (event) {
        if (visibleColumns == 0) {
            setDisable(true);
            return;
        }
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    var open = Boolean(anchorEl);
    var id = open ? "simple-popper" : undefined;
    var handleClose = function () { return setAnchorEl(null); };
    var sortOption = function () {
        var options = [];
        options.push(_jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: "None", id: "None" }, { children: "None" }), "None"));
        options.push(_jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: "asc" }, { children: "Ascending" }), "asc"));
        options.push(_jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: "desc" }, { children: "Descending" }), "desc"));
        return options;
    };
    //none = True means None options should be provided for this Select component
    //Type will have either numeric columns or all columns
    //humanFriendly if true will have option value of humanFriendly column name (required in GroupBy columns component)
    var colDropdown = function (none, type, humanFriendly) {
        var aggsColumns = _.cloneDeep(columns);
        //Remove date type columns from aggregation dropdowns
        aggsColumns = aggsColumns.filter(function (col) {
            return col.column_data_type !== "date";
        });
        var options = [];
        if (none) {
            options.push(_jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: "None", id: "None" }, { children: "None" }), "None"));
        }
        options.push(_jsx(ListSubheader, __assign({ sx: {
                fontSize: "12px",
                color: "#272727",
                lineHeight: "120%",
                margin: "10px 0",
            } }, { children: "Columns in your view" }), "Show"));
        options.push(_jsx(Divider, {}, "divider1"));
        if (type == "all") {
            aggsColumns.forEach(function (col) {
                if (col.show) {
                    options.push(_jsx(MenuItem, __assign({ sx: {
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#404040",
                            lineHeight: "20px",
                            padding: "13px",
                        }, value: humanFriendly ? col.human_friendly_column_name : col.column_name }, { children: col["human_friendly_column_name"] }), col["column_name"]));
                }
            });
            options.push(_jsx(ListSubheader, __assign({ sx: {
                    fontSize: "12px",
                    color: "#272727",
                    lineHeight: "120%",
                    margin: "10px 0",
                } }, { children: "Other columns" }), "Hide"));
            options.push(_jsx(Divider, {}, "divider2"));
            aggsColumns.map(function (col) {
                if (!col.show) {
                    options.push(_jsx(MenuItem, __assign({ sx: {
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#404040",
                            lineHeight: "20px",
                        }, value: humanFriendly ? col.human_friendly_column_name : col.column_name }, { children: col["human_friendly_column_name"] }), col["column_name"]));
                }
            });
        }
        else if (type == "numeric") {
            aggsColumns.forEach(function (col) {
                if (col.show &&
                    col.column_data_type != "boolean" &&
                    col.column_data_type != "text") {
                    options.push(_jsx(MenuItem, __assign({ sx: {
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#404040",
                            lineHeight: "20px",
                            padding: "13px",
                        }, value: humanFriendly ? col.human_friendly_column_name : col.column_name }, { children: col["human_friendly_column_name"] }), col["column_name"]));
                }
            });
            options.push(_jsx(ListSubheader, __assign({ sx: {
                    fontSize: "12px",
                    color: "#272727",
                    lineHeight: "120%",
                    margin: "10px 0",
                } }, { children: "Other columns" }), "Hide"));
            options.push(_jsx(Divider, {}, "divider3"));
            aggsColumns.map(function (col) {
                if (!col.show &&
                    col.column_data_type != "boolean" &&
                    col.column_data_type != "text") {
                    options.push(_jsx(MenuItem, __assign({ sx: {
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#404040",
                            lineHeight: "20px",
                        }, value: humanFriendly ? col.human_friendly_column_name : col.column_name }, { children: col["human_friendly_column_name"] }), col["column_name"]));
                }
            });
        }
        return options;
    };
    var getColumnIndex = function (colName) {
        //Get index of a column by it's name/ human friendly name
        for (var i = 0; i < columns.length; i++) {
            if (columns[i].human_friendly_column_name == colName ||
                columns[i].column_name == colName)
                return i;
        }
        return -1;
    };
    var handleGroupByChange = function (event) {
        var value = event.target.value;
        setGroupByColumns((typeof value === "string" ? value.split(",") : value));
    };
    var handleRemoveAgg = function (e, index) {
        var copyAggRows = JSON.parse(JSON.stringify(aggRows));
        if (copyAggRows.length == 1) {
            setAggRows([
                {
                    function: "Min",
                    functionColumn: columns[0].column_name,
                    distinctByColumn: "None",
                    sortOrder: "None",
                },
            ]);
            return;
        }
        copyAggRows.splice(index, 1);
        setAggRows(copyAggRows);
        setCurrentNumAggs(function (prevNumAggs) { return prevNumAggs - 1; });
    };
    var aggOptions = function () {
        var optionRows = [];
        var options = [
            "Min",
            "Max",
            "Avg",
            "Sum",
            "Distinct count",
            "Distinct sum",
        ];
        var optionMap = {
            Min: "min",
            Max: "max",
            Avg: "avg",
            Sum: "sum",
            "Distinct count": "distinct_count",
            "Distinct sum": "distinct_sum",
        };
        options.map(function (op) {
            optionRows.push(_jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: op }, { children: op }), op));
        });
        return optionRows;
    };
    var handleChangeFunction = function (e, index) {
        var val = e.target.value;
        var copyAggRows = JSON.parse(JSON.stringify(aggRows));
        copyAggRows[index] = __assign(__assign({}, copyAggRows[index]), { function: val });
        if (val != "Distinct count") {
            var curFunColIndex = getColumnIndex(copyAggRows[index].functionColumn);
            if (columns[curFunColIndex].column_data_type == "boolean" ||
                columns[curFunColIndex].column_data_type == "text") {
                copyAggRows[index].functionColumn = columns[0].column_name;
            }
        }
        setAggRows(copyAggRows);
    };
    var handleChangeFunctionColumn = function (e, index) {
        var val = e.target.value;
        var copyAggRows = JSON.parse(JSON.stringify(aggRows));
        copyAggRows[index] = __assign(__assign({}, copyAggRows[index]), { functionColumn: val });
        setAggRows(copyAggRows);
    };
    var handleChangeDistinctColumn = function (e, index) {
        var val = e.target.value;
        var copyAggRows = JSON.parse(JSON.stringify(aggRows));
        copyAggRows[index] = __assign(__assign({}, copyAggRows[index]), { distinctByColumn: val });
        setAggRows(copyAggRows);
    };
    var handleChangeSortOrder = function (e, index) {
        var val = e.target.value;
        var copyAggRows = JSON.parse(JSON.stringify(aggRows));
        copyAggRows[index] = __assign(__assign({}, copyAggRows[index]), { sortOrder: val });
        setAggRows(copyAggRows);
    };
    var handleAddAggs = function () {
        var copyAggRows = JSON.parse(JSON.stringify(aggRows));
        copyAggRows[currentNumAggs] = __assign(__assign({}, copyAggRows[currentNumAggs]), { function: "Min", functionColumn: columns[0].column_name, distinctByColumn: "None", sortOrder: "None" });
        setAggRows(copyAggRows);
        setCurrentNumAggs(function (prevNumAggs) { return prevNumAggs + 1; });
    };
    var handleSave = function () {
        if (aggRows.length == 0)
            return;
        var newGroupByColumns = [];
        groupByColumns.forEach(function (col) {
            var idx = getColumnIndex(col);
            newGroupByColumns.push(columns[idx].column_name);
        });
        var newQueryOptions = aggRowsToJson(aggRows, queryOptions, newGroupByColumns, variableToFriendlyColumn);
        if (restoreState)
            setRestoreState(false);
        setQueryOptions(newQueryOptions);
        setModeType(ModeType.agg);
        addModes(ModeType.agg);
        removeModes(ModeType.vis);
        setChart(initialDDTChartState);
        handleClose();
    };
    var handleReset = function () {
        var newAggRows = [
            {
                function: "Min",
                functionColumn: columns[0].column_name,
                distinctByColumn: "None",
                sortOrder: "None",
            },
        ];
        setAggRows(newAggRows);
        var newQueryOption = JSON.parse(JSON.stringify(queryOptions));
        newQueryOption.aggs = undefined;
        setQueryOptions(newQueryOption);
        setModeType(ModeType.data);
        removeModes(ModeType.agg);
        removeModes(ModeType.vis);
        handleClose();
        setGroupByColumns([]);
        setCurrentNumAggs(1);
    };
    var showAggRows = function () {
        var rows = [];
        if (aggRows.length == 0)
            return;
        var _loop_4 = function (i) {
            rows.push(_jsxs(Box, __assign({ sx: {
                    display: "flex",
                    alignItems: "center",
                    height: "65px",
                } }, { children: [_jsx("span", __assign({ onClick: function (e) { return handleRemoveAgg(e, i); }, tabIndex: 0, "aria-label": "Remove", onKeyDown: function (event) {
                            event.key == "Enter" && handleRemoveAgg(event, i);
                        }, style: { cursor: "pointer", margin: "0 5px" }, "data-id": i }, { children: "\u2715" })), _jsxs(Box, __assign({ sx: {
                            display: "flex",
                            alignItems: "center",
                            minWidth: "290px",
                            paddingLeft: "10px",
                            overflow: "hidden",
                        } }, { children: [_jsx(Select, __assign({ value: aggRows[i].function, onChange: function (e) { return handleChangeFunction(e, i); }, sx: {
                                    margin: "0 5px",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    maxWidth: "125px",
                                } }, { children: aggOptions() })), _jsx(Typography, __assign({ sx: { margin: "0 5px" } }, { children: "of" })), _jsx(Select, __assign({ value: aggRows[i].functionColumn, onChange: function (e) { return handleChangeFunctionColumn(e, i); }, sx: {
                                    margin: "0 5px",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    maxWidth: "260px",
                                    overflow: "hidden",
                                } }, { children: aggRows[i].function == "Distinct count"
                                    ? colDropdown(false, "all", false)
                                    : colDropdown(false, "numeric", false) }))] })), _jsx(Box, __assign({ sx: { minWidth: "140px", paddingLeft: "10px" } }, { children: _jsx(Select, __assign({ value: aggRows[i].distinctByColumn, onChange: function (e) { return handleChangeDistinctColumn(e, i); }, sx: {
                                fontSize: "14px",
                                fontWeight: "400",
                                maxWidth: "130px",
                                overflow: "hidden",
                            } }, { children: colDropdown(true, "all", false) })) })), _jsx(Box, __assign({ sx: { width: "320px", padding: "0 0 0 20px" } }, { children: _jsx(Select, __assign({ value: aggRows[i].sortOrder, onChange: function (e) { return handleChangeSortOrder(e, i); }, sx: {
                                fontSize: "14px",
                                fontWeight: "400",
                                maxWidth: "300px",
                            } }, { children: sortOption() })) }))] }), i));
        };
        for (var i = 0; i < aggRows.length; i++) {
            _loop_4(i);
        }
        return rows;
    };
    return (_jsx(ClickAwayListener, __assign({ onClickAway: handleClose, mouseEvent: "onMouseUp" }, { children: _jsxs(Box, __assign({ sx: {
                width: "227px",
                padding: "17px  17px 0px 17px",
            } }, { children: [_jsx(Typography, __assign({ sx: {
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#595959",
                        marginBottom: "6px",
                    } }, { children: "Aggregation" })), _jsxs(Box, __assign({ onClick: handleClick, tabIndex: 0, role: "button", onKeyDown: function (event) {
                        event.key == "Enter" && handleClick(event);
                    }, sx: {
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "12px 17px",
                        boxSizing: "border-box",
                        cursor: "pointer",
                        width: "227px",
                        height: "44px",
                        alignItems: "center",
                        border: "1px solid #949494",
                        borderRadius: "3px",
                        fontWeight: "400",
                        "&:hover": {
                            boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(0deg 0% 74%) 0 0 0 3px",
                        },
                        "&:focus-visible": {
                            boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(210deg 100% 35%) 0 0 0 2px, hsl(210deg 100% 85%) 0 0 0 4px",
                            outline: "none",
                        },
                    } }, { children: [!((_b = queryOptions.aggs) === null || _b === void 0 ? void 0 : _b.aggs) ||
                            Object.keys(queryOptions.aggs.aggs).length == 0 ? (_jsx(Typography, __assign({ component: "span", variant: "body2", sx: { color: "#595959" } }, { children: "Select Aggregation" }))) : (_jsxs(Typography, __assign({ component: "span", variant: "body2", sx: { color: "#404040" } }, { children: [Object.keys(queryOptions.aggs.aggs).length, " aggregation"] }))), _jsx(FunctionsIcon, { sx: { color: "#595959" } })] })), mode.active == ModeType.data &&
                    mode.availableModes.includes(ModeType.agg) && (_jsx(Typography, __assign({ color: "red", p: "3px", fontSize: "10px" }, { children: "Aggregation applied, may lead to slow results" }))), _jsx(Popper, __assign({ id: id, open: open, anchorEl: anchorEl, placement: "auto-start", modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 15],
                            },
                        },
                    ], sx: { zIndex: "1000" } }, { children: _jsx(FocusTrap, __assign({ open: open }, { children: _jsxs(Box, __assign({ sx: {
                                width: "625px",
                                border: "1px solid #EDEDED",
                                background: "#FFFFFF",
                                display: "flex",
                                flexDirection: "column",
                                padding: "12px",
                                boxShadow: "1",
                            }, tabIndex: -1 }, { children: [_jsxs(Box, __assign({ sx: {
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "0 2px",
                                        margin: "10px 0 20px 0",
                                    } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: {
                                                color: "#272727",
                                                marginBottom: 0,
                                                alignSelf: "center",
                                                fontSize: "18px",
                                                fontWeight: "500",
                                            } }, { children: "Aggregation" })), _jsx(CloseButton, { size: "small", "aria-label": "close", onClick: handleClose })] })), _jsxs(Box, __assign({ sx: {
                                        display: "flex",
                                        alignItems: "center",
                                    } }, { children: [_jsx(Typography, __assign({ sx: {
                                                margin: "0 10px",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                color: "#272727",
                                            } }, { children: "Group by" })), _jsx(FormControl, __assign({ sx: { m: 1, minWidth: "125px", maxWidth: "610px" } }, { children: _jsx(Select, __assign({ input: _jsx(OutlinedInput, { id: "select-group", sx: { color: "black" }, label: "Columns", placeholder: "Columns" }), multiple: true, id: "groupBy", labelId: "columns", value: groupByColumns, label: "Group by", onChange: handleGroupByChange, placeholder: "Columns", 
                                                //@ts-ignore
                                                renderValue: function (selected) { return (_jsx(Box, __assign({ sx: { display: "flex", flexWrap: "wrap", gap: 0.5 } }, { children: selected.map(function (value) { return (_jsx(Chip, { label: value }, value)); }) }))); }, sx: {
                                                    color: "#949494",
                                                } }, { children: colDropdown(false, "all", true) })) }))] })), _jsx(Divider, {}), _jsxs(Box, __assign({ sx: {
                                        display: "flex",
                                        margin: "15px 0 10px 0",
                                        paddingLeft: "30px",
                                    } }, { children: [_jsx(Typography, __assign({ sx: {
                                                fontWeight: "600",
                                                fontSize: "16px",
                                                color: "#272727",
                                                marginRight: "235px",
                                            } }, { children: "Function" })), _jsx(Typography, __assign({ sx: {
                                                fontWeight: "600",
                                                fontSize: "16px",
                                                color: "#272727",
                                                marginRight: "75px",
                                            } }, { children: "Distinct by" })), _jsx(Typography, __assign({ sx: { fontWeight: "600", fontSize: "16px", color: "#272727" } }, { children: "Sort" }))] })), _jsx(Divider, { sx: { margin: "0px 0" } }), _jsx(Box, __assign({ sx: { height: "136px", overflowY: "auto" } }, { children: showAggRows() })), _jsx(Box, __assign({ sx: {
                                        minHeight: "34px",
                                        maxHeight: "95px",
                                        overflowY: "auto",
                                        margin: "10px 20px",
                                    } }, { children: error && showAggsError() })), _jsxs(Box, __assign({ style: {
                                        margin: "10px 0 15px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }, className: "addAggs" }, { children: [_jsxs(Box, __assign({ onClick: handleAddAggs, tabIndex: 0, onKeyDown: function (event) {
                                                event.key == "Enter" && handleAddAggs();
                                            }, sx: { display: "flex", cursor: "pointer" } }, { children: [_jsx("span", __assign({ style: { alignSelf: "center", margin: "0 10px" } }, { children: _jsx("img", { src: plus, "aria-hidden": "true", alt: "plus icon" }) })), _jsx(Typography, __assign({ sx: {
                                                        color: "#004080",
                                                        fontWeight: 500,
                                                        fontSize: "16px",
                                                        alignSelf: "center",
                                                        margin: "0px",
                                                    } }, { children: "Add function" }))] })), _jsxs(Box, __assign({ sx: {
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "185px",
                                                marginRight: "10px",
                                            } }, { children: [_jsx(Button, __assign({ onClick: handleReset, variant: "contained", color: "secondary" }, { children: "Reset" })), _jsx(Button, __assign({ onClick: handleSave, variant: "contained", color: "primary" }, { children: "Apply" }))] }))] }))] })) })) })), _jsx(Snackbar, __assign({ open: disable, autoHideDuration: 6000, onClose: function () { return setDisable(false); } }, { children: _jsx(Alert, __assign({ variant: "filled", onClose: function () { return setDisable(false); }, severity: "error" }, { children: "Select some columns before applying aggregation" })) }))] })) })));
};
export default AggregationMenu;
