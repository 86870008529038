var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
var parseJwt = function (token) {
    var jsonPayload = "";
    try {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        jsonPayload = decodeURIComponent(window
            .atob(base64)
            .split("")
            .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
            .join(""));
    }
    catch (e) {
        throw e;
        //this is caught in login and invalid token message is displayed
    }
    return JSON.parse(jsonPayload);
};
export var decodeUserInfo = function (token) {
    var payload = parseJwt(token);
    return JSON.parse(window.atob(payload.payload));
};
export var arraysEqual = function (a, b) {
    if (a === b)
        return true;
    if (a == null || b == null)
        return false;
    if (a.length !== b.length)
        return false;
    a.sort();
    b.sort();
    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i])
            return false;
    }
    return true;
};
export var isJsonParsable = function (jsonString) {
    /* 3 cases possible :
  
        1. data is not a valid object/array, return  'a'
        2. data is a valid object/array , return 'b'
        3. data is a string which can be parsed to a valid object/array, return 'c'
  
    */
    try {
        if (jsonString && typeof jsonString === "object") {
            return "b";
        }
        var o = JSON.parse(jsonString);
        if (o && typeof o === "object")
            return "c";
    }
    catch (e) {
        return "a";
    }
    return "a";
};
export var curlGenerate = function (url, token, body) {
    var curl = "curl -L '".concat(url, "' -H \"authorization: Bearer ").concat(token, "\" -H \"content-type: application/json\" --data-raw '").concat(JSON.stringify(body), "'");
    return curl;
};
export var addOtherColumnsMetadata = function (cols) {
    //Object is passed by reference so changes are reflected directly
    //Check if these columns were already added
    var otherColPresent = false;
    cols.forEach(function (col) {
        //Either both the cols are added or none, so check if even one is present
        if (col.column_name == "item_status-current" ||
            col.column_name == "item_lifecycle_state-current") {
            otherColPresent = true;
        }
    });
    if (!otherColPresent) {
        var itemStatusObj = {
            column_name: "item_status-current",
            human_friendly_column_name: "Item status",
            column_description: "The current item status from Content",
            column_display_order_rank: 48,
            column_categories: ["section", "question"],
            column_data_type: "text",
            provide_auto_selection: true,
        };
        var itemLifeCycleStateObj = {
            column_name: "item_lifecycle_state-current",
            human_friendly_column_name: "Item lifecycle state",
            column_description: "IRT lifecycle state from content",
            column_display_order_rank: 49,
            column_categories: ["section", "question"],
            column_data_type: "text",
            provide_auto_selection: true,
        };
        cols.push(itemStatusObj, itemLifeCycleStateObj);
    }
};
export var styleHeaders = function (header) {
    if (header.column_name.endsWith("-current")) {
        return (_jsxs(Box, __assign({ sx: { display: "flex" } }, { children: [_jsx(Typography, __assign({ sx: { cursor: "pointer", fontWeight: "500" } }, { children: header.human_friendly_column_name })), _jsx(Typography, __assign({ sx: {
                        color: "#272727",
                        fontSize: "12px",
                        fontWeight: "500",
                        background: "#EDEDED",
                        padding: "6px 4px",
                        marginLeft: "7px",
                    } }, { children: "Current" }))] })));
    }
    else {
        return (_jsx(Typography, __assign({ sx: { cursor: "pointer", fontWeight: "500" } }, { children: header.human_friendly_column_name })));
    }
};
//This is only used for hashing userIds on client side to obfuscate the original userIds 
//Note : not to be used for other hashing requirements
export var hashCode = function (s) {
    var hash = 0, i, chr;
    if (s.length === 0)
        return hash;
    for (i = 0; i < s.length; i++) {
        chr = s.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};
