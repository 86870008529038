var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableHead, TableRow, } from "@inspera/component-library";
import { TableCell } from "@mui/material";
import ReactJson from "@microlink/react-json-view";
import { isJsonParsable } from "../../../../Utils/util";
import { AggHeaderCell } from "./AggHeaderCell";
import { EmptyAggTable } from "./EmptyAggTable";
export var AggregationTable = function (_a) {
    var _b;
    var data = _a.data, groupedBy = _a.groupedBy, aggNames = _a.aggNames, ddtHeaders = _a.ddtHeaders, updateHeaders = _a.updateHeaders;
    if (data.aggs != null && data.aggs.rows.length > 0)
        return (_jsxs(Table, __assign({ stickyHeader: true, size: "small" }, { children: [_jsx(TableHead, { children: _jsx(TableRow, __assign({ sx: { height: "56px" } }, { children: Object.keys((_b = data === null || data === void 0 ? void 0 : data.aggs) === null || _b === void 0 ? void 0 : _b.rows[0]).map(function (header) { return (_jsx(AggHeaderCell
                        // align={data.aggs.rows[0].length <= 2 ? "left" : "right"}
                        , { 
                            // align={data.aggs.rows[0].length <= 2 ? "left" : "right"}
                            groupedBy: groupedBy, align: "left", aggNames: aggNames, value: header, updateCell: updateHeaders, ddtHeaders: ddtHeaders }, "header" + header)); }) })) }), _jsx(TableBody, { children: data.aggs.rows.map(function (row, index) {
                        return (_jsx(TableRow, __assign({ sx: {
                                overflow: "clip",
                                height: "56px",
                            }, hover: true, role: "checkbox", tabIndex: -1 }, { children: Object.keys(row).map(function (header) {
                                return (_jsx(TableCell, __assign({ component: "th", align: "left", 
                                    // { Object.keys(row).length - 2 <= 2 ? "left" : "right"}
                                    scope: "row", sx: {
                                        backgroundColor: "white",
                                        width: "200px",
                                        px: "16px",
                                        textOverflow: "ellipsis",
                                        maxHeight: "56px",
                                        overflow: "hidden",
                                    }, padding: "none" }, { children: isJsonParsable(row[header]) == "b" ? (_jsx(ReactJson, { src: row[header], collapsed: true, name: false, displayDataTypes: false, 
                                        //@ts-ignore
                                        displayArrayKey: false, groupArraysAfterLength: 5, style: {
                                            minWidth: "180px",
                                        } })) : (row[header]) }), "aggCell" + header));
                            }) }), index));
                    }) })] })));
    else
        return _jsx(EmptyAggTable, {});
};
