var criteriaToES = {
    is: "equal",
    "is not": "not_equal",
    "is null": "is_null",
    "is not null": "is_not_null",
    "greater than": "gt",
    "greater than equal to": "gte",
    "less than": "lt",
    "less than equal to": "lte",
    includes: "includes",
    excludes: "excludes",
};
export var createESFilter = function (filterRows) {
    var _a, _b;
    var filterObj = { filters: [] };
    for (var _i = 0, filterRows_1 = filterRows; _i < filterRows_1.length; _i++) {
        var row = filterRows_1[_i];
        if (row == null) {
            continue;
        }
        var criteria_present = 0;
        if (filterObj["filters"].length > 0) {
            for (var _c = 0, _d = filterObj["filters"]; _c < _d.length; _c++) {
                var esquery = _d[_c];
                if (esquery.criteria == criteriaToES[row["criteria"]] &&
                    esquery.field_name == row["column"]) {
                    criteria_present = 1;
                    var val = row["value"];
                    if (!isNaN(val) && val.trim().length != 0)
                        val = Number(val);
                    if (criteriaToES[row["criteria"]] == "equal") {
                        (_a = esquery === null || esquery === void 0 ? void 0 : esquery.allowed_values) === null || _a === void 0 ? void 0 : _a.push(String(val));
                    }
                    else if (criteriaToES[row["criteria"]] == "not_equal") {
                        (_b = esquery === null || esquery === void 0 ? void 0 : esquery.allowed_values) === null || _b === void 0 ? void 0 : _b.push(String(val));
                    }
                    else if (criteriaToES[row["criteria"]] == "gt") {
                        esquery.range.gt = Math.max(esquery.range.gt, val);
                    }
                    else if (criteriaToES[row["criteria"]] == "gte") {
                        esquery.range.gte = Math.max(esquery.range.gte, val);
                    }
                    else if (criteriaToES[row["criteria"]] == "lt") {
                        esquery.range.lt = Math.min(esquery.range.lt, val);
                    }
                    else if (criteriaToES[row["criteria"]] == "lte") {
                        esquery.range.lte = Math.min(esquery.range.lte, val);
                    }
                    else if (criteriaToES[row["criteria"]] == "includes") {
                        criteria_present = 0;
                    }
                    else if (criteriaToES[row["criteria"]] == "excludes") {
                        criteria_present = 0;
                    }
                }
            }
        }
        if (criteria_present)
            continue;
        var query = {};
        query["field_name"] = row["column"];
        var value = row["value"];
        if (!isNaN(value) && value.trim().length != 0)
            value = Number(value);
        if (criteriaToES[row["criteria"]] == "equal") {
            query["allowed_values"] = [value];
            query["invert_filter"] = false;
            query["criteria"] = "equal";
        }
        else if (criteriaToES[row["criteria"]] == "not_equal") {
            query["allowed_values"] = [value];
            query["invert_filter"] = true;
            query["criteria"] = "not_equal";
        }
        else if (criteriaToES[row["criteria"]] == "is_null") {
            query["is_null"] = true;
        }
        else if (criteriaToES[row["criteria"]] == "is_not_null") {
            query["is_null"] = false;
        }
        else if (criteriaToES[row["criteria"]] == "gt") {
            query["range"] = { gt: Number(value) };
            query["invert_filter"] = false;
            query["criteria"] = "gt";
        }
        else if (criteriaToES[row["criteria"]] == "gte") {
            query["range"] = { gte: Number(value) };
            query["invert_filter"] = false;
            query["criteria"] = "gte";
        }
        else if (criteriaToES[row["criteria"]] == "lt") {
            query["range"] = { lt: Number(value) };
            query["invert_filter"] = false;
            query["criteria"] = "lt";
        }
        else if (criteriaToES[row["criteria"]] == "lte") {
            query["range"] = { lte: Number(value) };
            query["invert_filter"] = false;
            query["criteria"] = "lte";
        }
        else if (criteriaToES[row["criteria"]] == "includes") {
            if (["question_metadata", "section_metadata"].includes(row.column))
                query["includes_text"] = value;
            else
                query["wildcard"] = "*".concat(value, "*");
            query["invert_filter"] = false;
            query["criteria"] = "includes";
        }
        else if (criteriaToES[row["criteria"]] == "excludes") {
            if (["question_metadata", "section_metadata"].includes(row.column))
                query["includes_text"] = value;
            else
                query["wildcard"] = "*".concat(value, "*");
            query["invert_filter"] = true;
            query["criteria"] = "excludes";
        }
        filterObj["filters"].push(query);
    }
    filterObj["filters"].forEach(function (qr) { return delete qr.criteria; });
    return filterObj.filters;
};
