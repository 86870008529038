var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem, Select } from "@inspera/component-library";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useStore from "../../Store/store";
import _ from "lodash";
var RowPerPage = function () {
    var _a = useStore(), queryOptions = _a.queryOptions, setQueryOptions = _a.setQueryOptions, setPage = _a.setPage, restoreState = _a.restoreState, setRestoreState = _a.setRestoreState;
    var _b = useState(String(queryOptions.size)), rowsPerPage = _b[0], setRowsPerPage = _b[1];
    var perPage = ["10", "25", "50", "100"];
    var handleSelect = function (e) {
        var newQueryOptions = _.cloneDeep(queryOptions);
        var val = e.target.value;
        newQueryOptions.size = Number(val);
        if (restoreState)
            setRestoreState(false);
        setPage(1);
        setQueryOptions(newQueryOptions);
        setRowsPerPage(val);
    };
    useEffect(function () {
        setRowsPerPage(String(queryOptions.size));
    }, [queryOptions.size]);
    return (_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center" } }, { children: [_jsx(Typography, __assign({ sx: {
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#000000",
                    margin: "0 10px 0 10px",
                } }, { children: "Show" })), _jsx(Select, __assign({ sx: {
                    fontSize: "14px",
                    fontWeight: "400",
                    backgroundColor: "white",
                }, value: rowsPerPage, onChange: handleSelect }, { children: perPage.map(function (row, i) { return (_jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: row }, { children: "".concat(row, " rows") }), i)); }) }))] })));
};
export default RowPerPage;
