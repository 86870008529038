var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import createFilter from "../../../../Assets/images/CreateFilter.svg";
import { PresetCard } from "./PresetCard";
import { ArrowForward } from "@mui/icons-material";
import { Modal } from "@inspera/component-library";
import { useState } from "react";
import PresetTable from "../../../Modal/PresetTable/PresetTable";
import useStore from "../../../../Store/store";
export var TableInfoPanel = function (_a) {
    var _b = _a.isSourceSelected, isSourceSelected = _b === void 0 ? true : _b;
    var presets = useStore().presets;
    var _c = useState(false), openModal = _c[0], setOpenModal = _c[1];
    var previewPresets = presets ? presets.slice(0, 3) : [];
    return (_jsxs(Box, __assign({ sx: {
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            justifySelf: "center",
            display: "flex",
            paddingBottom: "23px",
            bgcolor: "#F7F7F7",
        } }, { children: [_jsxs(Box, __assign({ sx: {
                    bgcolor: "white",
                    justifySelf: "center",
                    boxSizing: "border-box",
                    border: "1px solid #EDEDED",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: isSourceSelected || previewPresets.length == 0 ? "100%" : "50%",
                    justifyContent: "center",
                    alignItems: "center",
                } }, { children: [isSourceSelected ? (_jsx(Typography, __assign({ variant: "h3", textTransform: "none" }, { children: "Select columns to view data" }))) : (_jsx(Typography, __assign({ marginTop: "-60px", variant: "h3", textTransform: "none" }, { children: "Select source to view data" }))), _jsx(Typography, { children: "You can find the filter on your left hand side" }), _jsx("img", { src: createFilter, alt: "Empty Table" })] })), !isSourceSelected && previewPresets.length > 0 && (_jsxs(Box, __assign({ sx: {
                    bgcolor: "white",
                    width: "50%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingLeft: "30px",
                } }, { children: [_jsx(Typography, __assign({ variant: "h3" }, { children: "Or use a template to get started" })), _jsx(Box, { children: previewPresets.map(function (preset, i) {
                            return _jsx(PresetCard, { preset: preset }, i);
                        }) }), _jsxs(Box, __assign({ sx: {
                            display: "flex",
                            marginTop: "25px",
                            cursor: "pointer",
                            width: "fit-content",
                        }, onClick: function () { return setOpenModal(true); } }, { children: [_jsx(Typography, __assign({ color: "#004080", marginRight: "10px", variant: "h3" }, { children: "See more presets" })), _jsx(ArrowForward, { sx: { color: "#004080" } })] }))] }))), _jsx(Modal, __assign({ open: openModal, onBackdropClick: function () { return setOpenModal(false); }, onClose: function () { return setOpenModal(false); }, width: "lg", fullWidth: false }, { children: _jsx(PresetTable, { handleClose: function () { return setOpenModal(false); } }) }))] })));
};
