var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Icon, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import grid_fill from "../../Assets/images/grid_fill.svg";
import _ from "lodash";
import React, { useEffect, useState, } from "react";
import { ErrorType, ModeType, } from "../../Model/model";
import { Button, Menu, MenuItem, Modal } from "@inspera/component-library";
import EditView from "../Modal/EditView";
import ViewCurl from "../Modal/ViewCurl";
import CloneView from "../Modal/CloneView";
import ShareView from "../Modal/ShareView";
import DeleteView from "../Modal/DeleteView";
import useStore from "../../Store/store";
import { arraysEqual } from "../../Utils/util";
import { apiCore } from "../../Api/apiCore";
import { handleError } from "../../Utils/handleError";
import SaveView from "../Modal/SaveView";
import OverwriteView from "../Modal/OverwriteView";
import Export from "../Modal/Export";
import { OptionBtn } from "./OptionBtn";
import { initialDDTChartState } from "../../Model/initialStates";
var Titlebar = function () {
    var _a, _b, _c, _d;
    var _e = useStore(), current = _e.current, visibleColumns = _e.visibleColumns, columns = _e.columns, sharedView = _e.sharedView, queryOptions = _e.queryOptions, mode = _e.mode, aggHeaders = _e.aggHeaders, userId = _e.userId, token = _e.token, chart = _e.chart, setError = _e.setError, fetchVirtualColumns = _e.fetchVirtualColumns, deduplicate = _e.deduplicate, dataViewData = _e.dataViewData;
    var _f = useState(false), disable = _f[0], setDisable = _f[1];
    var _g = useState(false), saveAsModal = _g[0], setSaveAsModal = _g[1];
    var _h = React.useState(null), saveAsAnchorEl = _h[0], setSaveAsAnchorEl = _h[1];
    // States to track change in manage columns and queryOptions
    var _j = useState(false), columnChange = _j[0], setColumnChange = _j[1];
    var _k = useState(false), queryChange = _k[0], setQueryChange = _k[1];
    var _l = useState(false), aggHeaderChange = _l[0], setAggHeaderChange = _l[1];
    var _m = useState(false), visChange = _m[0], setVisChange = _m[1];
    var _o = useState(false), deduplicateChange = _o[0], setDeduplicateChange = _o[1];
    //TODO : Refactor above states using useReducer
    //Dropdown menu
    var _p = React.useState(null), anchorEl = _p[0], setAnchorEl = _p[1];
    var openMenu = Boolean(anchorEl);
    var menuValues = ["Edit", "View cURL", "Clone", "Export", "Share"];
    //Modal
    var _q = useState(false), openModal = _q[0], setOpenModal = _q[1];
    var _r = useState("Title"), modalTitle = _r[0], setModalTitle = _r[1];
    var _s = useState(_jsx(_Fragment, {})), modal = _s[0], setModalContent = _s[1];
    var _t = useState(""), title = _t[0], setTitle = _t[1];
    var _u = useState(""), description = _u[0], setDescription = _u[1];
    useEffect(function () {
        var _a, _b, _c;
        if ((current === null || current === void 0 ? void 0 : current.viewDef) != null) {
            if (!current.viewDef.parameters.ddt_frontend.agg_headers && !aggHeaders)
                setAggHeaderChange(false);
            else if (_.isEqual((_c = (_b = (_a = current === null || current === void 0 ? void 0 : current.viewDef) === null || _a === void 0 ? void 0 : _a.parameters) === null || _b === void 0 ? void 0 : _b.ddt_frontend) === null || _c === void 0 ? void 0 : _c.agg_headers, aggHeaders)) {
                // view aggHeaders and current aggHeaders are equal
                setAggHeaderChange(false);
            }
            else {
                setAggHeaderChange(true);
            }
        }
    }, [aggHeaders]);
    useEffect(function () {
        var _a, _b, _c, _d;
        if ((current === null || current === void 0 ? void 0 : current.viewDef) != null) {
            var newParam = _.cloneDeep(chart);
            if (((_b = (_a = current.viewDef.parameters) === null || _a === void 0 ? void 0 : _a.ddt_frontend.visualization) === null || _b === void 0 ? void 0 : _b.chart) != null) {
                if (newParam.dataset != null)
                    delete newParam.dataset.source;
                if (_.isEqual((_d = (_c = current.viewDef.parameters) === null || _c === void 0 ? void 0 : _c.ddt_frontend.visualization) === null || _d === void 0 ? void 0 : _d.chart, newParam)) {
                    setVisChange(false);
                }
                else {
                    setVisChange(true);
                }
            }
            else {
                if (_.isEqual(newParam, initialDDTChartState))
                    setVisChange(false);
                else {
                    setVisChange(true);
                }
            }
        }
    }, [JSON.stringify(chart)]);
    useEffect(function () {
        var _a, _b;
        if (!columns || !(current === null || current === void 0 ? void 0 : current.viewDef))
            return;
        var prevCols = (_b = (_a = current === null || current === void 0 ? void 0 : current.viewDef) === null || _a === void 0 ? void 0 : _a.parameters) === null || _b === void 0 ? void 0 : _b.body.columns;
        var currCols = [];
        columns.forEach(function (col) {
            if (col.show)
                currCols.push(col.column_name);
        });
        if (arraysEqual(prevCols, currCols)) {
            setColumnChange(false);
        }
        else {
            setColumnChange(true);
        }
    }, [columns]);
    useEffect(function () {
        var _a, _b;
        if ((current === null || current === void 0 ? void 0 : current.viewDef) != null) {
            var viewQueryOption_1 = _.cloneDeep((_b = (_a = current === null || current === void 0 ? void 0 : current.viewDef) === null || _a === void 0 ? void 0 : _a.parameters) === null || _b === void 0 ? void 0 : _b.body);
            viewQueryOption_1.columns = [];
            var newQueryOptions_1 = _.cloneDeep(queryOptions);
            newQueryOptions_1.columns = [];
            //remove null entries before comparing
            Object.keys(viewQueryOption_1).forEach(function (k) {
                return viewQueryOption_1[k] == null &&
                    delete viewQueryOption_1[k];
            });
            Object.keys(newQueryOptions_1).forEach(function (k) {
                return newQueryOptions_1[k] == null &&
                    delete newQueryOptions_1[k];
            });
            if (_.isEqual(newQueryOptions_1, viewQueryOption_1))
                setQueryChange(false);
            else {
                setQueryChange(true);
            }
        }
    }, [queryOptions]);
    useEffect(function () {
        var _a;
        if ((current === null || current === void 0 ? void 0 : current.viewDef) != null) {
            //in view definition deduplicate means false, if it's undefined
            var viewDeduplicate = (_a = current.viewDef.parameters.ddt_frontend.deduplicate) !== null && _a !== void 0 ? _a : false;
            if (deduplicate != viewDeduplicate)
                setDeduplicateChange(true);
            else {
                if (deduplicateChange)
                    setDeduplicateChange(false);
            }
        }
    }, [deduplicate]);
    useEffect(function () {
        //Set title and  description based on the source/view/preset/dataview selected
        if (current === null || current === void 0 ? void 0 : current.viewDef) {
            setTitle(current.viewDef.name);
            setDescription(current.viewDef.description);
        }
        else if (current === null || current === void 0 ? void 0 : current.preset) {
            setTitle(current.preset.title);
            setDescription(current.preset.description);
        }
        else if (current === null || current === void 0 ? void 0 : current.dataView) {
            setTitle(current.dataView.title);
            setDescription(current.dataView.description);
        }
        else if (current === null || current === void 0 ? void 0 : current.source) {
            setTitle(current.source.human_friendly_data_source_name);
            setDescription(current.source.data_source_description);
        }
        else {
            setTitle("Untitled");
            setDescription("");
        }
    }, [JSON.stringify(current)]);
    var handleMenuClose = function (event) {
        setAnchorEl(null);
        event.stopPropagation();
    };
    var handleDropdownMenu = function (event) {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };
    var handleSaveModal = function (event) {
        setSaveAsAnchorEl(event.currentTarget);
        setSaveAsModal(function (save) { return !save; });
    };
    var handleAggExport = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, apiCore
                        .exportCsv(token, (_a = current === null || current === void 0 ? void 0 : current.source) === null || _a === void 0 ? void 0 : _a.data_source_url_slug, true, queryOptions, true, fetchVirtualColumns, deduplicate, aggHeaders)
                        .catch(function (err) {
                        handleError(setError, err, ErrorType.ERROR, "");
                    })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleModalClick = function (event) {
        setAnchorEl(null);
        switch (event.currentTarget.dataset.myValue) {
            case "Save":
                setModalTitle("Save View");
                setModalContent(_jsx(SaveView, { setModal: setOpenModal }));
                break;
            case "Edit":
                setModalTitle("Edit View");
                setModalContent(_jsx(EditView, { view: current === null || current === void 0 ? void 0 : current.viewDef, setModal: setOpenModal }));
                break;
            case "Overwrite":
                setModalTitle("Overwrite View");
                setModalContent(_jsx(OverwriteView, { view: current === null || current === void 0 ? void 0 : current.viewDef, setModal: setOpenModal, setColumnChange: setColumnChange, setQueryChange: setQueryChange, setAggHeaderChange: setAggHeaderChange, setVisChange: setVisChange }));
                break;
            case "newView":
                setModalTitle("New View");
                setModalContent(_jsx(SaveView, { setModal: setOpenModal }));
                break;
            case "Clone":
                setModalTitle("Clone View");
                setModalContent(_jsx(CloneView, { view: current === null || current === void 0 ? void 0 : current.viewDef, setModal: setOpenModal }));
                break;
            case "View cURL":
                setModalTitle("View cURL");
                setModalContent(_jsx(ViewCurl, { view: current === null || current === void 0 ? void 0 : current.viewDef }));
                break;
            case "Delete":
                setModalTitle("Delete View");
                setModalContent(_jsx(DeleteView, { view: current === null || current === void 0 ? void 0 : current.viewDef, setModal: setOpenModal }));
                break;
            case "Share":
                setModalTitle("Share");
                setModalContent(_jsx(ShareView, { view: current === null || current === void 0 ? void 0 : current.viewDef }));
                break;
            case "Export":
                if (visibleColumns == 0 && mode.active == ModeType.data) {
                    setDisable(true);
                    return;
                }
                if (mode.active == ModeType.agg) {
                    handleAggExport();
                    return;
                }
                setModalTitle("Export");
                setModalContent(_jsx(Export, { close: handleCloseModal }));
            default:
                // TODO
                break;
        }
        setOpenModal(true);
    };
    var handleCloseModal = function () { return setOpenModal(false); };
    var handleCloseSnack = function () {
        setDisable(false);
    };
    var handleDataViewExport = function () {
        setAnchorEl(null);
        setOpenModal(true);
        setModalTitle("Export");
        setModalContent(_jsx(Export, { close: handleCloseModal }));
    };
    return (_jsxs(Grid, __assign({ container: true, height: "77px", sx: {
            background: "white",
            alignItems: "center",
            justifyContent: "flex-start",
        } }, { children: [_jsxs(Stack, __assign({ direction: "row", width: "100%", alignItems: "center", whiteSpace: "nowrap" }, { children: [_jsxs(Stack, __assign({ mx: "16px", direction: "row", bgcolor: "#F7F7F7", borderRadius: "3px", alignItems: "center", px: "8px", width: "auto", maxWidth: "350px", py: "6px" }, { children: [_jsx(Icon, { children: _jsx("img", { src: grid_fill, alt: "grid icon", "aria-hidden": "true" }) }), _jsx(Typography, __assign({ sx: {
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    ml: "12px",
                                    p: 0,
                                    flex: 1,
                                    fontSize: "22px",
                                    fontWeight: "500",
                                }, lineHeight: "25.5px", "aria-label": title }, { children: title }))] })), _jsx(Box, __assign({ sx: { flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis" } }, { children: _jsx(Typography, __assign({ whiteSpace: "nowrap", sx: {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                margin: "0px",
                                width: "auto",
                                marginLeft: "6px",
                                color: "#595959",
                                textDecoration: "none",
                            } }, { children: description })) })), (current === null || current === void 0 ? void 0 : current.dataView) && dataViewData && (_jsx(Box, { children: _jsx(Button, __assign({ variant: "contained", sx: {
                                width: "84px",
                                height: "44px",
                            }, color: "secondary", "data-my-value": "Export", onClick: handleDataViewExport }, { children: "Export" })) })), _jsxs(Box, __assign({ mr: "17px", sx: { alignSelf: "flex-end" } }, { children: [!(current === null || current === void 0 ? void 0 : current.viewDef) ? ((visibleColumns > 0 || queryOptions.aggs != null) &&
                                !(current === null || current === void 0 ? void 0 : current.dataView) && (_jsx(Button, __assign({ variant: "contained", "data-my-value": "Save", sx: { bgcolor: "#0059B2", width: "102px", height: "44px" }, onClick: handleModalClick }, { children: _jsx(Typography, __assign({ variant: "button", color: "white", whiteSpace: "nowrap" }, { children: "Save view" })) })))) : current.viewDef.view_id == ((_a = sharedView.viewDef) === null || _a === void 0 ? void 0 : _a.view_id) &&
                                sharedView.viewDef.user_id != userId ? (_jsx(Button, __assign({ variant: "contained", sx: { bgcolor: "#0059B2", width: "102px", height: "44px" } }, { children: _jsx(Typography, __assign({ variant: "button", "data-my-value": "Clone", color: "white", whiteSpace: "nowrap", onClick: handleModalClick }, { children: "Clone view" })) }))) : ((queryChange ||
                                columnChange ||
                                aggHeaderChange ||
                                visChange ||
                                deduplicateChange) && (_jsxs(Button, __assign({ variant: "contained", onClick: handleSaveModal, sx: { bgcolor: "#0059B2", width: "84px", height: "44px" } }, { children: ["Save as", _jsxs(Menu, __assign({ open: saveAsModal, anchorEl: saveAsAnchorEl, elevation: 10, anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }, transformOrigin: {
                                            vertical: "top",
                                            horizontal: "right",
                                        }, sx: { mt: "9px" } }, { children: [_jsx(MenuItem, __assign({ sx: {
                                                    py: "10px",
                                                    fontWeight: "400",
                                                    fontSize: "16px",
                                                    width: "242px",
                                                    color: "#595959",
                                                }, "data-my-value": "Overwrite", onClick: handleModalClick }, { children: "Update saved view" })), _jsx(MenuItem, __assign({ sx: {
                                                    py: "10px",
                                                    fontWeight: "400",
                                                    fontSize: "16px",
                                                    width: "242px",
                                                    color: "#595959",
                                                }, "data-my-value": "newView", onClick: handleModalClick }, { children: "Save as a new view" }))] }))] })))), (current === null || current === void 0 ? void 0 : current.viewDef) != null ? (_jsx(OptionBtn, { isSharedView: sharedView.viewDef != null &&
                                    ((_b = sharedView.viewDef) === null || _b === void 0 ? void 0 : _b.view_id) == ((_c = current === null || current === void 0 ? void 0 : current.viewDef) === null || _c === void 0 ? void 0 : _c.view_id) &&
                                    ((_d = sharedView.viewDef) === null || _d === void 0 ? void 0 : _d.user_id) != userId, handleOnClick: handleDropdownMenu })) : ((current === null || current === void 0 ? void 0 : current.source) != null && (_jsx(Button, __assign({ variant: "contained", sx: {
                                    width: "84px",
                                    height: "44px",
                                    ml: "9px",
                                }, color: "secondary", "data-my-value": "Export", onClick: handleModalClick }, { children: "Export" })))), _jsxs(Menu, __assign({ sx: { mt: "5px" }, elevation: 6, anchorEl: anchorEl, open: openMenu, anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right",
                                }, transformOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                }, onClose: handleMenuClose, MenuListProps: {
                                    "aria-labelledby": "options",
                                } }, { children: [menuValues.map(function (val) {
                                        return (_jsx(MenuItem, __assign({ sx: {
                                                py: "10px",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                                width: "242px",
                                                color: "#595959",
                                            }, "data-my-value": val, onClick: handleModalClick }, { children: val }), val));
                                    }), _jsx(MenuItem, __assign({ "data-my-value": "Delete", sx: {
                                            color: "#A50D0D",
                                            pr: "5rem",
                                            py: "10px",
                                            fontSize: "16px",
                                        }, onClick: handleModalClick }, { children: "Delete" }))] }))] }))] })), _jsx(Modal, __assign({ id: "modal-default", open: openModal, onBackdropClick: handleCloseModal, onClose: handleCloseModal, title: modalTitle, width: "sm", fullWidth: false }, { children: modal }))] })));
};
export default Titlebar;
