import { jsx as _jsx } from "react/jsx-runtime";
import Home from "./Home";
import LoginPage from "./LoginPage";
import { Navigate } from "react-router-dom";
import useStore from "../Store/store";
import { ErrorType } from "../Model/model";
var Auth = function () {
    var _a = useStore(), isAuth = _a.isAuth, error = _a.error;
    if (isAuth) {
        return error.type == ErrorType.ERROR ? (_jsx(Navigate, { to: "/error/" })) : (_jsx(Home, {}));
    }
    return _jsx(LoginPage, {});
};
export default Auth;
