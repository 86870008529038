var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, TextField, Typography } from "@mui/material";
import { Button } from "@inspera/component-library";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useStore from "../../../../Store/store";
import _ from "lodash";
var Absolute = function (_a) {
    var fromTime = _a.fromTime, setFromTime = _a.setFromTime, fromDate = _a.fromDate, setFromDate = _a.setFromDate, toTime = _a.toTime, setToTime = _a.setToTime, toDate = _a.toDate, setToDate = _a.setToDate, handleClose = _a.handleClose, handleReset = _a.handleReset, anchorEl = _a.anchorEl, setAppliedLabel = _a.setAppliedLabel, selectedColumn = _a.selectedColumn;
    var _b = useStore(), queryOptions = _b.queryOptions, setQueryOptions = _b.setQueryOptions, restoreState = _b.restoreState, setRestoreState = _b.setRestoreState;
    var _c = useState(false), error = _c[0], setError = _c[1];
    var _d = useState(""), errorMsg = _d[0], setErrorMsg = _d[1];
    var handleSave = function () {
        if (fromDate == null) {
            setError(true);
            setErrorMsg("Enter a 'From' Date");
            return;
        }
        if (fromDate > toDate) {
            setError(true);
            setErrorMsg("Invalid Range selected");
            return;
        }
        var newQueryOptions = _.cloneDeep(queryOptions);
        var fromDateCopy = fromDate.clone();
        var toDateCopy = toDate.clone();
        var fromDate1 = fromDateCopy
            .set("hour", fromTime.hour())
            .set("minute", fromTime.minute())
            .set("second", fromTime.second());
        var toDate1 = toDateCopy
            .set("hour", toTime.hour())
            .set("minute", toTime.minute())
            .set("second", toTime.second());
        //Adding utcOffset to get UTC time
        var finalToDate = toDate1.add(toTime.utcOffset(), "minute");
        var finalFromDate = fromDate1.add(fromTime.utcOffset(), "minute");
        newQueryOptions.date_range = {
            field_name: selectedColumn,
            gte: finalFromDate.toISOString(),
            lte: finalToDate.toISOString(),
        };
        if (restoreState)
            setRestoreState(false);
        setQueryOptions(newQueryOptions);
        setAppliedLabel("".concat(fromDate1.format("DD.MM.YYYY h:mm A"), " \u2192 ").concat(toDate1.format("DD.MM.YYYY h:mm A")));
        handleClose();
    };
    return (_jsxs(Box, __assign({ sx: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            margin: "20px 0",
        } }, { children: [_jsxs(Box, __assign({ marginBottom: "10px" }, { children: [_jsx(Typography, __assign({ sx: {
                            margin: "5px 0",
                            color: "#272727",
                            fontSize: "16px",
                            fontWeight: "400",
                        } }, { children: "From" })), _jsxs(Box, __assign({ sx: { display: "flex" } }, { children: [_jsx(Box, __assign({ marginRight: "10px" }, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { label: "Select Date", "aria-label": "Select Date", value: fromDate, onChange: function (val) { return setFromDate(val); }, renderInput: function (params) { return _jsx(TextField, __assign({}, params)); }, PopperProps: {
                                            anchorEl: anchorEl,
                                            placement: "auto-start",
                                        }, inputFormat: "DD-MM-YYYY" }) })) })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(TimePicker, { value: fromTime, onChange: function (newTime) { return setFromTime(newTime); }, label: "Select Time(UTC)", renderInput: function (params) { return _jsx(TextField, __assign({}, params)); }, PopperProps: {
                                        anchorEl: anchorEl,
                                        placement: "auto-start",
                                    } }) }))] }))] })), _jsxs(Box, { children: [_jsx(Typography, __assign({ sx: {
                            margin: "5px 0",
                            color: "#272727",
                            fontSize: "16px",
                            fontWeight: "400",
                        } }, { children: "To" })), _jsxs(Box, __assign({ sx: { display: "flex" } }, { children: [_jsx(Box, __assign({ marginRight: "10px" }, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: toDate, onChange: function (val) { return setToDate(val); }, renderInput: function (params) { return _jsx(TextField, __assign({}, params)); }, label: "Select Date", PopperProps: {
                                            anchorEl: anchorEl,
                                            placement: "auto-start",
                                        }, inputFormat: "DD-MM-YYYY" }) })) })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(TimePicker, { value: toTime, onChange: function (newTime) { return setToTime(newTime); }, label: "Select Time(UTC)", renderInput: function (params) { return _jsx(TextField, __assign({}, params)); }, PopperProps: {
                                        anchorEl: anchorEl,
                                        placement: "auto-start",
                                    } }) }))] }))] }), _jsx(Box, __assign({ minHeight: "30px", paddingTop: "10px" }, { children: error && (_jsx(Typography, __assign({ sx: {
                        margin: "5px 0",
                        color: "#d10000",
                        fontSize: "13px",
                        padding: "2px 8px",
                        borderRadius: "4px",
                        backgroundColor: "#edc0c0",
                        width: "fit-content",
                    } }, { children: errorMsg }))) })), _jsxs(Box, __assign({ sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    width: "175px",
                    marginTop: "20px",
                } }, { children: [_jsx(Button, __assign({ onClick: handleSave, variant: "contained", color: "primary" }, { children: "Apply" })), _jsx(Button, __assign({ onClick: function () { return handleReset(); }, variant: "contained", color: "secondary" }, { children: "Reset" }))] }))] })));
};
export default Absolute;
