var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@inspera/component-library";
import { Stack, Typography } from "@mui/material";
var Desc = function (_a) {
    var src = _a.src, alt = _a.alt, desc = _a.desc, url = _a.url, _b = _a.btnText, btnText = _b === void 0 ? "To Login" : _b, requestId = _a.requestId;
    return (_jsxs(Stack, __assign({ spacing: 0, alignItems: "center", justifyContent: "center" }, { children: [_jsx("img", { src: src, alt: alt, style: {
                    width: "75%",
                    maxHeight: "300px",
                } }), _jsxs(Typography, __assign({ mb: "2.5rem", px: "15%", variant: "h6", textAlign: "center" }, { children: [desc, " ", _jsx("br", {}), requestId ? "Request-id : ".concat(requestId) : ""] })), url && (_jsx(Button, __assign({ href: url, variant: "outlined", color: "info", size: "medium" }, { children: btnText })))] })));
};
export default Desc;
