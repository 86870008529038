var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from "lodash";
var setDataset = function (chartOption, dimensions, source, groupByColumns) {
    var temp = chartOption;
    delete temp.dataset;
    //make grouped_by columns as one single 'group' column as comma seperated values of corresponding columns
    // do this if more than 1 column are grouped
    var pieChart = chartOption.series.type == "pie";
    var newSource = [];
    if ((groupByColumns === null || groupByColumns === void 0 ? void 0 : groupByColumns.length) > 1 || pieChart) {
        newSource = _.cloneDeep(source);
        newSource.forEach(function (row) {
            var combinedValue = "";
            for (var _i = 0, _a = Object.entries(row); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                if (groupByColumns.includes(key)) {
                    combinedValue += value + ", ";
                    delete row[key];
                }
            }
            //removing extra comma and space at end
            row["group"] = combinedValue.slice(0, combinedValue.lastIndexOf(","));
        });
        temp.dataset = { dimension: dimensions, source: newSource };
    }
    else
        temp.dataset = { dimension: dimensions, source: source };
};
var addBarGraph = function (chartOption, x, y) {
    var temp = chartOption;
    temp.xAxis = { type: "category" };
    temp.yAxis = {};
    temp.x = x;
    temp.y = y;
    temp.series = {
        type: "bar",
        seriesLayoutBy: "row",
        emphasis: { focus: "series" },
    };
    temp.tooltip = {
        trigger: "axis",
        axisPointer: {
            type: "shadow",
        },
    };
};
var addPieGraph = function (chartOption, x, y) {
    var temp = chartOption;
    temp.x = x;
    temp.xAxis = { type: "category" };
    temp.yAxis = {};
    temp.y = y;
    temp.series = {
        type: "pie",
    };
    temp.tooltip = {
        trigger: "item",
    };
    temp.legend = {
        orient: "vertical",
        left: "left",
    };
};
var addLineGraph = function (chartOption, x, y) {
    var temp = chartOption;
    temp.xAxis = { type: "category" };
    temp.x = x;
    temp.y = y;
    temp.series = {
        type: "line",
        smooth: true,
    };
    temp.tooltip = {
        trigger: "axis",
        axisPointer: {
            type: "line",
        },
    };
    temp.yAxis = { type: "value" };
};
var resetVisualMap = function (chartOption) {
    var temp = _.cloneDeep(chartOption);
    if (temp.series.type != "pie")
        temp.color = "#0059b3";
    else
        delete temp.color;
    if (temp.visualMap)
        delete temp.visualMap;
    return temp;
};
var addVisualMap = function (chartOption, op, color, highlightColor, value, dimension) {
    var _a;
    var temp = _.cloneDeep(chartOption);
    if (temp.series.type != "pie")
        temp.color = color;
    else
        delete temp.color;
    if ((value === null || value === void 0 ? void 0 : value.length) == 0 || parseFloat(value) == 0) {
        delete temp.visualMap;
    }
    else if (value != null && value.length > 0) {
        delete temp.visualMap;
        temp.visualMap = {
            dimension: dimension,
            show: false,
            pieces: [
                { lt: 0, color: color },
                (_a = {},
                    _a[op] = parseFloat(value),
                    _a.color = highlightColor,
                    _a),
            ],
            outOfRange: {
                color: color,
            },
        };
    }
    return temp;
};
export var GetEChartOptions = function (option) {
    var series;
    if (option.series.type == "pie") {
        series = {
            type: option.series.type,
            radius: "50%",
            encode: {
                itemName: option.x,
                value: option.y,
            },
        };
    }
    else {
        series = __assign(__assign({}, option.series), { xAxisIndex: 0, yAxisIndex: 0, encode: {
                x: option.x,
                y: option.y,
            } });
    }
    var chartOption = {
        color: option.color,
        dataset: option.dataset,
        xAxis: option.xAxis,
        yAxis: option.yAxis,
        series: series,
        visualMap: option.visualMap,
        tooltip: option.tooltip,
        toolbox: {
            feature: {
                saveAsImage: {
                    type: "png",
                    name: "".concat(option.y),
                },
            },
        },
    };
    if (chartOption.visualMap == undefined)
        delete chartOption.visualMap;
    return chartOption;
};
export { setDataset, resetVisualMap, addVisualMap, addLineGraph, addPieGraph, addBarGraph, };
