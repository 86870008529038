var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Box, Grid, Snackbar, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import useStore from "../Store/store";
import { ErrorType, } from "../Model/model";
import { Dashboard } from "../Components/Dashboard/Dashboard";
import { SavedViews } from "../Components/SavedViews/SavedViews";
import { Button, Modal, Typography } from "@inspera/component-library";
import dayjs from "dayjs";
import { hashCode } from "../Utils/util";
import { DataViewDashboard } from "../Components/Dashboard/DataViewDashboard";
var Dataview = function () {
    var _a = useStore(), sources = _a.sources, error = _a.error, columns = _a.columns, columnCategories = _a.columnCategories, setCurrent = _a.setCurrent, setVisibleCategories = _a.setVisibleCategories, setVisibleColumns = _a.setVisibleColumns, virtualColumnsMetadata = _a.virtualColumnsMetadata, sharedView = _a.sharedView, setRestoreState = _a.setRestoreState, userId = _a.userId, current = _a.current;
    var hashUserId = hashCode(String(userId));
    var _b = useState(false), toastState = _b[0], setToast = _b[1];
    var _c = useState(false), isSavedView = _c[0], setIsSavedView = _c[1];
    var _d = useState(localStorage.getItem("options".concat(hashUserId)) != null), open = _d[0], setOpen = _d[1];
    var savedState = localStorage.getItem("options".concat(hashUserId));
    var sessionDateTime = "";
    if (savedState) {
        sessionDateTime = dayjs(JSON.parse(savedState).timestamp).format("D MMM, YYYY hh:mm A");
    }
    var handleModalConfirm = function () {
        if (savedState) {
            var options = JSON.parse(savedState);
            setRestoreState(true);
            setCurrent(options.current);
            handleClose();
        }
    };
    var handleClose = function () {
        setOpen(false);
    };
    useEffect(function () {
        if (error.type == ErrorType.WARNING)
            setToast(true);
    }, [error]);
    useEffect(function () {
        var visCol = 0;
        var visCat = 0;
        columns.forEach(function (col) {
            if (col.show)
                visCol++;
        });
        virtualColumnsMetadata.forEach(function (col) {
            if (col.show)
                visCol++;
        });
        columnCategories.forEach(function (cat) {
            if (cat.show)
                visCat++;
        });
        setVisibleColumns(visCol);
        setVisibleCategories(visCat);
    }, [columnCategories, columns, virtualColumnsMetadata]);
    useEffect(function () {
        if (sharedView.viewDef) {
            sources.forEach(function (source) {
                var _a;
                if (source.data_source_url_slug == ((_a = sharedView.viewDef) === null || _a === void 0 ? void 0 : _a.data_source)) {
                    setCurrent({
                        source: source,
                        viewDef: sharedView.viewDef,
                        viewOwn: false,
                    });
                }
            });
        }
    }, [sharedView]);
    return (_jsxs(Grid, __assign({ container: true, wrap: "nowrap", direction: "column", sx: {
            backgroundColor: "#F7F7F7",
        } }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, zIndex: 10 }, { children: _jsx(Navbar, { title: "Data discovery", hideBtn: true, hideMpName: false, isSavedView: isSavedView, setIsSavedView: setIsSavedView }) })), _jsx(SavedViews, { hidden: !isSavedView, setIsSavedView: setIsSavedView }), !(current === null || current === void 0 ? void 0 : current.dataView) && _jsx(Dashboard, { hidden: isSavedView }), (current === null || current === void 0 ? void 0 : current.dataView) && !isSavedView && _jsx(DataViewDashboard, {}), _jsx(Snackbar, __assign({ open: toastState, autoHideDuration: 6000, message: "Default snackbar color", onClose: function () { return setToast(false); } }, { children: _jsx(Alert, __assign({ onClose: function () { }, severity: "error" }, { children: error.message })) })), _jsx(Modal, __assign({ open: open, id: "modal-WithIcon", onBackdropClick: handleClose, onClose: handleClose, title: "Restore session", width: "sm", fullWidth: false }, { children: _jsxs(Stack, __assign({ spacing: 3 }, { children: [_jsxs(Box, __assign({ component: "p" }, { children: ["You have unsaved changes from last session on\u00A0", _jsx(Typography, __assign({ component: "span", sx: { fontWeight: "500" } }, { children: sessionDateTime })), ".", _jsx("br", {}), " Would you like to restore it?"] })), _jsxs(Stack, __assign({ direction: "row", spacing: 3 }, { children: [_jsx(Button, __assign({ color: "primary", variant: "contained", onClick: handleModalConfirm }, { children: "Restore" })), _jsx(Button, __assign({ color: "info", variant: "contained", onClick: function () {
                                        localStorage.removeItem("options".concat(hashUserId));
                                        handleClose();
                                    } }, { children: "Discard" }))] }))] })) }))] })));
};
export default Dataview;
