import { jsx as _jsx } from "react/jsx-runtime";
import DefaultError from "../Components/Error/DefaultError";
import EmptySource from "../Components/Error/EmptySource";
import PageNotFound from "../Components/Error/PageNotFound";
import Unauthenticated from "../Components/Error/Unauthenticated";
import Unauthorized from "../Components/Error/Unauthorized";
import { ErrorType, StatusCode } from "../Model/model";
import useStore from "../Store/store";
import { APP_URL, IA_LOGIN_URL } from "../Utils/constants";
var ErrorPage = function () {
    var _a = useStore(), error = _a.error, marketplaceId = _a.marketplaceId, sharedView = _a.sharedView, domain = _a.domain;
    var iaLoginPage = "";
    var ia_login_url = new URL(IA_LOGIN_URL);
    switch (String(error.code)) {
        case StatusCode.UAUTHENTICATED:
            if (marketplaceId) {
                if (domain) {
                    sharedView.id
                        ? (iaLoginPage =
                            domain == ia_login_url.hostname
                                ? "".concat(IA_LOGIN_URL, "?marketplaceId=").concat(marketplaceId, "&returnTo=").concat(APP_URL, "/share/view/").concat(sharedView.id)
                                : "https://".concat(domain, "/admin?returnTo=").concat(APP_URL, "/share/view/").concat(sharedView.id))
                        : (iaLoginPage =
                            domain == ia_login_url.hostname
                                ? "".concat(IA_LOGIN_URL, "?marketplaceId=").concat(marketplaceId, "&returnTo=").concat(APP_URL, "/auth")
                                : "https://".concat(domain, "/admin?returnTo=").concat(APP_URL, "/auth"));
                }
                else {
                    if (sharedView.id)
                        iaLoginPage = "".concat(IA_LOGIN_URL, "?marketplaceId=").concat(marketplaceId, "&returnTo=").concat(APP_URL, "/share/view/").concat(sharedView.id);
                    else
                        iaLoginPage = "".concat(IA_LOGIN_URL, "?marketplaceId=").concat(marketplaceId, "&returnTo=").concat(APP_URL, "/auth");
                }
                return _jsx(Unauthenticated, { url: iaLoginPage });
            }
            return _jsx(Unauthenticated, { url: "" });
        case StatusCode.UNAUTHORIZED:
            if (marketplaceId) {
                if (domain) {
                    iaLoginPage =
                        domain == ia_login_url.hostname
                            ? "".concat(IA_LOGIN_URL, "?marketplaceId=").concat(marketplaceId, "&returnTo=").concat(APP_URL, "/share/view/").concat(sharedView.id)
                            : "https://".concat(domain, "/admin?returnTo=").concat(APP_URL, "/share/view/").concat(sharedView.id);
                }
                else
                    iaLoginPage = "".concat(IA_LOGIN_URL, "?marketplaceId=").concat(marketplaceId, "&returnTo=").concat(APP_URL, "/share/view/").concat(sharedView.id);
                return _jsx(Unauthorized, { msg: error.message, url: iaLoginPage });
            }
            return _jsx(Unauthorized, { url: "", msg: "" });
        case StatusCode.PAGENOTFOUND:
            return _jsx(PageNotFound, { children: " " });
        case StatusCode.EMPTYSOURCE:
            return _jsx(EmptySource, {});
        case StatusCode.INTERNALSERVERERROR:
            return (_jsx(DefaultError, { error: {
                    message: "",
                    code: "500",
                    type: ErrorType.ERROR,
                    title: "Internal Server Error",
                } }));
        case StatusCode.ITEMBANKEMPTY:
            return _jsx(DefaultError, { error: error, insperaLogin: true });
        default:
            return _jsx(DefaultError, { error: error });
    }
};
export default ErrorPage;
