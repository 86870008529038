var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, } from "@inspera/component-library";
import { TableInfoPanel } from "../Utils/TableInfoPanel";
import { formatData, getAlignment } from "../Utils/utils";
import EmptyDataTable from "./EmptyDataTable";
import { styleHeaders } from "../../../../Utils/util";
export var DataTable = function (_a) {
    var headers = _a.headers, data = _a.data, domain = _a.domain, colVisible = _a.colVisible;
    if (data.rows.length != 0)
        return (_jsx(_Fragment, { children: colVisible ? (_jsx(TableInfoPanel, {})) : (_jsxs(Table, __assign({ stickyHeader: true, size: "small" }, { children: [_jsx(TableHead, { children: _jsx(TableRow, __assign({ sx: { height: "56px" } }, { children: headers.map(function (header) {
                                return header.show && (_jsx(TableCell, __assign({ sx: { whiteSpace: "nowrap" }, align: getAlignment(header.column_data_type) }, { children: _jsx(Tooltip, __assign({ title: header.column_description, placement: "top" }, { children: styleHeaders(header) })) }), header.column_name));
                            }) })) }), _jsx(TableBody, { children: data.rows.map(function (row, index) {
                            return (_jsx(TableRow, __assign({ sx: {
                                    overflow: "clip",
                                    height: "56px",
                                }, hover: true, role: "checkbox", tabIndex: -1 }, { children: headers.map(function (header) {
                                    return (header.show && (_jsx(TableCell, __assign({ component: "th", id: header.column_name, align: getAlignment(header.column_data_type), scope: "row", sx: {
                                            backgroundColor: "white",
                                            px: "16px",
                                            textOverflow: "ellipsis",
                                            maxHeight: "56px",
                                            overflow: "hidden",
                                        }, padding: "none" }, { children: formatData(row[header.column_name], header, row, domain) }), header.column_name)));
                                }) }), index));
                        }) })] }))) }));
    else
        return _jsx(EmptyDataTable, {});
};
