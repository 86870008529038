export var StatusCode;
(function (StatusCode) {
    StatusCode["EMPTYSOURCE"] = "0";
    StatusCode["UAUTHENTICATED"] = "401";
    StatusCode["UNAUTHORIZED"] = "403";
    StatusCode["PAGENOTFOUND"] = "404";
    StatusCode["ITEMBANKEMPTY"] = "420";
    StatusCode["UNPROCESSABLE"] = "422";
    StatusCode["INTERNALSERVERERROR"] = "500";
})(StatusCode || (StatusCode = {}));
export var ErrorType;
(function (ErrorType) {
    ErrorType["WARNING"] = "warning";
    ErrorType["UAUTHENTICATED"] = "unauth";
    ErrorType["ERROR"] = "error";
})(ErrorType || (ErrorType = {}));
export var ACTIONS = {
    APPLY: "apply",
    ERROR: "error",
    ERROR_MSG: "errorMsg",
    FILTER_COUNT: "filterCount",
    APPLIED_FILTER_COUNT: "appliedFilterCount",
    ROW_TYPE: "rowType",
    FILTER_ROWS: "filterRows",
    OPEN: "open",
    CHANGE_COLUMN: "changeColumn",
    TOOLTIP_OPEN: "tooltipOpen",
};
export var ModeType;
(function (ModeType) {
    ModeType["data"] = "DATA";
    ModeType["agg"] = "AGG";
    ModeType["vis"] = "VIS";
})(ModeType || (ModeType = {}));
