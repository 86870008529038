var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Divider } from "@inspera/component-library";
import { Stack, Typography } from "@mui/material";
import { Api } from "../../Api/api";
import { apiCore } from "../../Api/apiCore";
import { ErrorType } from "../../Model/model";
import useStore from "../../Store/store";
import { handleError } from "../../Utils/handleError";
var DeleteView = function (_a) {
    var view = _a.view, setModal = _a.setModal;
    var _b = useStore(), token = _b.token, setView = _b.setView, setError = _b.setError, current = _b.current, setCurrent = _b.setCurrent;
    var deleteHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiCore.deleteView(token, view === null || view === void 0 ? void 0 : view.view_id).catch(function (err) {
                        throw err;
                    })];
                case 1:
                    _a.sent();
                    setModal(false);
                    return [4 /*yield*/, Api.get("/view_definitions", token)
                            .then(function (views) {
                            var _a;
                            setView(views);
                            if (((_a = current === null || current === void 0 ? void 0 : current.viewDef) === null || _a === void 0 ? void 0 : _a.view_id) == (view === null || view === void 0 ? void 0 : view.view_id))
                                setCurrent({
                                    source: current === null || current === void 0 ? void 0 : current.source,
                                    viewDef: null,
                                    viewOwn: false,
                                });
                        })
                            .catch(function (err) {
                            handleError(setError, err, ErrorType.ERROR, "");
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Stack, __assign({ spacing: 3 }, { children: [_jsx(Divider, {}), _jsx(Typography, { children: "Are you sure you want to delete the view" }), _jsxs(Stack, __assign({ direction: "row", spacing: 3 }, { children: [_jsx(Button, __assign({ color: "error", variant: "contained", onClick: deleteHandler }, { children: "Delete" })), _jsx(Button, __assign({ color: "info", variant: "contained", onClick: function () { return setModal(false); } }, { children: "Cancel" }))] }))] })));
};
export default DeleteView;
