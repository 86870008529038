var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import PaginationComponent from "./PaginationComponent";
import RowPerPage from "./RowPerPage";
var Footer = function () {
    return (_jsxs(Box, __assign({ sx: {
            flexWrap: "nowrap",
            position: "sticky",
            left: "0px",
            display: "flex",
            width: "100%",
            padding: "20px 0",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "#F7F7F7",
        } }, { children: [_jsx(Box, __assign({ sx: {
                    visibility: "hidden",
                } }, { children: _jsx(RowPerPage, {}) })), _jsx(Box, __assign({ sx: {
                    alignItems: "center",
                    display: "flex",
                } }, { children: _jsx(PaginationComponent, {}) })), _jsx(Box, __assign({ sx: {
                    right: "24px",
                } }, { children: _jsx(RowPerPage, {}) }))] })));
};
export default Footer;
