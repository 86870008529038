var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { StatusCode, } from "../Model/model";
import { Api } from "./api";
var FileSaver = require("file-saver");
var tokenStatus = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var status;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.get("/health_check", token).catch(function (err) {
                    throw err;
                })];
            case 1:
                status = _a.sent();
                if (status) {
                    token && localStorage.setItem("ddt_token", token);
                    return [2 /*return*/, status];
                }
                return [2 /*return*/];
        }
    });
}); };
var getDataSource = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var dataSource;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.get("/sources", token).catch(function (err) {
                    throw err;
                })];
            case 1:
                dataSource = _a.sent();
                if (dataSource.length == 0)
                    throw {
                        code: StatusCode.EMPTYSOURCE,
                        message: "You dont any access to any source",
                        type: null,
                    };
                return [2 /*return*/, dataSource];
        }
    });
}); };
var getViews = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var views;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.get("/view_definitions", token).catch(function (err) {
                    throw err;
                })];
            case 1:
                views = _a.sent();
                return [2 /*return*/, views];
        }
    });
}); };
var getColumns = function (token, source) { return __awaiter(void 0, void 0, void 0, function () {
    var cols;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.get("/source/".concat(source, "/columns"), token).catch(function (err) {
                    throw err;
                })];
            case 1:
                cols = _a.sent();
                return [2 /*return*/, cols];
        }
    });
}); };
var getColumnCategories = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var columnCategories;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.get("/column_categories", token).catch(function (err) {
                    throw err;
                })];
            case 1:
                columnCategories = _a.sent();
                return [2 /*return*/, columnCategories];
        }
    });
}); };
var exportCsv = function (token, source, humanFriendly, body, aggs, fetchFromContent, deduplicate, aggHeader) {
    if (fetchFromContent === void 0) { fetchFromContent = false; }
    if (deduplicate === void 0) { deduplicate = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!aggs) return [3 /*break*/, 2];
                    return [4 /*yield*/, Api.post("/source/".concat(source, "?csv=true&csv_agg=").concat(aggs), token, body)
                            .then(function (res) {
                            var renameCsvExport = res;
                            if (aggHeader) {
                                for (var _i = 0, _a = Object.entries(aggHeader); _i < _a.length; _i++) {
                                    var _b = _a[_i], key = _b[0], value = _b[1];
                                    renameCsvExport = renameCsvExport.replace(key, value);
                                }
                            }
                            FileSaver.saveAs(new Blob([renameCsvExport], { type: "text/csv" }), "export_agg.csv");
                        })
                            .catch(function (err) {
                            throw err;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2: return [4 /*yield*/, Api.post("/source/".concat(source, "?csv=true&page=1&csv_friendly_names=").concat(humanFriendly, "&fetch_latest_values_from_content=").concat(fetchFromContent, "&deduplicate=").concat(deduplicate), token, body)
                        .then(function (res) {
                        FileSaver.saveAs(new Blob([res], { type: "text/csv" }), "export.csv");
                    })
                        .catch(function (err) {
                        throw err;
                    })];
                case 3: return [2 /*return*/, _a.sent()];
            }
        });
    });
};
var exportJson = function (token, source, page, body, fetchFromContent, deduplicate) {
    if (fetchFromContent === void 0) { fetchFromContent = false; }
    if (deduplicate === void 0) { deduplicate = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Api.post("/source/".concat(source, "?csv=false&page=").concat(page, "&csv_friendly_names=true&fetch_latest_values_from_content=").concat(fetchFromContent, "&deduplicate=").concat(deduplicate), token, body).catch(function (err) {
                        throw err;
                    })];
                case 1:
                    data = _a.sent();
                    FileSaver.saveAs(new Blob([JSON.stringify(data.rows)], { type: "application/json" }), "export.json");
                    return [2 /*return*/];
            }
        });
    });
};
var updateView = function (token, view_id, body) { return __awaiter(void 0, void 0, void 0, function () {
    var updateView;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.patch("/view_definitions/".concat(view_id, "/update"), token, body).catch(function (err) {
                    throw err;
                })];
            case 1:
                updateView = _a.sent();
                return [2 /*return*/, updateView];
        }
    });
}); };
var cloneView = function (token, view_id, body) { return __awaiter(void 0, void 0, void 0, function () {
    var view;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.post("/view_definitions/".concat(view_id, "/clone"), token, body).catch(function (err) {
                    throw err;
                })];
            case 1:
                view = _a.sent();
                return [2 /*return*/, view];
        }
    });
}); };
var deleteView = function (token, view_id) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.delete("/view_definitions/".concat(view_id), token).catch(function (err) {
                    throw err;
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
var fetchData = function (token, source, page, body, fetchFromContent, deDuplicate) {
    if (fetchFromContent === void 0) { fetchFromContent = false; }
    if (deDuplicate === void 0) { deDuplicate = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Api.post("/source/".concat(source, "?csv=false&page=").concat(page, "&csv_friendly_names=true&fetch_latest_values_from_content=").concat(fetchFromContent, "&deduplicate=").concat(deDuplicate), token, body).catch(function (err) {
                        throw err;
                    })];
                case 1:
                    data = _a.sent();
                    return [2 /*return*/, data];
            }
        });
    });
};
var fetchView = function (token, id) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.get("/view_definitions/".concat(id), token).catch(function (err) {
                    throw err;
                })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, data];
        }
    });
}); };
var saveView = function (token, body) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.post("/view_definitions", token, body).catch(function (err) {
                    throw err;
                })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var getPresets = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.get("/presets", token).catch(function (err) {
                    throw err;
                })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//Get the list of dataviews available for the current user
var getDataViews = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Api.get("/dataviews", token).catch(function (err) {
                    throw err;
                })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
//Get data of a particular dataview
var fetchDataView = function (token, itemBankId, body, interactions) {
    if (interactions === void 0) { interactions = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var queryParams, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryParams = "?interactions=".concat(interactions);
                    if (itemBankId)
                        queryParams += "&item_bank_id=".concat(itemBankId);
                    return [4 /*yield*/, Api.post("/dataviews/latest_content".concat(queryParams), token, body).catch(function (err) {
                            throw err;
                        })];
                case 1:
                    data = _a.sent();
                    return [2 /*return*/, data];
            }
        });
    });
};
var exportDataView = function (token, humanFriendly, dataViewQueryOptions, csv) { return __awaiter(void 0, void 0, void 0, function () {
    var queryParams, data, queryParams, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!dataViewQueryOptions)
                    return [2 /*return*/];
                if (!csv) return [3 /*break*/, 2];
                queryParams = "?interactions=".concat(dataViewQueryOptions.showInteractions);
                queryParams += "&item_bank_id=".concat(dataViewQueryOptions.itemBankId, "&csv=true&csv_friendly_names=").concat(humanFriendly);
                return [4 /*yield*/, Api.post("/dataviews/latest_content".concat(queryParams), token, dataViewQueryOptions.body).catch(function (err) {
                        throw err;
                    })];
            case 1:
                data = _a.sent();
                FileSaver.saveAs(new Blob([data], { type: "text/csv" }), "dataview.csv");
                return [3 /*break*/, 4];
            case 2:
                queryParams = "?interactions=".concat(dataViewQueryOptions.showInteractions);
                queryParams += "&item_bank_id=".concat(dataViewQueryOptions.itemBankId);
                return [4 /*yield*/, Api.post("/dataviews/latest_content".concat(queryParams), token, dataViewQueryOptions.body).catch(function (err) {
                        throw err;
                    })];
            case 3:
                data = _a.sent();
                FileSaver.saveAs(new Blob([JSON.stringify(data.rows)], { type: "application/json" }), "dataview.json");
                _a.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}); };
export var apiCore = {
    tokenStatus: tokenStatus,
    getDataSource: getDataSource,
    getViews: getViews,
    getColumns: getColumns,
    exportCsv: exportCsv,
    exportJson: exportJson,
    updateView: updateView,
    fetchData: fetchData,
    cloneView: cloneView,
    deleteView: deleteView,
    fetchView: fetchView,
    saveView: saveView,
    getColumnCategories: getColumnCategories,
    getPresets: getPresets,
    getDataViews: getDataViews,
    fetchDataView: fetchDataView,
    exportDataView: exportDataView,
};
