var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Icon, IconButton } from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
var CollapseBtn = function (_a) {
    var collapse = _a.collapse, setStatus = _a.setStatus;
    return (_jsx(IconButton, __assign({ onClick: function () { return setStatus(!collapse); }, sx: {
            backgroundColor: "white",
            borderRadius: 32,
            position: "absolute",
            height: "2rem",
            width: "2rem",
            border: "1px solid #ededed",
            left: collapse ? "0rem" : "calc(16rem - 10px)",
            top: "418px",
            "&:hover": {
                backgroundColor: "#ededed",
            },
            transition: "left 0.2s",
            zIndex: "1000",
        }, "aria-label": collapse ? "Expand sources" : "Collapse sources" }, { children: _jsx(Icon, __assign({ sx: {
                transform: collapse ? "rotate(180deg)" : "rotate(0deg)",
            } }, { children: _jsx(ChevronLeftRoundedIcon, { height: "32px" }) })) })));
};
export default CollapseBtn;
