var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, Popper, ClickAwayListener, Snackbar, Alert, } from "@mui/material";
import FocusTrap from "@mui/base/TrapFocus";
import calendar from "../../../../Assets/images/calendar.svg";
import { useEffect, useState } from "react";
import Absolute from "./Absolute";
import Relative from "./Relative";
import { CloseButton, MenuItem, Select, Tooltip, } from "@inspera/component-library";
import useStore from "../../../../Store/store";
import dayjs from "dayjs";
import { hashCode } from "../../../../Utils/util";
var TimeMenu = function () {
    var _a = useStore(), queryOptions = _a.queryOptions, setQueryOptions = _a.setQueryOptions, current = _a.current, visibleColumns = _a.visibleColumns, restoreState = _a.restoreState, userId = _a.userId, columns = _a.columns;
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var _c = useState(false), disable = _c[0], setDisable = _c[1];
    //For Absolute TimeDate component
    var _d = useState(null), fromDate = _d[0], setFromDate = _d[1];
    var _e = useState(dayjs()), fromTime = _e[0], setFromTime = _e[1];
    var _f = useState(dayjs()), toDate = _f[0], setToDate = _f[1];
    var _g = useState(dayjs()), toTime = _g[0], setToTime = _g[1];
    //For Relative component
    var _h = useState("d"), dmy = _h[0], setDmy = _h[1];
    var _j = useState(""), lastNum = _j[0], setLastNum = _j[1];
    //Selecting absolute or relative for date-time
    var _k = useState("abs"), timeMenu = _k[0], setTimeMenu = _k[1];
    //Label when Relative or Absolute Date range is applied.
    var _l = useState(""), appliedLabel = _l[0], setAppliedLabel = _l[1];
    //Which column to apply dateRange on
    var _m = useState("row_timestamp"), selectedColumn = _m[0], setSelectedColumn = _m[1];
    //TODO : refactor useEffects for views/presets based on selected column in daterange
    //Relative selected option, to display on the component
    var commonlyUsed = {
        Today: "now-1d/d",
        "Last 24 hours": "now-24h",
        "This week": "now-1w/w",
        "Last 7 days": "now-7d",
        "This month": "now-1M/M",
        "Last 30 days": "now-30d",
    };
    var menuSelectStyle = {
        fontSize: "16px",
        fontWeight: "500",
        textDecoration: "underline",
        textDecorationColor: "#004080",
        textDecorationThickness: "3px",
        textUnderlineOffset: "9px",
        cursor: "pointer",
    };
    useEffect(function () {
        if ((current === null || current === void 0 ? void 0 : current.viewDef) != null || restoreState || (current === null || current === void 0 ? void 0 : current.preset))
            return;
        //When only source is changed, reset the component
        setFromDate(null);
        setFromTime(dayjs());
        setToDate(dayjs());
        setToTime(dayjs());
        setDmy("d");
        setTimeMenu("abs");
        setLastNum("");
        setAppliedLabel("");
        setSelectedColumn("row_timestamp");
    }, [current === null || current === void 0 ? void 0 : current.source]);
    useEffect(function () {
        var _a;
        var options;
        var hashUserId = hashCode(String(userId));
        var savedState = localStorage.getItem("options".concat(hashUserId));
        if (restoreState && savedState) {
            options = JSON.parse(savedState).parameters;
        }
        else {
            options = (_a = current === null || current === void 0 ? void 0 : current.viewDef) === null || _a === void 0 ? void 0 : _a.parameters;
        }
        if (!options ||
            options.body.date_range == null ||
            Object.keys(options.body.date_range).length == 0) {
            setFromDate(null);
            setFromTime(dayjs());
            setToDate(dayjs());
            setToTime(dayjs());
            setDmy("d");
            setTimeMenu("abs");
            setLastNum("");
            setAppliedLabel("");
            setSelectedColumn("row_timestamp");
            return;
        }
        //Check if all the date_range properties are null
        var rangeNull = true;
        Object.values(options.body.date_range).forEach(function (range) {
            if (range) {
                // a range is applied
                rangeNull = false;
            }
        });
        if (rangeNull) {
            setFromDate(null);
            setFromTime(dayjs());
            setToDate(dayjs());
            setToTime(dayjs());
            setDmy("d");
            setTimeMenu("abs");
            setLastNum("");
            setAppliedLabel("");
            setSelectedColumn("row_timestamp");
            return;
        }
        var dateObj = options.body.date_range;
        //set selected column from view defintion if field_name is present else set it to "row_timestamp"
        dateObj.field_name
            ? setSelectedColumn(dateObj.field_name)
            : setSelectedColumn("row_timestamp");
        //stores if the view is absolute date ranged or not
        var viewRangeAbs = false;
        //Check if the range is relative or aboslute
        //Note: Only Absolute range will have lte and gte value set in them
        //      Relative range will have only 1 value set
        if (dateObj.gte != null && dateObj.lte != null) {
            viewRangeAbs = true;
        }
        if (viewRangeAbs) {
            //Absolute will have only lte & gte key
            setLastNum("");
            setDmy("d");
            var fromDateObj = dayjs(dateObj.gte);
            setFromDate(fromDateObj.subtract(fromDateObj.utcOffset(), "minute"));
            setFromTime(fromDateObj.subtract(fromDateObj.utcOffset(), "minute"));
            var toDateObj = dayjs(dateObj.lte);
            setToDate(toDateObj.subtract(toDateObj.utcOffset(), "minute"));
            setToTime(toDateObj.subtract(toDateObj.utcOffset(), "minute"));
            setTimeMenu("abs");
            setAppliedLabel("".concat(fromDateObj
                .subtract(fromDateObj.utcOffset(), "minute")
                .format("DD.MM.YYYY h:mm A"), " \u2192 ").concat(toDateObj
                .subtract(toDateObj.utcOffset(), "minute")
                .format("DD.MM.YYYY h:mm A")));
        }
        else {
            setFromDate(null);
            setFromTime(dayjs());
            setToDate(dayjs());
            setToTime(dayjs());
            //Relative range will only have gte key
            setTimeMenu("rel");
            var relDate_1 = dateObj.gte;
            var commonlyUsedSelected_1 = false;
            Object.keys(commonlyUsed).map(function (relOption) {
                if (commonlyUsed[relOption] == relDate_1) {
                    commonlyUsedSelected_1 = true;
                    setAppliedLabel(relOption);
                }
            });
            if (!commonlyUsedSelected_1) {
                //Quick select was used
                //midPart in 'now-10d/w' is '10d' which gives last num(10) and selected options(d)
                var midPart = relDate_1 === null || relDate_1 === void 0 ? void 0 : relDate_1.split("-")[1].split("/")[0];
                //Extract number from this part
                var num = midPart.replace(/[^0-9]/g, "");
                //Remove the number to get the range dropdown (d or M or Y)
                var dmyView = midPart.replace(/[0-9]/g, "");
                setLastNum(num);
                setDmy(dmyView);
                if (dmyView == "d") {
                    setAppliedLabel("Last ".concat(num, " day").concat(Number(num) > 1 ? "s" : ""));
                }
                else if (dmyView == "M") {
                    setAppliedLabel("Last ".concat(num, " month").concat(Number(num) > 1 ? "s" : ""));
                }
                else if (dmyView == "y") {
                    setAppliedLabel("Last ".concat(num, " year").concat(Number(num) > 1 ? "s" : ""));
                }
                else
                    setAppliedLabel("");
            }
        }
    }, [current === null || current === void 0 ? void 0 : current.viewDef]);
    useEffect(function () {
        if (current === null || current === void 0 ? void 0 : current.preset) {
            var presetData = current.preset;
            if (!presetData.parameters.body.date_range ||
                Object.keys(presetData.parameters.body.date_range).length == 0) {
                setFromDate(null);
                setFromTime(dayjs());
                setToDate(dayjs());
                setToTime(dayjs());
                setDmy("d");
                setTimeMenu("abs");
                setLastNum("");
                setAppliedLabel("");
                setSelectedColumn("row_timestamp");
                return;
            }
            //Check if all the date_range properties are null
            var rangeNull_1 = true;
            Object.values(presetData.parameters.body.date_range).forEach(function (range) {
                if (range) {
                    // a range is applied
                    rangeNull_1 = false;
                }
            });
            if (rangeNull_1) {
                setFromDate(null);
                setFromTime(dayjs());
                setToDate(dayjs());
                setToTime(dayjs());
                setDmy("d");
                setTimeMenu("abs");
                setLastNum("");
                setAppliedLabel("");
                setSelectedColumn("row_timestamp");
                return;
            }
            var dateObj = presetData.parameters.body.date_range;
            //set selected column from view defintion if field_name is present else set it to "row_timestamp"
            dateObj.field_name
                ? setSelectedColumn(dateObj.field_name)
                : setSelectedColumn("row_timestamp");
            //stores if the preset is absolute date ranged or not
            var viewRangeAbs = false;
            //Check if the range is relative or aboslute
            //Note: Only Absolute range will have lte and gte value set in them
            //      Relative range will have only 1 value set
            if (dateObj.gte != null && dateObj.lte != null) {
                viewRangeAbs = true;
            }
            if (viewRangeAbs) {
                //Absolute will have only lte & gte key
                setLastNum("");
                setDmy("d");
                var fromDateObj = dayjs(dateObj.gte);
                setFromDate(fromDateObj.subtract(fromDateObj.utcOffset(), "minute"));
                setFromTime(fromDateObj.subtract(fromDateObj.utcOffset(), "minute"));
                var toDateObj = dayjs(dateObj.lte);
                setToDate(toDateObj.subtract(toDateObj.utcOffset(), "minute"));
                setToTime(toDateObj.subtract(toDateObj.utcOffset(), "minute"));
                setTimeMenu("abs");
                setAppliedLabel("".concat(fromDateObj
                    .subtract(fromDateObj.utcOffset(), "minute")
                    .format("DD.MM.YYYY h:mm A"), " \u2192 ").concat(toDateObj
                    .subtract(toDateObj.utcOffset(), "minute")
                    .format("DD.MM.YYYY h:mm A")));
            }
            else {
                setFromDate(null);
                setFromTime(dayjs());
                setToDate(dayjs());
                setToTime(dayjs());
                //Relative range will only have gte key
                setTimeMenu("rel");
                var relDate_2 = dateObj.gte;
                var commonlyUsedSelected_2 = false;
                Object.keys(commonlyUsed).map(function (relOption) {
                    if (commonlyUsed[relOption] == relDate_2) {
                        commonlyUsedSelected_2 = true;
                        setAppliedLabel(relOption);
                    }
                });
                if (!commonlyUsedSelected_2) {
                    //Quick select was used
                    //midPart in 'now-10d/w' is '10d' which gives last num(10) and selected options(d)
                    var midPart = relDate_2 === null || relDate_2 === void 0 ? void 0 : relDate_2.split("-")[1].split("/")[0];
                    //Extract number from this part
                    var num = midPart.replace(/[^0-9]/g, "");
                    //Remove the number to get the range dropdown (d or M or Y)
                    var dmyView = midPart.replace(/[0-9]/g, "");
                    setLastNum(num);
                    setDmy(dmyView);
                    if (dmyView == "d") {
                        setAppliedLabel("Last ".concat(num, " day").concat(Number(num) > 1 ? "s" : ""));
                    }
                    else if (dmyView == "M") {
                        setAppliedLabel("Last ".concat(num, " month").concat(Number(num) > 1 ? "s" : ""));
                    }
                    else if (dmyView == "y") {
                        setAppliedLabel("Last ".concat(num, " year").concat(Number(num) > 1 ? "s" : ""));
                    }
                    else
                        setAppliedLabel("");
                }
            }
        }
    }, [current === null || current === void 0 ? void 0 : current.preset]);
    var handleClick = function (event) {
        if (visibleColumns == 0) {
            setDisable(true);
            return;
        }
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    var open = Boolean(anchorEl);
    var id = open ? "simple-popper" : undefined;
    var handleClose = function () { return setAnchorEl(null); };
    var handleReset = function () {
        setFromDate(null);
        setFromTime(dayjs());
        setToDate(dayjs());
        setToTime(dayjs());
        setDmy("d");
        setTimeMenu("abs");
        setLastNum("");
        setAppliedLabel("");
        setAnchorEl(null);
        var newQueryOptions = JSON.parse(JSON.stringify(queryOptions));
        newQueryOptions.date_range = null;
        setQueryOptions(newQueryOptions);
    };
    //get columns with date datatype
    var dateTimeColumns = function () {
        var options = [];
        columns.forEach(function (col) {
            if (col.column_data_type === "date") {
                options.push(_jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: col.column_name }, { children: col.human_friendly_column_name }), col.column_name));
            }
        });
        return options;
    };
    return (_jsx(ClickAwayListener, __assign({ onClickAway: handleClose, mouseEvent: "onMouseUp" }, { children: _jsxs(Box, __assign({ sx: { padding: "17px  17px 0px 17px", width: "227px" } }, { children: [_jsx(Typography, __assign({ sx: {
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#595959",
                        marginBottom: "6px",
                    } }, { children: "Date range" })), _jsx(Tooltip, __assign({ arrow: true, title: appliedLabel.includes(":") ? appliedLabel : "" }, { children: _jsxs(Typography, __assign({ onClick: handleClick, variant: "body2", role: "button", onKeyDown: function (event) {
                            event.key == "Enter" && handleClick(event);
                        }, tabIndex: 0, sx: {
                            margin: "0px",
                            display: "flex",
                            fontSize: "14px",
                            justifyContent: "space-between",
                            padding: "12px 17px",
                            boxSizing: "border-box",
                            cursor: "pointer",
                            height: "44px",
                            alignItems: "center",
                            border: "1px solid #949494",
                            borderRadius: "3px",
                            fontWeight: "400",
                            color: "#595959",
                            "&:hover": {
                                boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(0deg 0% 74%) 0 0 0 3px",
                            },
                            "&:active": {
                                boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(210deg 100% 35%) 0 0 0 2px, hsl(210deg 100% 85%) 0 0 0 4px",
                            },
                            "&:focus-visible": {
                                boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(210deg 100% 35%) 0 0 0 2px, hsl(210deg 100% 85%) 0 0 0 4px",
                                outline: "none",
                            },
                        } }, { children: [appliedLabel == "" ? ("Select Date Range") : (_jsx(Typography, __assign({ width: 177, marginBottom: 0, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", component: "span", color: "#595959", fontWeight: "400", fontSize: "14px" }, { children: appliedLabel }))), _jsx("img", { src: calendar, "aria-hidden": "true", alt: "calendar icon" }), " "] })) })), _jsx(Popper, __assign({ id: id, open: open, anchorEl: anchorEl, placement: "auto-start", sx: { zIndex: "1000" }, modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 20],
                            },
                        },
                    ] }, { children: _jsx(FocusTrap, __assign({ open: open }, { children: _jsxs(Box, __assign({ sx: {
                                display: "flex",
                                flexDirection: "column",
                                border: "1px solid #EDEDED",
                                background: "#FFFFFF",
                                padding: "24px",
                                width: "350px",
                            }, tabIndex: -1 }, { children: [_jsxs(Box, __assign({ sx: {
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "0 2px",
                                    } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: {
                                                color: "#272727",
                                                marginBottom: 0,
                                                alignSelf: "center",
                                                fontSize: "18px",
                                                fontWeight: "500",
                                            } }, { children: "Time" })), _jsx(CloseButton, { size: "small", "aria-label": "close", onClick: handleClose })] })), dateTimeColumns().length > 1 && (_jsxs(Box, __assign({ sx: {
                                        display: "flex",
                                        alignItems: "center",
                                        margin: "15px 0 10px 0",
                                    } }, { children: [_jsx(Typography, __assign({ sx: {
                                                color: "#272727",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                marginRight: "10px",
                                            } }, { children: "Column" })), _jsx(Select, __assign({ value: selectedColumn, onChange: function (e) {
                                                return setSelectedColumn(e.target.value);
                                            }, sx: {
                                                margin: "0 5px",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                            } }, { children: dateTimeColumns() }))] }))), _jsxs(Box, __assign({ sx: {
                                        display: "flex",
                                        margin: "28px 0",
                                        color: "#272727",
                                        marginLeft: "13px",
                                    } }, { children: [timeMenu == "abs" ? (_jsx(Typography, __assign({ sx: menuSelectStyle }, { children: "Absolute " }))) : (_jsx(Typography, __assign({ tabIndex: 0, onKeyDown: function (event) {
                                                event.key == "Enter" && setTimeMenu("abs");
                                            }, onClick: function () { return setTimeMenu("abs"); }, sx: {
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                cursor: "pointer",
                                            } }, { children: "Absolute" }))), timeMenu == "rel" ? (_jsxs(Typography, __assign({ marginLeft: "20px", sx: menuSelectStyle }, { children: ["Relative", " "] }))) : (_jsx(Typography, __assign({ onClick: function () { return setTimeMenu("rel"); }, tabIndex: 0, onKeyDown: function (event) {
                                                event.key == "Enter" && setTimeMenu("rel");
                                            }, marginLeft: "20px", sx: {
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                cursor: "pointer",
                                            } }, { children: "Relative" })))] })), _jsxs(Box, { children: [timeMenu == "abs" && (_jsx(Absolute, { fromTime: fromTime, setFromTime: setFromTime, toTime: toTime, setToTime: setToTime, fromDate: fromDate, setFromDate: setFromDate, toDate: toDate, setToDate: setToDate, handleClose: handleClose, handleReset: handleReset, anchorEl: anchorEl, setAppliedLabel: setAppliedLabel, selectedColumn: selectedColumn })), timeMenu == "rel" && (_jsx(Relative, { dmy: dmy, setDmy: setDmy, lastNum: lastNum, setLastNum: setLastNum, handleClose: handleClose, handleReset: handleReset, commonlyUsed: commonlyUsed, setAppliedLabel: setAppliedLabel, selectedColumn: selectedColumn }))] })] })) })) })), _jsx(Snackbar, __assign({ open: disable, autoHideDuration: 6000, onClose: function () { return setDisable(false); } }, { children: _jsx(Alert, __assign({ variant: "filled", onClose: function () { return setDisable(false); }, severity: "error" }, { children: "Select some columns before applying date range" })) }))] })) })));
};
export default TimeMenu;
