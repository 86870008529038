var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Input, Checkbox } from "@inspera/component-library";
import { Typography, Box, Stack } from "@mui/material";
import { useState } from "react";
import { apiCore } from "../../Api/apiCore";
import useStore from "../../Store/store";
import TextField from "@mui/material/TextField";
import { handleError } from "../../Utils/handleError";
import { ErrorType } from "../../Model/model";
import { VISUALISATION_VERSION } from "../../Utils/constants";
import _ from "lodash";
import FileSaver from "file-saver";
var Export = function (_a) {
    var close = _a.close;
    var _b = useStore(), token = _b.token, data = _b.data, queryOptions = _b.queryOptions, current = _b.current, columns = _b.columns, fetchVirtualColumns = _b.fetchVirtualColumns, deduplicate = _b.deduplicate, marketplaceId = _b.marketplaceId, chart = _b.chart, columnCategories = _b.columnCategories, aggHeaders = _b.aggHeaders, mode = _b.mode, virtualColumnsMetadata = _b.virtualColumnsMetadata, dataViewData = _b.dataViewData, dataViewQueryOptions = _b.dataViewQueryOptions;
    var _c = useState(false), error = _c[0], setError = _c[1];
    var _d = useState(""), errorMsg = _d[0], setErrorMsg = _d[1];
    var _e = useState(true), presetInputError = _e[0], setPresetInputError = _e[1];
    var _f = useState(""), presetInputErrorType = _f[0], setPresetInputErrorType = _f[1];
    var _g = useState("CSV"), exportType = _g[0], setExportType = _g[1];
    var _h = useState(true), humanFriendly = _h[0], setHumanFriendly = _h[1];
    var _j = useState(String(data === null || data === void 0 ? void 0 : data.metadata.total_rows)), rows = _j[0], setRows = _j[1];
    var _k = useState(""), presetExportName = _k[0], setPresetExportName = _k[1];
    var _l = useState(""), presetExportDescription = _l[0], setPresetExportDescription = _l[1];
    var _m = useState(""), presetExportTitle = _m[0], setPresetExportTitle = _m[1];
    var max = String((current === null || current === void 0 ? void 0 : current.dataView)
        ? dataViewData === null || dataViewData === void 0 ? void 0 : dataViewData.metadata.total_rows
        : data === null || data === void 0 ? void 0 : data.metadata.total_rows);
    var menuSelectStyle = {
        fontSize: "16px",
        fontWeight: "500",
        textDecoration: "underline",
        textDecorationColor: "#004080",
        textDecorationThickness: "3px",
        textUnderlineOffset: "9px",
        cursor: "pointer",
    };
    var menuNotSelectedStyle = {
        fontSize: "16px",
        fontWeight: "400",
        cursor: "pointer",
    };
    var handleInput = function (e) {
        setError(false);
        setErrorMsg("");
        var val = e.target.value;
        if (isNaN(Number(val)) || Number(val) <= 0) {
            setError(true);
            setErrorMsg("Enter a valid number");
        }
        else if (Number(val) > Number(max)) {
            setError(true);
            setErrorMsg("Enter rows less than ".concat(max, " rows "));
        }
        else if (Number(val) > 10000) {
            setError(true);
            setErrorMsg("Enter rows less than 10,000");
        }
        setRows(val);
    };
    var handleChange = function (e) {
        setHumanFriendly(e === null || e === void 0 ? void 0 : e.target.checked);
    };
    var presetInputHandler = function (e, inputType) {
        var input = e.target.value;
        setPresetInputErrorType("");
        setPresetInputError(false);
        if (inputType == "name") {
            setPresetExportName(input);
            if (input.trim().length == 0) {
                setPresetInputErrorType("name");
                setPresetInputError(true);
            }
            if (presetExportTitle.trim().length == 0 ||
                presetExportDescription.trim().length == 0)
                setPresetInputError(true);
        }
        else if (inputType == "title") {
            setPresetExportTitle(input);
            if (input.trim().length == 0) {
                setPresetInputErrorType("title");
                setPresetInputError(true);
            }
            if (presetExportName.trim().length == 0 ||
                presetExportDescription.trim().length == 0)
                setPresetInputError(true);
        }
        else if (inputType == "description") {
            setPresetExportDescription(input);
            if (input.trim().length == 0) {
                setPresetInputError(true);
                setPresetInputErrorType("description");
            }
            if (presetExportName.trim().length == 0 ||
                presetExportTitle.trim().length == 0)
                setPresetInputError(true);
        }
        //set error for input in preset if title/name is empty
    };
    var handleExport = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newDataViewQueryOptions, newQueryOptions, visibleCol_1, visibleColCat_1, chartOptions, presetExport;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    if (!((current === null || current === void 0 ? void 0 : current.dataView) && dataViewQueryOptions)) return [3 /*break*/, 1];
                    newDataViewQueryOptions = _.cloneDeep(dataViewQueryOptions);
                    //NOTE : Currently api exports all the available rows, so size doesn't matter now, refactor if api is changed later
                    newDataViewQueryOptions.body.size = 10;
                    apiCore.exportDataView(token, humanFriendly, newDataViewQueryOptions, exportType == "CSV");
                    return [3 /*break*/, 6];
                case 1:
                    newQueryOptions = _.cloneDeep(queryOptions);
                    if (exportType != "PRESET")
                        newQueryOptions.size = Number(rows);
                    visibleCol_1 = [];
                    columns.forEach(function (col) {
                        if (col.show)
                            visibleCol_1.push(col.column_name);
                    });
                    newQueryOptions.columns = visibleCol_1;
                    if (!(exportType == "CSV")) return [3 /*break*/, 3];
                    return [4 /*yield*/, apiCore
                            .exportCsv(token, (_a = current === null || current === void 0 ? void 0 : current.source) === null || _a === void 0 ? void 0 : _a.data_source_url_slug, humanFriendly, newQueryOptions, false, fetchVirtualColumns, deduplicate)
                            .catch(function (err) {
                            handleError(setError, err, ErrorType.ERROR, "");
                        })];
                case 2:
                    _f.sent();
                    return [3 /*break*/, 6];
                case 3:
                    if (!(exportType == "JSON")) return [3 /*break*/, 5];
                    return [4 /*yield*/, apiCore
                            .exportJson(token, (_b = current === null || current === void 0 ? void 0 : current.source) === null || _b === void 0 ? void 0 : _b.data_source_url_slug, 1, newQueryOptions, fetchVirtualColumns, deduplicate)
                            .catch(function (err) {
                            handleError(setError, err, ErrorType.ERROR, "");
                        })];
                case 4:
                    _f.sent();
                    return [3 /*break*/, 6];
                case 5:
                    if (exportType == "PRESET") {
                        visibleColCat_1 = [];
                        columnCategories.forEach(function (cat) {
                            if (cat.show)
                                visibleColCat_1.push(cat.categoryName);
                        });
                        chartOptions = _.cloneDeep(chart);
                        (_c = chartOptions.dataset) === null || _c === void 0 ? true : delete _c.source;
                        presetExport = {
                            name: presetExportName.trim(),
                            title: presetExportTitle.trim(),
                            description: presetExportDescription.trim(),
                            marketplace_id: marketplaceId,
                            allowed_roles: [],
                            parameters: {
                                body: newQueryOptions,
                                ddt_frontend: {
                                    visualization: ((_d = chart === null || chart === void 0 ? void 0 : chart.dataset) === null || _d === void 0 ? void 0 : _d.source)
                                        ? {
                                            version: VISUALISATION_VERSION,
                                            chart: chartOptions,
                                        }
                                        : undefined,
                                    column_categories: visibleColCat_1,
                                    agg_headers: aggHeaders,
                                    mode: mode,
                                    virtual_columns: fetchVirtualColumns
                                        ? {
                                            fetchVirtualColumns: fetchVirtualColumns,
                                            virtualColumnsMetadata: virtualColumnsMetadata,
                                        }
                                        : undefined,
                                    deduplicate: deduplicate,
                                },
                                data_source: (_e = current === null || current === void 0 ? void 0 : current.source) === null || _e === void 0 ? void 0 : _e.data_source_url_slug,
                            },
                        };
                        FileSaver.saveAs(new Blob([JSON.stringify(presetExport)], {
                            type: "application/json",
                        }), "".concat(presetExportName, ".json"));
                    }
                    _f.label = 6;
                case 6:
                    close();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Box, __assign({ sx: { width: "500px", height: "auto", minHeight: "210px" } }, { children: [_jsxs(Stack, __assign({ paddingLeft: 1, spacing: 4, direction: "row", sx: {
                    color: "#272727",
                } }, { children: [_jsx(Typography, __assign({ sx: exportType == "CSV" ? menuSelectStyle : menuNotSelectedStyle, onClick: function () { return setExportType("CSV"); } }, { children: "CSV" })), _jsx(Typography, __assign({ sx: exportType == "JSON" ? menuSelectStyle : menuNotSelectedStyle, onClick: function () { return setExportType("JSON"); } }, { children: "JSON" })), !(current === null || current === void 0 ? void 0 : current.dataView) && (_jsx(Typography, __assign({ sx: exportType == "PRESET" ? menuSelectStyle : menuNotSelectedStyle, onClick: function () { return setExportType("PRESET"); } }, { children: "PRESET" })))] })), _jsx(Box, __assign({ sx: {
                    display: "flex",
                    margin: "30px 0 10px",
                    alignItems: "center",
                    flexDirection: "row",
                } }, { children: exportType == "CSV" || exportType == "JSON" ? (_jsxs(Box, __assign({ display: "contents" }, { children: [(current === null || current === void 0 ? void 0 : current.dataView) ? (_jsxs(Typography, __assign({ sx: { margin: "0 5px", fontWeight: "500" } }, { children: ["Available rows to export : ", max] }))) : (_jsx(TextField, { onChange: handleInput, error: error, label: "Export rows ", helperText: errorMsg, sx: { width: "220px" }, type: "number", value: rows })), (current === null || current === void 0 ? void 0 : current.dataView) ? (_jsx(_Fragment, {})) : error ? (_jsxs(Typography, __assign({ sx: { margin: "0 5px 22px 5px" } }, { children: ["out of ", max, " rows"] }))) : (_jsxs(Typography, __assign({ sx: { margin: "0 5px" } }, { children: ["out of ", max, " rows"] })))] }))) : (_jsxs(Stack, __assign({ spacing: 3 }, { children: [_jsx(Input, { error: presetInputErrorType == "name", errorText: "Name cannot be empty", label: "Name", inputProps: {
                                maxLength: "40",
                            }, onChange: function (e) { return presetInputHandler(e, "name"); }, placeholder: "Enter name for this preset", required: true, type: "text", value: presetExportName }), _jsx(Input, { error: presetInputErrorType == "title", errorText: "Title cannot be empty", label: "Title", inputProps: {
                                maxLength: "40",
                            }, onChange: function (e) { return presetInputHandler(e, "title"); }, placeholder: "Enter title for this preset", required: true, type: "text", value: presetExportTitle }), _jsx(Input, { inputProps: {
                                maxLength: "200",
                            }, label: "Description", onChange: function (e) { return presetInputHandler(e, "description"); }, placeholder: "Enter description for this preset", required: true, type: "text", value: presetExportDescription })] }))) })), _jsxs(Box, __assign({ sx: { display: "flex", flexDirection: "column" } }, { children: [exportType == "CSV" && (_jsx(Checkbox, { checked: humanFriendly, onChange: handleChange, label: "Human friendly names for columns", sx: { width: "fit-content" } })), _jsx(Button, __assign({ onClick: handleExport, sx: { margin: "10px 0 0", width: "fit-content" }, color: "primary", variant: "contained", disabled: (exportType != "PRESET" && error) ||
                            (exportType == "PRESET" && presetInputError) }, { children: "Export" }))] }))] })));
};
export default Export;
