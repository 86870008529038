import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import unauth from "../../Assets/images/401.svg";
import { ErrorPageLayout } from "../Layouts/ErrorPageLayout";
import Desc from "./Desc";
import Title from "./Title";
var Unauthenticated = function (_a) {
    var url = _a.url, redirect = _a.redirect;
    var msg = redirect
        ? "Session expired. Please login to access your view"
        : "Session expired, please login again";
    // url is empty when there's no token provided in url and local storage,
    // hence user has to login through inspera platform
    if (url.length == 0)
        msg = "Please login through inspera platform";
    return (_jsxs(ErrorPageLayout, { children: [_jsx(Title, { children: "Unauthenticated" }), _jsx(Desc, { src: unauth, alt: "Warning", desc: msg, url: url })] }));
};
export default Unauthenticated;
