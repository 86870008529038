var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, MenuItem, Modal, Select, InformationBadge, } from "@inspera/component-library";
// import InformationBadgeComponent from "@inspera/component-library/src/components/InformationBadge/InformationBadge";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ModeType, } from "../../../../Model/model";
import useStore from "../../../../Store/store";
var SourceMenu = function (_a) {
    var collapse = _a.collapse;
    var _b = useStore(), sources = _b.sources, current = _b.current, setCurrent = _b.setCurrent, queryOptions = _b.queryOptions, removeModes = _b.removeModes, setModeType = _b.setModeType, restoreState = _b.restoreState, setRestoreState = _b.setRestoreState, deduplicate = _b.deduplicate, setDeduplicate = _b.setDeduplicate;
    var _c = useState(""), source = _c[0], setSource = _c[1];
    var _d = useState(""), tempChanges = _d[0], setTempChanges = _d[1];
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    var sourceSetter = function (source) {
        var newCurrent = {
            source: sources.filter(function (x) { return x.human_friendly_data_source_name == source; })[0],
            viewDef: null,
        };
        setSource(tempChanges);
        setModeType(ModeType.data);
        removeModes(ModeType.agg);
        setCurrent(newCurrent);
    };
    var handleModalConfirm = function () {
        sourceSetter(tempChanges);
        handleClose();
    };
    var handleSourceChange = function (event) {
        // set restoreState to false when changing just source, as it's already restored in all components
        if (restoreState)
            setRestoreState(false);
        setTempChanges(event.target.value);
        //reset deduplicate to false when source is switched
        if (deduplicate && (current === null || current === void 0 ? void 0 : current.source)) {
            setDeduplicate(false);
        }
        if (!(current === null || current === void 0 ? void 0 : current.source)) {
            //If there's no source set, don't show modal, just set source
            sourceSetter(event.target.value);
            return;
        }
        //If nothing is changed (filters), switch source without modal
        if (queryOptions.filters.length == 0 &&
            queryOptions.sort.length == 0 &&
            !queryOptions.aggs &&
            !queryOptions.date_range) {
            sourceSetter(event.target.value);
            return;
        }
        //If clicked on the same source, just return;
        if (current.source.human_friendly_data_source_name == event.target.value) {
            return;
        }
        handleOpen();
    };
    useEffect(function () {
        var _a, _b, _c;
        if (source != ((_a = current === null || current === void 0 ? void 0 : current.source) === null || _a === void 0 ? void 0 : _a.human_friendly_data_source_name))
            setSource((_c = (_b = current === null || current === void 0 ? void 0 : current.source) === null || _b === void 0 ? void 0 : _b.human_friendly_data_source_name) !== null && _c !== void 0 ? _c : "");
    }, [current]);
    return (_jsxs(Box, __assign({ sx: {
            padding: "24px 17px 0 17px",
            transition: "width 0.2s ",
            width: "229px",
            position: collapse ? "block" : "relative",
            background: "".concat((current === null || current === void 0 ? void 0 : current.source) || collapse ? "#ffffff" : "#fff3dc"),
        } }, { children: [!collapse && current && !current.source && (_jsx(InformationBadge, __assign({ sx: { position: "absolute", marginTop: "-11px", top: "0px" }, color: "warning", variant: "filled" }, { children: "Start here" }))), _jsx(Box, __assign({ sx: { display: "block" } }, { children: _jsx(Typography, __assign({ variant: "body2", sx: {
                        color: "#595959",
                        margin: "0px 0 6px 0",
                    } }, { children: "Source" })) })), _jsx(Box, { children: _jsx(Select, __assign({ variant: "outlined", value: source, displayEmpty: true, renderValue: source !== ""
                        ? undefined
                        : function () { return (_jsx(Typography, __assign({ sx: {
                                color: "#595959",
                                fontSize: "14px",
                                margin: "0px",
                            } }, { children: "Select source" }))); }, sx: {
                        width: "227px",
                        visibility: collapse ? "hidden" : "visible",
                    }, onChange: handleSourceChange }, { children: sources
                        .sort(function (a, b) {
                        return a.data_source_display_order_rank -
                            b.data_source_display_order_rank;
                    })
                        .map(function (source, index) { return (_jsx(MenuItem, __assign({ value: source.human_friendly_data_source_name }, { children: _jsx(Typography, __assign({ sx: {
                                color: "#595959",
                                fontSize: "14px",
                                margin: "0px",
                            } }, { children: source.human_friendly_data_source_name })) }), index)); }) })) }), _jsx(Modal, __assign({ open: open, id: "modal-WithIcon", onBackdropClick: handleClose, onClose: handleClose, title: "Change source?", width: "sm", fullWidth: false }, { children: _jsxs(Stack, __assign({ spacing: 3 }, { children: [_jsx(Typography, __assign({ component: "p", paragraph: true, variant: "body1" }, { children: "You are about to change data source. This will cause you to lose what you have worked on in this view, unless it was saved." })), _jsxs(Stack, __assign({ direction: "row", spacing: 3 }, { children: [_jsx(Button, __assign({ color: "error", variant: "contained", onClick: handleModalConfirm }, { children: "Change source" })), _jsx(Button, __assign({ color: "info", variant: "contained", onClick: handleClose }, { children: "Cancel" }))] }))] })) }))] })));
};
export default SourceMenu;
