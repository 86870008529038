var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Menu, MenuItem, Pagination } from "@inspera/component-library";
import useStore from "../../Store/store";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
var PaginationComponent = function () {
    var _a;
    var _b = useStore(), data = _b.data, setPage = _b.setPage, page = _b.page;
    var _c = useState(1), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = useState(null), anchorEl = _d[0], setAnchorEl = _d[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var totalPages = (_a = data === null || data === void 0 ? void 0 : data.metadata.total_pages) !== null && _a !== void 0 ? _a : 0;
    var pageRanges = function () {
        if (totalPages <= 1)
            return;
        var ranges = [];
        var totalRanges = Math.ceil(totalPages / 5);
        var _loop_1 = function (i) {
            var start = i * 5 + 1;
            var end = start + 4 <= totalPages ? start + 4 : totalPages;
            var selectedRange = false;
            if (currentPage >= start && currentPage <= end)
                selectedRange = true;
            ranges.push(_jsx(MenuItem, __assign({ selected: selectedRange, onClick: function () {
                    handleClose();
                    setPage(start);
                } }, { children: start == end ? start : "".concat(start, "-").concat(end) }), i));
        };
        for (var i = 0; i < totalRanges; i++) {
            _loop_1(i);
        }
        return ranges;
    };
    useEffect(function () {
        if (page)
            setCurrentPage(page);
    }, [page]);
    var handleChangePage = function (e, newPage) {
        setPage(newPage);
        setCurrentPage(newPage);
    };
    return (_jsxs(Box, __assign({ sx: {
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
        } }, { children: [_jsx(Box, __assign({ sx: { backgroundColor: "white" } }, { children: _jsx(Pagination, { count: totalPages, variant: "outlined", shape: "rounded", onChange: handleChangePage, page: currentPage }) })), _jsxs(Box, { children: [_jsxs(IconButton, __assign({ id: "basic-button", "aria-controls": open ? "basic-menu" : undefined, "aria-haspopup": "true", "aria-expanded": open ? "true" : undefined, sx: {
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }, onClick: handleClick }, { children: [_jsx(Typography, __assign({ sx: { margin: "0 0 0 10px" } }, { children: "Jump to page" })), !open ? (_jsx(ExpandMoreOutlined, { sx: { height: "20", width: "20" } })) : (_jsx(ExpandLessOutlined, { sx: { height: "20", width: "20" } }))] })), _jsx(Menu, __assign({ id: "basic-menu", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                            "aria-labelledby": "basic-button",
                        }, anchorOrigin: { vertical: "top", horizontal: "right" }, transformOrigin: { vertical: "bottom", horizontal: "center" }, PaperProps: {
                            style: {
                                maxHeight: "440px",
                                marginBottom: "20px",
                            },
                        } }, { children: pageRanges() }))] })] })));
};
export default PaginationComponent;
