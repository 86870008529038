var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@inspera/component-library";
export var OptionBtn = function (_a) {
    var isSharedView = _a.isSharedView, handleOnClick = _a.handleOnClick;
    return (_jsx(_Fragment, { children: !isSharedView && (_jsx(Button, __assign({ variant: "contained", sx: {
                width: "84px",
                height: "44px",
                ml: "9px",
            }, color: "secondary", onClick: handleOnClick }, { children: "Options" }))) }));
};
