import metadata from "./ColumnsMetadata/metadata.json";
import packageJson from "../../package.json";
export var URL = process.env.REACT_APP_API_URL;
export var IA_URL = process.env.REACT_APP_IA_URL;
export var IA_LOGIN_URL = IA_URL + "/admin";
export var limits = [10, 20, 30, 50];
//While running tests, window doesn't exist as it's an object model for browser (BOM), so setting APP_URL as localhost
var _APP_URL = "";
if (typeof window == "undefined")
    _APP_URL = "https://localhost:3000";
else
    _APP_URL = window.location.protocol + "//" + window.location.host;
export var APP_URL = _APP_URL;
var metadataObj = metadata;
export var sourceColumns = metadataObj.data_sources;
export var columnCategoriesMetadata = metadataObj.active_column_categories;
export var APP_VERSION = packageJson.version;
export var GIT_VERSION = process.env.GIT_VERSION;
export var VISUALISATION_VERSION = "2.0.0";
