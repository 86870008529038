var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GlobalStyles, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router";
import { useIdleTimer } from "react-idle-timer";
import { useEffect, useState } from "react";
import Auth from "./Pages/Auth";
import ErrorPage from "./Pages/ErrorPage";
import LoginPage from "./Pages/LoginPage";
import { theme } from "./theme";
import "./index.css";
import useStore from "./Store/store";
export var App = function () {
    var _a = useState(Date.now()), appStartTime = _a[0], setAppStartTime = _a[1];
    var _b = useState(true), isActive = _b[0], setIsActive = _b[1];
    var _c = useStore(), userId = _c.userId, domain = _c.domain;
    useEffect(function () {
        var timeSpent = (Date.now() - appStartTime) / 1000;
        //When the tab is closed send the active time of user to log api
        var handleTabClose = function (event) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        }); };
        window.addEventListener("beforeunload", handleTabClose);
        return function () {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);
    var onIdle = function () { return __awaiter(void 0, void 0, void 0, function () {
        var timeSpent;
        return __generator(this, function (_a) {
            // if user is inactive, the log report was sent once, no need to send unless user is active again
            if (!isActive)
                return [2 /*return*/];
            timeSpent = Date.now() - appStartTime;
            //TODO : send request to log-api
            if (isActive)
                setIsActive(false);
            return [2 /*return*/];
        });
    }); };
    var onActive = function (even) {
        //After remaining idle, when user becomes active this function is called
        //reset appStartTime as the user became active just now and mark user as active
        if (!isActive)
            setIsActive(true);
        setAppStartTime(Date.now());
    };
    var getElapsedTime = useIdleTimer({
        onIdle: onIdle,
        onActive: onActive,
        timeout: 10 * 1000,
        events: [
            "mousemove",
            "keydown",
            "wheel",
            "DOMMouseScroll",
            "mousewheel",
            "mousedown",
            "touchstart",
            "touchmove",
            "MSPointerDown",
            "MSPointerMove",
            "visibilitychange",
            "focus",
        ],
        immediateEvents: [],
        name: "idle-timer",
    }).getElapsedTime;
    return (_jsxs(ThemeProvider, __assign({ theme: theme }, { children: [_jsx(GlobalStyles, { styles: {
                    "&::-webkit-scrollbar": {
                        width: "20px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#d6dee1",
                        borderRadius: "20px",
                        border: "6px solid transparent",
                        backgroundClip: "content-box",
                    },
                } }), _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Auth, {}) }), _jsx(Route, { path: "/savedViews", element: _jsx(Auth, {}) }), _jsx(Route, __assign({ path: "share" }, { children: _jsx(Route, { path: "/share/view/:viewId", element: _jsx(LoginPage, {}) }) })), _jsx(Route, { path: "/auth/", element: _jsx(LoginPage, {}) }), _jsx(Route, { path: "/error/", element: _jsx(ErrorPage, {}) })] })] })));
};
