var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@inspera/component-library";
import useStore from "../../../Store/store";
export var DataViewModalCard = function (_a) {
    var dataView = _a.dataView, handleClose = _a.handleClose;
    var setCurrent = useStore().setCurrent;
    var handleDataViewSelection = function () {
        setCurrent({
            source: null,
            viewDef: null,
            preset: null,
            dataView: dataView,
            viewOwn: false,
        });
        handleClose();
    };
    return (_jsxs(Box, __assign({ sx: {
            border: "1px solid #D9D9D9",
            borderRadius: "5px",
            alignItems: "center",
            padding: "20px",
            flex: "0 0 33%",
            cursor: "pointer",
            width: "250px",
            "&:hover": {
                background: "#e0e0e0",
            },
        }, onClick: handleDataViewSelection }, { children: [_jsx(Typography, __assign({ sx: { fontSize: "16px", color: "#272727", marginBottom: "4px" } }, { children: dataView.title })), _jsx(Typography, __assign({ sx: { fontSize: "14px", color: "#272727", marginBottom: "4px" } }, { children: dataView.description }))] })));
};
