var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from "@inspera/component-library";
import { Box } from "@mui/material";
export var EmptyAggTable = function () {
    return (_jsx(Box, __assign({ sx: {
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            justifySelf: "center",
            display: "flex",
            paddingBottom: "23px",
            bgcolor: "#F7F7F7",
        } }, { children: _jsx(Box, __assign({ sx: {
                bgcolor: "white",
                justifySelf: "center",
                boxSizing: "border-box",
                border: "1px solid #EDEDED",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
            } }, { children: _jsx(Typography, { children: "No results found agg" }) })) })));
};
