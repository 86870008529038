var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { ReactECharts } from "../../../../Utils/ReactEchartsWrapper/ReactEcharts";
import { theme } from "./themes";
import useStore from "../../../../Store/store";
import FocusTrap from "@mui/base/TrapFocus";
import { addVisualMap, GetEChartOptions, resetVisualMap, setDataset, } from "./utils";
import { Box, ClickAwayListener, Popper, Typography, } from "@mui/material";
import { Button, CloseButton, EditIcon, Input, InputLabel, MenuItem, Select, } from "@inspera/component-library";
import _ from "lodash";
import { ModeType } from "../../../../Model/model";
import { hashCode } from "../../../../Utils/util";
var Visualisation = function () {
    var _a;
    var _b = useStore(), chart = _b.chart, setChart = _b.setChart, current = _b.current, mode = _b.mode, data = _b.data, restoreState = _b.restoreState, tabSwitching = _b.tabSwitching, setTabSwitching = _b.setTabSwitching, setRestoreState = _b.setRestoreState, userId = _b.userId;
    var _c = useState({}), options = _c[0], setOptions = _c[1];
    var _d = useState(chart === null || chart === void 0 ? void 0 : chart.labelXAxis), xAxis = _d[0], setXAxis = _d[1];
    var _e = useState(chart === null || chart === void 0 ? void 0 : chart.labelYAxis), yAxis = _e[0], setYAxis = _e[1];
    //which label is editable currently
    var _f = useState(""), editableLabel = _f[0], setEditableLabel = _f[1];
    var _g = useState(false), disableHighlight = _g[0], setDisable = _g[1];
    var _h = useState("Add title"), title = _h[0], setTitle = _h[1];
    var _j = useState("Add description"), description = _j[0], setDescription = _j[1];
    var _k = useState(""), hoverEdit = _k[0], setHoverEdit = _k[1];
    //Edit Viz
    var _l = useState(null), anchorEl = _l[0], setAnchorEl = _l[1];
    var openEditVis = Boolean(anchorEl);
    //chart
    var _m = useState("#0059b3"), color = _m[0], setColor = _m[1];
    var _o = useState("#595959"), highlightColor = _o[0], setHighlightColor = _o[1];
    var _p = useState("gt"), op = _p[0], setOp = _p[1];
    var _q = useState(""), highlightValue = _q[0], setHighlightValue = _q[1];
    var handleEditVis = function (event) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    var handleCloseEditVis = function () { return setAnchorEl(null); };
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (current === null || current === void 0 ? void 0 : current.preset)
            return;
        //If agg data hasn't been fetched yet, don't set charts
        if (!(data === null || data === void 0 ? void 0 : data.aggs))
            return;
        //If vis is rendered on tabswitching, no need to populate using viewDef or set to default state
        if (tabSwitching)
            return;
        else {
            // Chart is set using viewDef or set to default, no need to re-render on tabSwitching
            setTabSwitching(true);
        }
        var hashUserId = hashCode(String(userId));
        var savedState = localStorage.getItem("options".concat(hashUserId));
        var viewChart;
        if (savedState &&
            restoreState &&
            ((_a = JSON.parse(savedState).parameters.ddt_frontend
                .visualization) === null || _a === void 0 ? void 0 : _a.chart)) {
            viewChart = (_b = JSON.parse(savedState).parameters
                .ddt_frontend.visualization) === null || _b === void 0 ? void 0 : _b.chart;
        }
        else if ((current === null || current === void 0 ? void 0 : current.viewDef) == null ||
            !((_e = (_d = (_c = current === null || current === void 0 ? void 0 : current.viewDef) === null || _c === void 0 ? void 0 : _c.parameters) === null || _d === void 0 ? void 0 : _d.ddt_frontend.visualization) === null || _e === void 0 ? void 0 : _e.chart)) {
            return;
        }
        else {
            viewChart = JSON.parse(JSON.stringify(current.viewDef.parameters.ddt_frontend.visualization.chart));
        }
        setXAxis(viewChart.labelXAxis);
        setYAxis(viewChart.labelYAxis);
        setTitle(viewChart.title);
        setDescription(viewChart.description);
        if (((_f = viewChart === null || viewChart === void 0 ? void 0 : viewChart.visualMap) === null || _f === void 0 ? void 0 : _f.pieces) != null) {
            setOp(Object.keys(viewChart.visualMap.pieces[1])[0]);
            setHighlightValue(Object.entries(viewChart.visualMap.pieces[1])[0][1].toString());
            setHighlightColor(viewChart.visualMap.pieces[1].color);
        }
        else {
            setColor("#0059b3");
            setHighlightColor("#595959");
            setOp("gt");
            setHighlightValue("");
        }
        if ((_g = viewChart.dataset) === null || _g === void 0 ? void 0 : _g.dimension) {
            var dimensions = (_h = viewChart.dataset) === null || _h === void 0 ? void 0 : _h.dimension;
            setDataset(viewChart, dimensions, data === null || data === void 0 ? void 0 : data.aggs.rows, data === null || data === void 0 ? void 0 : data.aggs.grouped_by);
            setChart(viewChart);
        }
        if (viewChart.color != null) {
            setColor(viewChart.color);
        }
    }, [current === null || current === void 0 ? void 0 : current.viewDef]);
    useEffect(function () {
        var _a, _b, _c, _d;
        if (current === null || current === void 0 ? void 0 : current.preset) {
            //If agg data hasn't been fetched yet, don't set charts
            if (!(data === null || data === void 0 ? void 0 : data.aggs))
                return;
            //If vis is rendered on tabswitching, no need to populate using viewDef or set to default state
            if (tabSwitching)
                return;
            else {
                // Chart is set using preset or set to default, no need to re-render on tabSwitching
                setTabSwitching(true);
            }
            var presetChart = void 0;
            if (!((_a = current.preset.parameters.ddt_frontend) === null || _a === void 0 ? void 0 : _a.visualization)) {
                return;
            }
            else {
                presetChart = _.cloneDeep(current.preset.parameters.ddt_frontend.visualization.chart);
            }
            setXAxis(presetChart.labelXAxis);
            setYAxis(presetChart.labelYAxis);
            setTitle(presetChart.title);
            setDescription(presetChart.description);
            if (((_b = presetChart.visualMap) === null || _b === void 0 ? void 0 : _b.pieces) != null) {
                setOp(Object.keys(presetChart.visualMap.pieces[1])[0]);
                setHighlightValue(Object.entries(presetChart.visualMap.pieces[1])[0][1].toString());
                setHighlightColor(presetChart.visualMap.pieces[1].color);
            }
            else {
                setColor("#0059b3");
                setHighlightColor("#595959");
                setOp("gt");
                setHighlightValue("");
            }
            if ((_c = presetChart.dataset) === null || _c === void 0 ? void 0 : _c.dimension) {
                var dimensions = (_d = presetChart.dataset) === null || _d === void 0 ? void 0 : _d.dimension;
                setDataset(presetChart, dimensions, data === null || data === void 0 ? void 0 : data.aggs.rows, data === null || data === void 0 ? void 0 : data.aggs.grouped_by);
                setChart(presetChart);
            }
            if (presetChart.color != null) {
                setColor(presetChart.color);
            }
        }
    }, [current === null || current === void 0 ? void 0 : current.preset]);
    useEffect(function () {
        var _a;
        if (mode.active == ModeType.vis) {
            setXAxis(chart.labelXAxis);
            setYAxis(chart.labelYAxis);
            setTitle(chart.title);
            setDescription(chart.description);
            setOptions(GetEChartOptions(chart));
            //setting edit visualisation options
            if (((_a = chart === null || chart === void 0 ? void 0 : chart.visualMap) === null || _a === void 0 ? void 0 : _a.pieces) != null) {
                setOp(Object.keys(chart.visualMap.pieces[1])[0]);
                setHighlightValue(Object.entries(chart.visualMap.pieces[1])[0][1].toString());
                setHighlightColor(chart.visualMap.pieces[1].color);
            }
            else {
                setColor("#0059b3");
                setHighlightColor("#595959");
                setOp("gt");
                setHighlightValue("");
            }
            if (chart.color != null) {
                setColor(chart.color);
            }
        }
    }, [JSON.stringify(chart)]);
    var handleChange = function (event, edit) {
        if (edit == "title")
            setTitle(event.target.value);
        else if (edit == "description")
            setDescription(event.target.value);
        else if (edit == "xAxis")
            setXAxis(event.target.value);
        else if (edit == "yAxis")
            setYAxis(event.target.value);
    };
    var handleClose = function (labelType) {
        var newChart = JSON.parse(JSON.stringify(chart));
        if (labelType == "xAxis") {
            newChart.labelXAxis = xAxis;
        }
        else if (labelType == "yAxis") {
            newChart.labelYAxis = yAxis;
        }
        else if (labelType == "title") {
            newChart.title = title;
        }
        else if (labelType == "description") {
            newChart.description = description;
        }
        setChart(newChart);
        setEditableLabel("");
        setHoverEdit("");
    };
    var handleEnterKeyPress = function (e, labelType) {
        if (e.key == "Enter") {
            setEditableLabel("");
            handleClose(labelType);
        }
    };
    var handleReset = function () {
        setHighlightValue("");
        setHighlightColor("#595959");
        setOp("gt");
        var newChart = resetVisualMap(chart);
        setColor("#0059b3");
        setChart(newChart);
        handleCloseEditVis();
    };
    var ColorMenuItem = function (color, name) {
        return (_jsx(MenuItem, __assign({ value: color }, { children: _jsxs(Box, __assign({ sx: {
                    display: "flex",
                    whiteSpace: "nowrap",
                    alignItems: "center",
                } }, { children: [_jsx(Box, { width: "15px", height: "15px", mr: "4px", borderRadius: "2px", bgcolor: color.length > 0 ? color : "#000000" }), _jsx(Typography, __assign({ sx: { mb: "0px" } }, { children: name }))] })) })));
    };
    var handleColorChange = function (event) {
        setColor(event.target.value);
    };
    var handleHighlightColorChange = function (event) {
        setHighlightColor(event.target.value);
    };
    var handleOpChange = function (event) {
        setOp(event.target.value);
    };
    var handleHighlightInput = function (event) {
        setHighlightValue(event.target.value.toString());
    };
    var handleApplyEditVis = function (event) {
        var _a;
        if (chart != null) {
            var dimension = 1;
            if ((data === null || data === void 0 ? void 0 : data.aggs) != null && chart.series != null) {
                dimension = (_a = chart.dataset) === null || _a === void 0 ? void 0 : _a.dimension.indexOf(chart.y);
            }
            var temp = addVisualMap(chart, op, color, highlightColor, highlightValue, dimension);
            if (restoreState)
                setRestoreState(false);
            setChart(temp);
        }
        handleCloseEditVis();
    };
    return chart ? (_jsxs(Box, __assign({ sx: {
            background: "#ffffff",
            padding: "25px 17px 27px 25px",
            height: "90%",
        } }, { children: [_jsxs(Box, __assign({ sx: { display: "flex", justifyContent: "space-between" } }, { children: [_jsxs(Box, { children: [_jsx(Box, { children: editableLabel == "title" ? (_jsx(ClickAwayListener, __assign({ onClickAway: function () { return handleClose("title"); } }, { children: _jsx(Input, { value: title == "Add title" ? "" : title, onChange: function (e) { return handleChange(e, "title"); }, onBlur: function () { return handleClose("title"); }, onKeyDown: function (e) { return handleEnterKeyPress(e, "title"); }, type: "text", placeholder: title, autoFocus: true }) }))) : (_jsxs(Box, __assign({ sx: { display: "flex", cursor: "pointer" } }, { children: [_jsx(Typography, __assign({ sx: {
                                                margin: "0 4px 0 0",
                                                fontSize: "16px",
                                                color: "#595959",
                                            }, onClick: function () {
                                                setEditableLabel("title");
                                            }, onMouseOver: function () { return setHoverEdit("title"); }, onMouseLeave: function () { return setHoverEdit(""); } }, { children: title })), _jsx(Box, __assign({ sx: {
                                                transform: "translateY(-2px)",
                                                color: "#595959",
                                                visibility: hoverEdit == "title" ? "visible" : "hidden",
                                            } }, { children: _jsx(EditIcon, {}) }))] }))) }), _jsx(Box, { children: editableLabel == "description" ? (_jsx(ClickAwayListener, __assign({ onClickAway: function () { return handleClose("description"); } }, { children: _jsx(Input, { value: description == "Add description" ? "" : description, onChange: function (e) { return handleChange(e, "description"); }, onBlur: function () { return handleClose("description"); }, onKeyDown: function (e) { return handleEnterKeyPress(e, "description"); }, type: "text", style: { fontSize: "14px" }, placeholder: description, autoFocus: true }) }))) : (_jsxs(Box, __assign({ sx: { display: "flex", cursor: "pointer" }, onClick: function () {
                                        setEditableLabel("description");
                                    }, onMouseOver: function () { return setHoverEdit("description"); }, onMouseLeave: function () { return setHoverEdit(""); } }, { children: [_jsx(Typography, __assign({ sx: {
                                                margin: "0 4px 0 0",
                                                fontSize: "14px",
                                                color: "#595959",
                                            } }, { children: description })), _jsx(Box, __assign({ sx: {
                                                transform: "translateY(-2px)",
                                                color: "#595959",
                                                visibility: hoverEdit == "description" ? "visible" : "hidden",
                                            } }, { children: _jsx(EditIcon, {}) }))] }))) })] }), _jsx(Button, __assign({ id: "Edit Viz", sx: { minWidth: "195px", height: "50px", whiteSpace: "nowrap" }, variant: "contained", color: "secondary", endIcon: _jsx(EditIcon, {}), onClick: handleEditVis }, { children: "Edit visualisation" }))] })), _jsxs(Box, __assign({ sx: { mt: "20px", pr: "40px", height: "80%" } }, { children: [chart.series.type != "pie" && (_jsx(Box, { children: editableLabel == "yAxis" ? (_jsx(ClickAwayListener, __assign({ onClickAway: function () { return handleClose("yAxis"); } }, { children: _jsx(Input, { value: yAxis, onChange: function (e) { return handleChange(e, "yAxis"); }, onBlur: function () { return handleClose("yAxis"); }, onKeyDown: function (e) { return handleEnterKeyPress(e, "yAxis"); }, type: "text", placeholder: yAxis, autoFocus: true }) }))) : (_jsxs(Box, __assign({ sx: { display: "flex", cursor: "pointer" } }, { children: [_jsx(Typography, __assign({ sx: {
                                        margin: "0 4px 0 0",
                                        color: "#404040",
                                        fontWeight: "500",
                                    }, onClick: function () {
                                        setEditableLabel("yAxis");
                                    }, onMouseOver: function () { return setHoverEdit("yAxis"); }, onMouseLeave: function () { return setHoverEdit(""); } }, { children: yAxis })), _jsx(Box, __assign({ sx: {
                                        transform: "translateY(-2px)",
                                        color: "#595959",
                                        visibility: hoverEdit == "yAxis" ? "visible" : "hidden",
                                    } }, { children: _jsx(EditIcon, {}) }))] }))) })), ((_a = options.dataset) === null || _a === void 0 ? void 0 : _a.source) != undefined && (_jsx(ReactECharts, { style: { height: "100%" }, option: options, theme: theme })), chart.series.type != "pie" && (_jsx(Box, __assign({ sx: { display: "flex", justifyContent: "right" } }, { children: editableLabel == "xAxis" ? (_jsx(ClickAwayListener, __assign({ onClickAway: function () { return handleClose("xAxis"); } }, { children: _jsx(Input, { value: xAxis, onChange: function (e) { return handleChange(e, "xAxis"); }, onBlur: function () { return handleClose("xAxis"); }, onKeyDown: function (e) { return handleEnterKeyPress(e, "xAxis"); }, type: "text", placeholder: xAxis, autoFocus: true }) }))) : (_jsxs(Box, __assign({ sx: { display: "flex", cursor: "pointer" }, onClick: function () {
                                setEditableLabel("xAxis");
                            }, onMouseOver: function () { return setHoverEdit("xAxis"); }, onMouseLeave: function () { return setHoverEdit(""); } }, { children: [_jsx(Typography, __assign({ sx: {
                                        margin: "0 4px 0 0",
                                        color: "#404040",
                                        fontWeight: "500",
                                    } }, { children: xAxis })), _jsx(Box, __assign({ sx: {
                                        transform: "translateY(-2px)",
                                        color: "#595959",
                                        visibility: hoverEdit == "xAxis" ? "visible" : "hidden",
                                    } }, { children: _jsx(EditIcon, {}) }))] }))) })))] })), _jsx(ClickAwayListener, __assign({ onClickAway: handleCloseEditVis, mouseEvent: "onMouseUp" }, { children: _jsx(Popper, __assign({ id: "Edit Viz", anchorEl: anchorEl, placement: "auto-start", sx: { zIndex: "1000" }, modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [10, 20],
                            },
                        },
                    ], open: openEditVis }, { children: _jsx(FocusTrap, __assign({ open: openEditVis }, { children: _jsxs(Box, __assign({ sx: {
                                display: "flex",
                                flexDirection: "column",
                                background: "#FFFFFF",
                                borderRadius: "4px",
                                boxShadow: "0px 6px 8px -6px rgba(39, 39, 39, 0.12), 0px 8px 16px -6px rgba(39, 39, 39, 0.08)",
                                padding: "24px",
                                width: "430px",
                            }, tabIndex: -1 }, { children: [_jsxs(Box, __assign({ sx: {
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mb: "17px",
                                    } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: {
                                                color: "#272727",
                                                marginBottom: 0,
                                                alignSelf: "center",
                                                fontSize: "18px",
                                                fontWeight: "500",
                                            } }, { children: "Edit visualisation" })), _jsx(CloseButton, { size: "small", "aria-label": "close", onClick: handleCloseEditVis })] })), _jsxs(Box, __assign({ mb: "25px" }, { children: [_jsx(InputLabel, __assign({ htmlFor: "color-select-label" }, { children: "Color" })), _jsxs(Select
                                        // disabled
                                        , __assign({ 
                                            // disabled
                                            id: "color-select-label", variant: "outlined", onChange: handleColorChange, value: color, style: { marginTop: "1px", whiteSpace: "nowrap" } }, { children: [chart.series.type == "pie" &&
                                                    ColorMenuItem("#000000", "Random"), ColorMenuItem("#0059b3", "Blue"), ColorMenuItem("#3399ff", "Light Blue"), ColorMenuItem("#1BA76D", "Green"), ColorMenuItem("#9F88D7", "Purple"), ColorMenuItem("#595959", "Gray"), ColorMenuItem("#404040", "Dark grey"), ColorMenuItem("#ed457d", "Pink"), ColorMenuItem("#f04242", "Red")] }))] })), _jsx(Box, __assign({ sx: {
                                        display: "flex",
                                        mb: "16px",
                                    } }, { children: _jsx(Typography, __assign({ variant: "h4", sx: {
                                            color: "#272727",
                                            marginBottom: 0,
                                            alignSelf: "center",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                        } }, { children: "Highlight values" })) })), _jsxs(Box, __assign({ sx: {
                                        display: "flex",
                                        gap: "15px",
                                        alignItems: "flex-end",
                                    } }, { children: [_jsxs(Box, { children: [_jsx(InputLabel, __assign({ htmlFor: "highlight-color-select-label" }, { children: "Color" })), _jsxs(Select, __assign({ disabled: disableHighlight, id: "highlight-color-select-label", variant: "outlined", onChange: handleHighlightColorChange, value: highlightColor, style: { marginTop: "1px" } }, { children: [ColorMenuItem("#0059b3", "Blue"), ColorMenuItem("#3399ff", "Light Blue"), ColorMenuItem("#1BA76D", "Green"), ColorMenuItem("#9F88D7", "Purple"), ColorMenuItem("#595959", "Gray"), ColorMenuItem("#404040", "Dark grey"), ColorMenuItem("#ed457d", "Pink"), ColorMenuItem("#f04242", "Red")] }))] }), _jsx(Box, { children: _jsx(Typography, __assign({ sx: { mb: "9px", textAlign: "center" } }, { children: "Where" })) }), _jsx(Box, { children: _jsxs(Select, __assign({ disabled: disableHighlight, variant: "outlined", defaultValue: "gt", onChange: handleOpChange, value: op, style: { marginTop: "1px" } }, { children: [_jsx(MenuItem, __assign({ value: "gt" }, { children: ">" })), _jsx(MenuItem, __assign({ value: "gte" }, { children: ">=" })), _jsx(MenuItem, __assign({ value: "lte" }, { children: "<=" })), _jsx(MenuItem, __assign({ value: "lt" }, { children: "<" }))] })) }), _jsxs(Box, { children: [_jsx(InputLabel, __assign({ sx: { mb: "1px" }, htmlFor: "value-input" }, { children: "Value" })), _jsx(Input, { disabled: disableHighlight, id: "value-input", placeholder: "value", type: "number", onChange: handleHighlightInput, value: highlightValue })] })] })), _jsxs(Box, __assign({ sx: {
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "175px",
                                        marginTop: "20px",
                                    } }, { children: [_jsx(Button, __assign({ onClick: handleApplyEditVis, variant: "contained", color: "primary" }, { children: "Apply" })), _jsx(Button, __assign({ onClick: handleReset, variant: "contained", color: "secondary" }, { children: "Reset" }))] }))] })) })) })) }))] }))) : (_jsx(_Fragment, {}));
};
export default Visualisation;
