var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableCell, TableHead, TableRow, TableSortLabel, } from "@inspera/component-library";
import { Box } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
var VIewTableHead = function (_a) {
    var onRequestSort = _a.onRequestSort, order = _a.order, orderBy = _a.orderBy;
    var createSortHandler = function (property) { return function (event) {
        onRequestSort(event, property);
    }; };
    var colHeaders = [
        { field: "name", headerName: "Name" },
        { field: "description", headerName: "Description" },
        { field: "data_source", headerName: "Source" },
        { field: "timestamp", headerName: "Date added" },
    ];
    return (_jsx(TableHead, { children: _jsxs(TableRow, { children: [colHeaders.map(function (col, idx) { return (_jsx(TableCell, __assign({ id: col.field, sortDirection: orderBy === col.field ? order : false }, { children: _jsxs(TableSortLabel, __assign({ active: orderBy === col.field, direction: orderBy === col.field ? order : "asc", onClick: createSortHandler(col.field) }, { children: [col.headerName, orderBy === col.field ? (_jsx(Box, __assign({ component: "span", sx: visuallyHidden }, { children: order === "desc" ? "sorted descending" : "sorted ascending" }))) : null] })) }), idx)); }), _jsx(TableCell, { id: "option-tbn" })] }) }));
};
export default VIewTableHead;
