var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Box, Typography, Popper, ClickAwayListener, Snackbar, Alert, ListSubheader, } from "@mui/material";
import FocusTrap from "@mui/base/TrapFocus";
import { CloseButton, Button, MenuItem, Select, Tooltip, Divider, } from "@inspera/component-library";
import plus from "../../../../Assets/images/plus.svg";
import SortIcon from "@mui/icons-material/Sort";
import useStore from "../../../../Store/store";
import { createJSONSorts } from "./ES_to_JsonSort";
import { hashCode } from "../../../../Utils/util";
var SortMenu = function (_a) {
    var variableToFriendlyColumn = _a.variableToFriendlyColumn, sortCount = _a.sortCount;
    var _b = useState(1), currentNumSorts = _b[0], setCurrentNumSorts = _b[1];
    var _c = useState(false), sortApplied = _c[0], setSortApplied = _c[1];
    var _d = useStore(), queryOptions = _d.queryOptions, setQueryOptions = _d.setQueryOptions, setPage = _d.setPage, current = _d.current, visibleColumns = _d.visibleColumns, deepLinkQuery = _d.deepLinkQuery, deepLinkQueryOption = _d.deepLinkQueryOption, restoreState = _d.restoreState, columns = _d.columns, setRestoreState = _d.setRestoreState, userId = _d.userId;
    var _e = useState([
        {
            field_name: columns[0].column_name,
            order: "asc",
        },
    ]), sortRows = _e[0], setSortRows = _e[1];
    var _f = useState(false), disable = _f[0], setDisable = _f[1];
    var _g = useState(null), anchorEl = _g[0], setAnchorEl = _g[1];
    //Store column index which were changed manually, so that we set the first col as default when column order is changed
    var _h = useState([]), changeColumn = _h[0], setChangeColumn = _h[1];
    //Tooltip for Select
    var _j = useState(-1), tooltipOpen = _j[0], setTooltipOpen = _j[1];
    var handleTooltip = function (index) {
        //if -1 then close the tooltip or open the particular row's index tooltip
        setTooltipOpen(index);
    };
    var handleClick = function (event) {
        if (visibleColumns == 0) {
            setDisable(true);
            return;
        }
        var copySortRows = JSON.parse(JSON.stringify(sortRows));
        copySortRows.map(function (sorts, index) {
            //If user hasn't changed the column, change this to First column in the view/table, and order ascending
            if (!changeColumn[index]) {
                sorts.field_name = columns[0].column_name;
                sorts.order = "asc";
            }
        });
        setSortRows(copySortRows);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    var open = Boolean(anchorEl);
    var id = open ? "simple-popper" : undefined;
    var handleClose = function () { return setAnchorEl(null); };
    useEffect(function () {
        if ((current === null || current === void 0 ? void 0 : current.viewDef) != null || restoreState || (current === null || current === void 0 ? void 0 : current.preset))
            return;
        setSortRows([
            {
                field_name: columns[0].column_name,
                order: "asc",
            },
        ]);
        setCurrentNumSorts(1);
        setSortApplied(false);
        setChangeColumn([false]);
    }, [current === null || current === void 0 ? void 0 : current.source]);
    useEffect(function () {
        var _a;
        var options;
        var hashUserId = hashCode(String(userId));
        var savedState = localStorage.getItem("options".concat(hashUserId));
        if (restoreState && savedState) {
            options = JSON.parse(savedState).parameters;
        }
        else {
            options = (_a = current === null || current === void 0 ? void 0 : current.viewDef) === null || _a === void 0 ? void 0 : _a.parameters;
        }
        if (!options || options.body.sort.length == 0) {
            setSortRows([
                {
                    field_name: columns[0].column_name,
                    order: "asc",
                },
            ]);
            setCurrentNumSorts(1);
            setSortApplied(false);
            setChangeColumn([false]);
            return;
        }
        var newSortRows = createJSONSorts(options.body.sort);
        if (newSortRows.length == 0)
            setSortApplied(false);
        else
            setSortApplied(true);
        setSortRows(newSortRows);
        setCurrentNumSorts(newSortRows.length);
        var newChangeColumn = new Array(newSortRows.length).fill(true);
        setChangeColumn(newChangeColumn);
    }, [current === null || current === void 0 ? void 0 : current.viewDef]);
    useEffect(function () {
        if (current === null || current === void 0 ? void 0 : current.preset) {
            var presetData = current.preset;
            if (presetData.parameters.body.sort.length == 0) {
                setSortRows([
                    {
                        field_name: columns[0].column_name,
                        order: "asc",
                    },
                ]);
                setCurrentNumSorts(1);
                setSortApplied(false);
                setChangeColumn([false]);
            }
            else {
                var newSortRows = createJSONSorts(presetData.parameters.body.sort);
                if (newSortRows.length == 0)
                    setSortApplied(false);
                else
                    setSortApplied(true);
                setSortRows(newSortRows);
                setCurrentNumSorts(newSortRows.length);
                var newChangeColumn = new Array(newSortRows.length).fill(true);
                setChangeColumn(newChangeColumn);
            }
        }
    }, [current === null || current === void 0 ? void 0 : current.preset]);
    useEffect(function () {
        if ((deepLinkQueryOption === null || deepLinkQueryOption === void 0 ? void 0 : deepLinkQueryOption.sort) && deepLinkQueryOption.sort.length > 0) {
            var newSortRows = createJSONSorts(deepLinkQueryOption.sort);
            setSortRows(newSortRows);
            setCurrentNumSorts(newSortRows.length);
            setSortApplied(true);
            setCurrentNumSorts(newSortRows.length);
            var newChangeColumn = new Array(newSortRows.length).fill(true);
            setChangeColumn(newChangeColumn);
        }
    }, [deepLinkQueryOption]);
    var handleRemoveSort = function (e, index) {
        if (sortRows.length == 1) {
            setSortRows([
                {
                    field_name: columns[0].column_name,
                    order: "asc",
                },
            ]);
            setChangeColumn([false]);
            return;
        }
        var copySortRows = JSON.parse(JSON.stringify(sortRows));
        copySortRows.splice(index, 1);
        setSortRows(copySortRows);
        var newChangeColumn = JSON.parse(JSON.stringify(changeColumn));
        newChangeColumn.splice(index, 1);
        setChangeColumn(newChangeColumn);
        setCurrentNumSorts(function (prevNumSorts) { return prevNumSorts - 1; });
    };
    var handleAddSort = function () {
        var copySortRows = JSON.parse(JSON.stringify(sortRows));
        copySortRows[currentNumSorts] = __assign(__assign({}, copySortRows[currentNumSorts]), { field_name: columns[0].column_name, order: "asc" });
        setSortRows(copySortRows);
        var newChangeColumn = __spreadArray(__spreadArray([], changeColumn, true), [false], false);
        setChangeColumn(newChangeColumn);
        setCurrentNumSorts(function (prevNumSorts) { return prevNumSorts + 1; });
    };
    var colDropdown = function () {
        var options = [];
        options.push(_jsx(ListSubheader, __assign({ sx: {
                fontSize: "12px",
                color: "#272727",
                lineHeight: "120%",
                margin: "10px 0",
            }, onClickCapture: function (e) { return e.stopPropagation(); } }, { children: "Columns in your view" }), "Show"));
        options.push(_jsx(Divider, {}, "divider1"));
        columns.map(function (col) {
            if (col.show) {
                options.push(_jsx(MenuItem, __assign({ sx: {
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#404040",
                        lineHeight: "20px",
                        padding: "13px",
                    }, value: col["column_name"] }, { children: col["human_friendly_column_name"] }), col["column_name"]));
            }
        });
        options.push(_jsx(ListSubheader, __assign({ sx: {
                fontSize: "12px",
                color: "#272727",
                lineHeight: "120%",
                margin: "10px 0",
            }, onClickCapture: function (e) { return e.stopPropagation(); } }, { children: "Other columns" }), "Hide"));
        options.push(_jsx(Divider, {}, "divider2"));
        columns.map(function (col) {
            if (!col.show) {
                options.push(_jsx(MenuItem, __assign({ sx: {
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#404040",
                        lineHeight: "20px",
                    }, value: col["column_name"] }, { children: col["human_friendly_column_name"] }), col["column_name"]));
            }
        });
        return options;
    };
    var handleChangeColumn = function (e, index) {
        var newChangeColumn = JSON.parse(JSON.stringify(changeColumn));
        newChangeColumn[index] = true;
        setChangeColumn(newChangeColumn);
        var copySortRows = JSON.parse(JSON.stringify(sortRows));
        copySortRows[index] = __assign(__assign({}, copySortRows[index]), { field_name: e.target.value });
        setSortRows(copySortRows);
    };
    var orderOption = function () {
        var options = [];
        options.push(_jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: "asc" }, { children: "Ascending" }), "asc"));
        options.push(_jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: "desc" }, { children: "Descending" }), "desc"));
        return options;
    };
    var handleChangeOrder = function (e, index) {
        var copySortRows = JSON.parse(JSON.stringify(sortRows));
        copySortRows[index] = __assign(__assign({}, copySortRows[index]), { order: e.target.value });
        setSortRows(copySortRows);
        var newChangeColumn = JSON.parse(JSON.stringify(changeColumn));
        newChangeColumn[index] = true;
        setChangeColumn(newChangeColumn);
    };
    var handleSave = function () {
        var copySortRows = JSON.parse(JSON.stringify(sortRows));
        var newQueryOption = JSON.parse(JSON.stringify(queryOptions));
        newQueryOption["sort"] = copySortRows;
        queryOptions.sort.forEach(function (sort) {
            if (sort.agg_name) {
                newQueryOption["sort"].push({
                    agg_name: sort.agg_name,
                    order: sort.order,
                });
            }
        });
        if (restoreState)
            setRestoreState(false);
        setQueryOptions(newQueryOption);
        setSortApplied(true);
        setPage(1);
        handleClose();
    };
    var handleReset = function () {
        var newSort = [
            {
                field_name: columns[0].column_name,
                order: "asc",
            },
        ];
        setSortRows(newSort);
        setCurrentNumSorts(1);
        if (sortApplied) {
            setSortApplied(false);
        }
        setChangeColumn([false]);
        var newQueryOption = JSON.parse(JSON.stringify(queryOptions));
        newQueryOption["sort"] = [];
        setPage(1);
        setQueryOptions(newQueryOption);
        handleClose();
    };
    var addSortRow = function () {
        var row = [];
        var _loop_1 = function (i) {
            row.push(_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center", margin: "10px 0" } }, { children: [_jsx("span", __assign({ onClick: function (e) { return handleRemoveSort(e, i); }, tabIndex: 0, onKeyDown: function (event) {
                            event.key == "Enter" && handleRemoveSort(event, i);
                        }, "aria-label": "Remove", style: { cursor: "pointer", margin: "0 5px" }, "data-id": i }, { children: "\u2715" })), i == 0 && (_jsx(Typography, __assign({ sx: { margin: "0 15px 0 2px", fontSize: "14px" } }, { children: "Sort by" }))), i != 0 && (_jsx(Typography, __assign({ sx: { margin: "0 10px 0 2px", fontSize: "14px" } }, { children: "Then by" }))), _jsx(Tooltip, __assign({ title: variableToFriendlyColumn[sortRows[i].field_name], open: tooltipOpen == i }, { children: _jsx(Box, { children: _jsx(Select, __assign({ onChange: function (e) { return handleChangeColumn(e, i); }, sx: {
                                    margin: "0 10px",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    maxWidth: "170px",
                                }, value: sortRows[i].field_name, onMouseEnter: function () { return handleTooltip(i); }, onMouseLeave: function () { return handleTooltip(-1); }, onOpen: function () { return handleTooltip(-1); } }, { children: colDropdown() })) }) })), _jsx(Select, __assign({ onChange: function (e) { return handleChangeOrder(e, i); }, sx: {
                            margin: "0 10px",
                            fontSize: "14px",
                            fontWeight: "400",
                            minWidth: "125px",
                        }, value: sortRows[i].order }, { children: orderOption() }))] }), i));
        };
        for (var i = 0; i < sortRows.length; i++) {
            _loop_1(i);
        }
        return row;
    };
    var handleCloseSnack = function () {
        setDisable(false);
    };
    return (_jsx(ClickAwayListener, __assign({ onClickAway: handleClose, mouseEvent: "onMouseUp" }, { children: _jsxs(Box, __assign({ sx: { padding: "17px  17px 0px 17px", width: "227px" } }, { children: [_jsx(Typography, __assign({ sx: {
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#595959",
                        marginBottom: "6px",
                    } }, { children: "Sort" })), _jsxs(Typography, __assign({ onClick: handleClick, variant: "body2", role: "button", onKeyDown: function (event) {
                        event.key == "Enter" && handleClick(event);
                    }, tabIndex: 0, sx: {
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "12px 17px",
                        boxSizing: "border-box",
                        cursor: "pointer",
                        width: "227px",
                        height: "44px",
                        alignItems: "center",
                        border: "1px solid #949494",
                        borderRadius: "3px",
                        fontWeight: "400",
                        "&:hover": {
                            boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(0deg 0% 74%) 0 0 0 3px",
                        },
                        "&:active": {
                            boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(210deg 100% 35%) 0 0 0 2px, hsl(210deg 100% 85%) 0 0 0 4px",
                        },
                        "&:focus-visible": {
                            boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(210deg 100% 35%) 0 0 0 2px, hsl(210deg 100% 85%) 0 0 0 4px",
                            outline: "none",
                        },
                    } }, { children: [sortCount == 0 ? (_jsx(Typography, __assign({ component: "span", variant: "body2", sx: { color: "#595959" } }, { children: "Select Sort" }))) : (_jsx(Typography, __assign({ component: "span", variant: "body2", sx: { color: "#404040" } }, { children: sortCount == 1 ? "1 sort" : "".concat(sortCount, " sorts") }))), _jsx(SortIcon, { sx: { color: "#595959" } })] })), _jsx(Popper, __assign({ id: id, open: open, anchorEl: anchorEl, placement: "auto-start", modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 20],
                            },
                        },
                    ], sx: { zIndex: "1000" } }, { children: _jsx(FocusTrap, __assign({ open: open }, { children: _jsx(Box, __assign({ sx: {
                                border: "1px solid #EDEDED",
                                minWidth: "450px",
                                background: "#FFFFFF",
                                boxShadow: "1",
                            }, tabIndex: -1 }, { children: _jsxs(Box, __assign({ sx: { margin: "24px" } }, { children: [_jsxs(Box, __assign({ sx: {
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0 2px",
                                        } }, { children: [_jsx(Typography, __assign({ sx: {
                                                    color: "#272727",
                                                    fontWeight: "400",
                                                    fontSize: "16px",
                                                    marginBottom: 0,
                                                    alignSelf: "center",
                                                } }, { children: "Sort" })), _jsx(CloseButton, { size: "small", "aria-label": "close", onClick: handleClose })] })), _jsx(Box, __assign({ sx: { height: "120px", overflowY: "auto" }, className: "sortRows" }, { children: addSortRow() })), _jsxs(Box, __assign({ style: {
                                            margin: "25px 0",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }, className: "addFilter" }, { children: [_jsxs(Box, __assign({ onClick: handleAddSort, tabIndex: 0, onKeyDown: function (event) {
                                                    event.key == "Enter" && handleAddSort();
                                                }, sx: { display: "flex", cursor: "pointer" } }, { children: [_jsx("span", __assign({ style: { alignSelf: "center", margin: "0 10px" } }, { children: _jsx("img", { src: plus, "aria-hidden": "true", alt: "plus" }) })), _jsx(Typography, __assign({ style: {
                                                            color: "#004080",
                                                            fontWeight: 500,
                                                            fontSize: "16px",
                                                            alignSelf: "center",
                                                            marginBottom: "0",
                                                        } }, { children: "Add level" }))] })), _jsxs(Box, __assign({ sx: {
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    width: "185px",
                                                    marginRight: "10px",
                                                } }, { children: [_jsx(Button, __assign({ onClick: handleReset, variant: "contained", color: "secondary" }, { children: "Reset" })), _jsx(Button, __assign({ onClick: handleSave, variant: "contained", color: "primary" }, { children: "Apply" }))] }))] }))] })) })) })) })), _jsx(Snackbar, __assign({ open: disable, autoHideDuration: 6000, onClose: handleCloseSnack }, { children: _jsx(Alert, __assign({ variant: "filled", onClose: handleCloseSnack, severity: "error" }, { children: "Select some columns before applying sort" })) }))] })) })));
};
export default SortMenu;
