import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import unauthorized from "../../Assets/images/403.svg";
import { ErrorPageLayout } from "../Layouts/ErrorPageLayout";
import Desc from "./Desc";
import Title from "./Title";
var Unauthorized = function (_a) {
    var url = _a.url, msg = _a.msg;
    return (_jsxs(ErrorPageLayout, { children: [_jsx(Title, { children: "Unauthorized" }), _jsx(Desc, { src: unauthorized, alt: "Warning", desc: msg, url: url })] }));
};
export default Unauthorized;
