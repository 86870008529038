var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { isJsonParsable } from "../../../../Utils/util";
import { IA_URL } from "../../../../Utils/constants";
import ReactJson from "@microlink/react-json-view";
import dayjs from "dayjs";
import { Typography } from "@inspera/component-library";
import { isArray } from "lodash";
export var getAlignment = function (type) {
    if (type == "text")
        return "left";
    else if (type == "long")
        return "right";
    else
        return "left";
};
export var formatData = function (val, column, row, domain) {
    if (typeof val === "undefined" || val == null)
        return (_jsx(Typography, __assign({ sx: { color: "gray", fontWeight: 400, fontStyle: "italic" } }, { children: "null" })));
    var errorUrl = 0;
    var ia_url;
    if (!IA_URL) {
        ia_url = new URL("https://iad.inspera.com");
    }
    else
        ia_url = new URL(IA_URL);
    var baseUrl = domain == ia_url.hostname ? IA_URL : "https://" + domain;
    if (column.url_template) {
        var path = column.url_template.url;
        //First step: If path contains {column}, replace it with respective column value
        var regexp = /{([^}]+)}/g;
        var varPath = [];
        var curMatch = void 0;
        //currMatch will have [{abc},abc] on every match and null at last(no match)
        while ((curMatch = regexp.exec(path))) {
            varPath.push(curMatch[1]);
        }
        //This template url has {column} that has to be replaced with respective column values
        if (varPath.length > 0) {
            path = path.replace(/[{}]/g, ""); //Remove curly braces
            for (var _i = 0, varPath_1 = varPath; _i < varPath_1.length; _i++) {
                var replacePath = varPath_1[_i];
                if (row[replacePath])
                    path = path.replace(replacePath, row[replacePath]);
                else
                    errorUrl = 1;
            }
        }
        var url = new URL(baseUrl + path);
        //Check if url_template has params, if it does,
        //Add query params to the link
        if (column.url_template.params) {
            for (var _a = 0, _b = Object.entries(column.url_template.params); _a < _b.length; _a++) {
                var _c = _b[_a], param = _c[0], param_val = _c[1];
                var value = param_val.replace(/\{|\}/gi, "");
                if (row[value])
                    url.searchParams.append(param, row[value]);
                else
                    url.searchParams.append(param, value);
            }
        }
        return (_jsx(_Fragment, { children: errorUrl ? (_jsx(_Fragment, { children: String(val) })) : (_jsx("a", __assign({ href: url.href, target: "_blank", rel: "noopener noreferrer", style: {
                    color: "#272727",
                    textDecoration: "underline",
                    textDecorationThickness: "1.9px",
                    textUnderlineOffset: "2px",
                    fontWeight: "500",
                } }, { children: val }))) }));
    }
    var collapsed = true;
    var parseData;
    switch (isJsonParsable(val)) {
        case "a":
            if (column.column_data_type == "date")
                val = dayjs(val).format("DD.MM.YYYY hh:mm:ss.SSS A");
            return _jsx(_Fragment, { children: String(val) });
        case "b":
            return (_jsx(ReactJson, { src: val, collapsed: isArray(val) && val.length <= 3 ? false : true, collapseStringsAfterLength: 15, name: false, displayDataTypes: false, 
                //@ts-ignore
                displayArrayKey: false, groupArraysAfterLength: 0, style: {
                    minWidth: "200px",
                } }));
        case "c":
            parseData = JSON.parse(val);
            return (_jsx(ReactJson, { src: parseData, collapsed: isArray(val) && val.length <= 3 ? false : true, collapseStringsAfterLength: 13, name: false, displayDataTypes: false, 
                //@ts-ignore
                displayArrayKey: false, groupArraysAfterLength: 0, style: {
                    minWidth: "200px",
                } }));
        default:
            return _jsx(_Fragment, { children: String(val) });
    }
};
