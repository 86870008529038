import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GlobalStyles } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { createRoot } from "react-dom/client";
import React from "react";
var container = document.getElementById("root");
var root = createRoot(container);
root.render(_jsxs(React.StrictMode, { children: [_jsx(GlobalStyles, { styles: {
                body: { margin: 0 },
            } }), _jsx(BrowserRouter, { children: _jsx(App, {}) })] }));
