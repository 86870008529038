var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useReducer } from "react";
import { Box, Typography, Popper, ClickAwayListener, Snackbar, Alert, Autocomplete, } from "@mui/material";
import FocusTrap from "@mui/base/TrapFocus";
import { CloseButton, Select, Button, Tooltip, TextField, } from "@inspera/component-library";
import plus from "../../../../Assets/images/plus.svg";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { ACTIONS, } from "../../../../Model/model";
import useStore from "../../../../Store/store";
import { createJsonFilters } from "./ESFilterToJson";
import { createESFilter } from "./jsonToESFilter";
import getCriteriaOptions from "./CriteriaOptions";
import { colDropdown, getAutoCompleteOptions, getColumnIndex, handleAddFilter, handleChangeColumn, handleChangeCriteria, handleChangeValue, handleInputChange, handleRemoveFilter, initialFilterState, showFilterError, } from "./utils";
import { hashCode } from "../../../../Utils/util";
import _ from "lodash";
import { filterCheck } from "./filterCheck";
var reducer = function (state, action) {
    switch (action.type) {
        case ACTIONS.APPLY:
            return __assign(__assign({}, state), { apply: action.payload });
        case ACTIONS.ERROR:
            return __assign(__assign({}, state), { error: action.payload });
        case ACTIONS.ERROR_MSG:
            return __assign(__assign({}, state), { errorMsg: action.payload });
        case ACTIONS.FILTER_COUNT:
            return __assign(__assign({}, state), { filterCount: action.payload });
        case ACTIONS.ROW_TYPE:
            return __assign(__assign({}, state), { rowType: action.payload });
        case ACTIONS.FILTER_ROWS:
            return __assign(__assign({}, state), { filterRows: action.payload });
        case ACTIONS.OPEN:
            return __assign(__assign({}, state), { open: action.payload });
        case ACTIONS.CHANGE_COLUMN:
            return __assign(__assign({}, state), { changeColumn: action.payload });
        case ACTIONS.TOOLTIP_OPEN:
            return __assign(__assign({}, state), { tooltipOpen: action.payload });
        case ACTIONS.APPLIED_FILTER_COUNT:
            return __assign(__assign({}, state), { appliedFilterCount: action.payload });
        default:
            return state;
    }
};
var FilterMenu = function (_a) {
    var variableToFriendlyColumn = _a.variableToFriendlyColumn, variant = _a.variant;
    var _b = useStore(), queryOptions = _b.queryOptions, setQueryOptions = _b.setQueryOptions, setPage = _b.setPage, current = _b.current, visibleColumns = _b.visibleColumns, deepLinkQueryOption = _b.deepLinkQueryOption, token = _b.token, restoreState = _b.restoreState, columns = _b.columns, setRestoreState = _b.setRestoreState, userId = _b.userId, itemBankId = _b.itemBankId, dataViewQueryOptions = _b.dataViewQueryOptions, setDataViewQueryOptions = _b.setDataViewQueryOptions;
    var initialState = initialFilterState(columns);
    var _c = useReducer(reducer, initialState), state = _c[0], dispatchState = _c[1];
    var _d = useState(false), disable = _d[0], setDisable = _d[1];
    // {col1: [suggestion1,suggestion2],col2:[suggestion4,suggestion5]}
    var _e = useState({}), autoCompleteOptions = _e[0], setAutoCompleteOptions = _e[1];
    var _f = useState(null), anchorEl = _f[0], setAnchorEl = _f[1];
    //stores the row for which this label has to be displayed else -1
    var _g = useState(-1), noSuggestionLabel = _g[0], setNoSuggestionLabel = _g[1];
    var handleTooltip = function (index) {
        //if -1 then close the tooltip or open the particular row's index tooltip
        dispatchState({ type: ACTIONS.TOOLTIP_OPEN, payload: index });
    };
    var handleClick = function (event) {
        if (visibleColumns == 0) {
            setDisable(true);
            return;
        }
        var copyFilterRows = _.cloneDeep(state.filterRows);
        copyFilterRows.map(function (filter, index) {
            //If user hasn't changed the column, change this to First column in the view/table, and criteria,value as default
            if (!state.changeColumn[index]) {
                filter.column = columns[0].column_name;
                filter.criteria = "is";
                filter.value = "";
            }
        });
        dispatchState({ type: ACTIONS.FILTER_ROWS, payload: copyFilterRows });
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    var open = Boolean(anchorEl);
    var handleClose = function () {
        setAnchorEl(null);
    };
    useEffect(function () {
        var _a, _b;
        if ((current === null || current === void 0 ? void 0 : current.viewDef) != null || restoreState || (current === null || current === void 0 ? void 0 : current.preset))
            return;
        var dataViewQueryOptionsCopy;
        if (current === null || current === void 0 ? void 0 : current.dataView) {
            if (dataViewQueryOptions)
                dataViewQueryOptionsCopy = dataViewQueryOptions;
        }
        getAutoCompleteOptions(columns[0]["column_name"], columns, token, variant == "normal"
            ? (_a = current === null || current === void 0 ? void 0 : current.source) === null || _a === void 0 ? void 0 : _a.data_source_url_slug
            : (_b = current === null || current === void 0 ? void 0 : current.dataView) === null || _b === void 0 ? void 0 : _b.data_sources[0], {}, setAutoCompleteOptions, dataViewQueryOptionsCopy);
        dispatchState({ type: ACTIONS.ERROR, payload: false });
        dispatchState({
            type: ACTIONS.FILTER_ROWS,
            payload: [
                {
                    column: columns[0]["column_name"],
                    criteria: "is",
                    value: "",
                },
            ],
        });
        dispatchState({
            type: ACTIONS.FILTER_COUNT,
            payload: 1,
        });
        dispatchState({
            type: ACTIONS.ROW_TYPE,
            payload: [columns[0]["column_data_type"]],
        });
        dispatchState({
            type: ACTIONS.CHANGE_COLUMN,
            payload: [false],
        });
        dispatchState({
            type: ACTIONS.APPLIED_FILTER_COUNT,
            payload: 0,
        });
    }, [current === null || current === void 0 ? void 0 : current.source]);
    useEffect(function () {
        var _a, _b, _c;
        var options;
        var dataSource;
        var hashUserId = hashCode(String(userId));
        var savedState = localStorage.getItem("options".concat(hashUserId));
        if (restoreState && savedState) {
            options = JSON.parse(savedState).parameters;
            dataSource = (_a = JSON.parse(savedState).current.source) === null || _a === void 0 ? void 0 : _a.data_source_url_slug;
            setRestoreState(false);
        }
        else {
            options = (_b = current === null || current === void 0 ? void 0 : current.viewDef) === null || _b === void 0 ? void 0 : _b.parameters;
            dataSource = (_c = current === null || current === void 0 ? void 0 : current.source) === null || _c === void 0 ? void 0 : _c.data_source_url_slug;
        }
        dispatchState({ type: ACTIONS.ERROR, payload: false });
        setAutoCompleteOptions({});
        if (options == null || options.body.filters.length == 0) {
            var firstRow = [
                {
                    column: columns[0]["column_name"],
                    criteria: "is",
                    value: "",
                },
            ];
            dispatchState({ type: ACTIONS.FILTER_ROWS, payload: firstRow });
            dispatchState({
                type: ACTIONS.CHANGE_COLUMN,
                payload: [false],
            });
            dispatchState({ type: ACTIONS.FILTER_COUNT, payload: 1 });
            dispatchState({
                type: ACTIONS.APPLIED_FILTER_COUNT,
                payload: 0,
            });
            getAutoCompleteOptions(columns[0]["column_name"], columns, token, dataSource, autoCompleteOptions, setAutoCompleteOptions);
            return;
        }
        var viewFilter = options.body.filters;
        var newFilterRows = createJsonFilters(viewFilter);
        var newFilterRowType = [];
        newFilterRows.forEach(function (row) {
            var col = row.column;
            var colIndex = getColumnIndex(col, columns);
            newFilterRowType.push(columns[colIndex].column_data_type);
            getAutoCompleteOptions(col, columns, token, dataSource, autoCompleteOptions, setAutoCompleteOptions);
        });
        dispatchState({ type: ACTIONS.FILTER_ROWS, payload: newFilterRows });
        dispatchState({ type: ACTIONS.ROW_TYPE, payload: newFilterRowType });
        dispatchState({
            type: ACTIONS.FILTER_COUNT,
            payload: newFilterRows.length,
        });
        dispatchState({
            type: ACTIONS.APPLIED_FILTER_COUNT,
            payload: newFilterRows.length,
        });
        var newChangeColumn = new Array(newFilterRows.length).fill(true);
        dispatchState({
            type: ACTIONS.CHANGE_COLUMN,
            payload: newChangeColumn,
        });
    }, [current === null || current === void 0 ? void 0 : current.viewDef]);
    //useEffect for setting presets
    useEffect(function () {
        var _a;
        if (current === null || current === void 0 ? void 0 : current.preset) {
            var presetData_1 = current.preset;
            if (((_a = presetData_1.parameters.body) === null || _a === void 0 ? void 0 : _a.filters.length) == 0) {
                var firstRow = [
                    {
                        column: columns[0]["column_name"],
                        criteria: "is",
                        value: "",
                    },
                ];
                dispatchState({ type: ACTIONS.FILTER_ROWS, payload: firstRow });
                dispatchState({
                    type: ACTIONS.CHANGE_COLUMN,
                    payload: [false],
                });
                dispatchState({ type: ACTIONS.FILTER_COUNT, payload: 1 });
                dispatchState({
                    type: ACTIONS.APPLIED_FILTER_COUNT,
                    payload: 0,
                });
                getAutoCompleteOptions(columns[0]["column_name"], columns, token, presetData_1.parameters.data_source, autoCompleteOptions, setAutoCompleteOptions);
            }
            else {
                var newFilterRows = createJsonFilters(presetData_1.parameters.body.filters);
                var newFilterRowType_1 = [];
                newFilterRows.forEach(function (row) {
                    var col = row.column;
                    var colIndex = getColumnIndex(col, columns);
                    newFilterRowType_1.push(columns[colIndex].column_data_type);
                    getAutoCompleteOptions(col, columns, token, presetData_1.parameters.data_source, autoCompleteOptions, setAutoCompleteOptions);
                });
                dispatchState({ type: ACTIONS.FILTER_ROWS, payload: newFilterRows });
                dispatchState({ type: ACTIONS.ROW_TYPE, payload: newFilterRowType_1 });
                dispatchState({
                    type: ACTIONS.FILTER_COUNT,
                    payload: newFilterRows.length,
                });
                dispatchState({
                    type: ACTIONS.APPLIED_FILTER_COUNT,
                    payload: newFilterRows.length,
                });
                var newChangeColumn = new Array(newFilterRows.length).fill(true);
                dispatchState({
                    type: ACTIONS.CHANGE_COLUMN,
                    payload: newChangeColumn,
                });
            }
        }
    }, [current === null || current === void 0 ? void 0 : current.preset]);
    //Function
    //When 1 filter is present, the column data type is set to the 1st column by default
    //When column order is changed, column data type should be set to the new 1st column
    if (state.filterRows.length == 1) {
        var newRowType = _.cloneDeep(state.rowType);
        var newFilterRows = _.cloneDeep(state.filterRows);
        var columnIndex = getColumnIndex(newFilterRows[0].column, columns);
        //If column hasn't updated when source is changed, skip this
        if (columnIndex != -1) {
            if (columns[columnIndex].column_data_type != state.rowType[0]) {
                newRowType[0] = columns[columnIndex].column_data_type;
                newFilterRows[0].criteria = ["metadata", "object"].includes(columns[columnIndex].column_data_type)
                    ? "is null"
                    : "is";
                dispatchState({
                    type: ACTIONS.ROW_TYPE,
                    payload: newRowType,
                });
                dispatchState({ type: ACTIONS.FILTER_ROWS, payload: newFilterRows });
            }
        }
    }
    useEffect(function () {
        if ((deepLinkQueryOption === null || deepLinkQueryOption === void 0 ? void 0 : deepLinkQueryOption.filters) &&
            deepLinkQueryOption.filters.length > 0) {
            var deepLinkFilter = deepLinkQueryOption.filters;
            var newFilterRows = createJsonFilters(deepLinkFilter);
            var newFilterRowType_2 = [];
            newFilterRows.forEach(function (row) {
                var col = row.column;
                var colIndex = getColumnIndex(col, columns);
                newFilterRowType_2.push(columns[colIndex].column_data_type);
            });
            dispatchState({ type: ACTIONS.FILTER_ROWS, payload: newFilterRows });
            dispatchState({ type: ACTIONS.ROW_TYPE, payload: newFilterRowType_2 });
            dispatchState({
                type: ACTIONS.FILTER_COUNT,
                payload: newFilterRows.length,
            });
            dispatchState({
                type: ACTIONS.APPLIED_FILTER_COUNT,
                payload: newFilterRows.length,
            });
            var newChangeColumn = new Array(newFilterRows.length).fill(true);
            dispatchState({
                type: ACTIONS.CHANGE_COLUMN,
                payload: newChangeColumn,
            });
        }
    }, [deepLinkQueryOption]);
    var criteriaOption = function (i) {
        var options = getCriteriaOptions(state.rowType[i], state.filterRows[i].column);
        return options;
    };
    var PopperMy = function (props) {
        return (_jsx(Popper, __assign({}, props, { style: { width: 250 }, placement: "bottom-start" })));
    };
    var handleReset = function () {
        var newFilter = [
            {
                column: columns[0]["column_name"],
                criteria: "is",
                value: "",
            },
        ];
        dispatchState({ type: ACTIONS.FILTER_ROWS, payload: newFilter });
        dispatchState({
            type: ACTIONS.ROW_TYPE,
            payload: [columns[0]["column_data_type"]],
        });
        dispatchState({ type: ACTIONS.ERROR, payload: false });
        dispatchState({ type: ACTIONS.ERROR_MSG, payload: "" });
        dispatchState({ type: ACTIONS.FILTER_COUNT, payload: 1 });
        dispatchState({
            type: ACTIONS.APPLIED_FILTER_COUNT,
            payload: 0,
        });
        if (queryOptions.filters.length == 0) {
            handleClose();
            return;
        }
        if (variant == "normal") {
            var newQueryOption = _.cloneDeep(queryOptions);
            newQueryOption["filters"] = [];
            setQueryOptions(newQueryOption);
        }
        else {
            //For dataviews
            var newDataQueryOption = _.cloneDeep(dataViewQueryOptions);
            if (newDataQueryOption) {
                newDataQueryOption.body.filters = [];
                setDataViewQueryOptions(newDataQueryOption);
            }
        }
        setPage(1);
        dispatchState({
            type: ACTIONS.CHANGE_COLUMN,
            payload: [false],
        });
        if (state.apply)
            dispatchState({ type: ACTIONS.APPLY, payload: false });
        handleClose();
    };
    var handleSave = function () {
        var copyFilterRows = _.cloneDeep(state.filterRows);
        for (var _i = 0, copyFilterRows_1 = copyFilterRows; _i < copyFilterRows_1.length; _i++) {
            var obj = copyFilterRows_1[_i];
            obj.value = obj.value.toString().trim();
        }
        var errorMsg = filterCheck(copyFilterRows, columns, itemBankId);
        if (errorMsg.length > 0) {
            dispatchState({ type: ACTIONS.ERROR, payload: true });
            dispatchState({ type: ACTIONS.ERROR_MSG, payload: errorMsg });
            return;
        }
        var filterArray = createESFilter(copyFilterRows);
        dispatchState({ type: ACTIONS.APPLY, payload: true });
        dispatchState({ type: ACTIONS.ERROR, payload: false });
        dispatchState({ type: ACTIONS.ERROR_MSG, payload: "" });
        dispatchState({ type: ACTIONS.OPEN, payload: false });
        dispatchState({
            type: ACTIONS.APPLIED_FILTER_COUNT,
            payload: copyFilterRows.length,
        });
        if (variant == "normal") {
            var newQueryOption = _.cloneDeep(queryOptions);
            newQueryOption["filters"] = filterArray;
            setQueryOptions(newQueryOption);
        }
        else {
            //For dataviews
            var newDataQueryOption = _.cloneDeep(dataViewQueryOptions);
            if (newDataQueryOption) {
                newDataQueryOption.body.filters = filterArray;
                setDataViewQueryOptions(newDataQueryOption);
            }
        }
        if (restoreState)
            setRestoreState(false);
        setPage(1);
        handleClose();
    };
    var addRow = function () {
        if (!state.filterRows)
            return;
        var row = [];
        var _loop_1 = function (i) {
            row.push(_jsxs(Box, __assign({ sx: {
                    display: "flex",
                    alignItems: "center",
                    margin: "20px 0",
                    padding: "0px 3px",
                } }, { children: [_jsx("span", __assign({ onClick: function (e) {
                            return handleRemoveFilter(e, i, columns, state, dispatchState);
                        }, "aria-label": "Remove", tabIndex: 0, onKeyDown: function (e) {
                            e.key == "Enter" &&
                                handleRemoveFilter(e, i, columns, state, dispatchState);
                        }, style: { cursor: "pointer", margin: "0 5px" } }, { children: "\u2715" })), _jsx(Tooltip, __assign({ title: variableToFriendlyColumn[state.filterRows[i].column], open: state.tooltipOpen == i }, { children: _jsx(Box, __assign({ sx: { maxWidth: "165px" } }, { children: _jsx(Select, __assign({ value: state.filterRows[i].column, onChange: function (e) {
                                    var _a, _b;
                                    return handleChangeColumn(e, i, columns, state, dispatchState, token, variant == "normal"
                                        ? (_a = current === null || current === void 0 ? void 0 : current.source) === null || _a === void 0 ? void 0 : _a.data_source_url_slug
                                        : (_b = current === null || current === void 0 ? void 0 : current.dataView) === null || _b === void 0 ? void 0 : _b.data_sources[0], autoCompleteOptions, setAutoCompleteOptions, variant == "dataview"
                                        ? dataViewQueryOptions !== null && dataViewQueryOptions !== void 0 ? dataViewQueryOptions : undefined
                                        : undefined);
                                }, sx: {
                                    margin: "0 5px",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    maxWidth: "155px",
                                }, onMouseEnter: function () { return handleTooltip(i); }, onMouseLeave: function () { return handleTooltip(-1); }, onOpen: function () { return handleTooltip(-1); } }, { children: colDropdown(columns) })) })) })), _jsx(Box, { children: _jsx(Select, __assign({ value: state.filterRows[i].criteria, onChange: function (e) { return handleChangeCriteria(e, i, state, dispatchState); }, sx: {
                                margin: "0 5px",
                                fontSize: "14px",
                                fontWeight: "400",
                            } }, { children: criteriaOption(i) })) }), _jsxs(Box, __assign({ sx: { position: "relative" } }, { children: [!autoCompleteOptions[state.filterRows[i].column] &&
                                state.filterRows[i].value == "" &&
                                noSuggestionLabel == i && (_jsx(Typography, __assign({ sx: {
                                    position: "absolute",
                                    bottom: "0px",
                                    marginBottom: "-18px",
                                    color: "#595959",
                                    fontSize: "12px",
                                } }, { children: "No suggestions" }))), state.filterRows[i].criteria != "is null" &&
                                state.filterRows[i].criteria != "is not null" && (_jsx(Autocomplete, { placeholder: "Value..", sx: { height: "40px", padding: "0px" }, componentsProps: {
                                    paper: {
                                        sx: {
                                            width: "fit-content",
                                        },
                                    },
                                }, "aria-label": state.filterRows[i].value, value: state.filterRows[i].value, onChange: function (e, val) {
                                    return handleChangeValue(e, i, columns, state, dispatchState, val);
                                }, onInputChange: function (e) {
                                    return handleInputChange(e, i, state, dispatchState);
                                }, readOnly: false, options: autoCompleteOptions[state.filterRows[i].column]
                                    ? autoCompleteOptions[state.filterRows[i].column]
                                    : [], renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { sx: {
                                        "& .MuiOutlinedInput-root": {
                                            padding: "0px",
                                        },
                                        width: "165px",
                                        padding: "0px",
                                    } }))); }, freeSolo: true, PopperComponent: PopperMy, onFocus: function () { return setNoSuggestionLabel(i); }, onBlur: function () { return setNoSuggestionLabel(-1); } }))] }))] }), i));
        };
        for (var i = 0; i < state.filterRows.length; i++) {
            _loop_1(i);
        }
        return row;
    };
    var handleCloseSnack = function () {
        setDisable(false);
    };
    return (_jsx(ClickAwayListener, __assign({ onClickAway: handleClose, mouseEvent: "onMouseUp" }, { children: _jsxs(Box, __assign({ sx: {
                padding: "17px  17px 0px 17px",
                width: "227px",
            } }, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                        color: "#595959",
                        marginBottom: "6px",
                    } }, { children: "Filter" })), _jsxs(Typography, __assign({ onClick: handleClick, variant: "body2", role: "button", onKeyDown: function (event) {
                        event.key == "Enter" && handleClick(event);
                    }, tabIndex: 0, sx: {
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "12px 17px",
                        boxSizing: "border-box",
                        cursor: "pointer",
                        width: "227px",
                        height: "44px",
                        alignItems: "center",
                        border: "1px solid #949494",
                        fontWeight: "400",
                        borderRadius: "3px",
                        "&:hover": {
                            boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(0deg 0% 74%) 0 0 0 3px",
                        },
                        "&:focus-visible": {
                            boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(210deg 100% 35%) 0 0 0 2px, hsl(210deg 100% 85%) 0 0 0 4px",
                            outline: "none",
                        },
                    } }, { children: [state.appliedFilterCount == 0 ? (_jsx(Typography, __assign({ component: "span", variant: "body2", sx: { color: "#595959" } }, { children: "Select Filter" }))) : (_jsx(Typography, __assign({ component: "span", variant: "body2", sx: { color: "#404040" } }, { children: state.appliedFilterCount == 1
                                ? "1 filter"
                                : "".concat(state.appliedFilterCount, " filters") }))), _jsx(FilterAltIcon, { sx: { color: "#595959" } })] })), _jsx(Popper, __assign({ open: open, anchorEl: anchorEl, placement: "right", modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [10, 20],
                            },
                        },
                    ], sx: { zIndex: "1000" } }, { children: _jsx(FocusTrap, __assign({ open: open }, { children: _jsx(Box, __assign({ sx: {
                                border: "1px solid #EDEDED",
                                background: "#FFFFFF",
                                boxShadow: "1",
                            }, tabIndex: -1 }, { children: _jsxs(Box, __assign({ sx: { margin: "24px" } }, { children: [_jsxs(Box, __assign({ sx: {
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0 2px",
                                        } }, { children: [_jsx(Typography, __assign({ sx: {
                                                    color: "#272727",
                                                    marginBottom: 0,
                                                    alignSelf: "center",
                                                } }, { children: "Filter" })), _jsx(CloseButton, { size: "small", "aria-label": "close", onClick: handleClose })] })), _jsx(Box, __assign({ sx: { maxHeight: "70vh", overflowY: "scroll" }, className: "filterRows" }, { children: addRow() })), _jsx(Box, __assign({ sx: {
                                            minHeight: "34px",
                                            maxHeight: "95px",
                                            overflowY: "auto",
                                            margin: "10px 20px",
                                        } }, { children: state.error && showFilterError(state) })), _jsxs(Box, __assign({ style: {
                                            margin: "10px 0 15px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }, className: "addFilter" }, { children: [_jsxs(Box, __assign({ onClick: function () {
                                                    var _a, _b;
                                                    return handleAddFilter(columns, state, dispatchState, token, variant == "normal"
                                                        ? (_a = current === null || current === void 0 ? void 0 : current.source) === null || _a === void 0 ? void 0 : _a.data_source_url_slug
                                                        : (_b = current === null || current === void 0 ? void 0 : current.dataView) === null || _b === void 0 ? void 0 : _b.data_sources[0], autoCompleteOptions, setAutoCompleteOptions, dataViewQueryOptions !== null && dataViewQueryOptions !== void 0 ? dataViewQueryOptions : undefined);
                                                }, onKeyDown: function (e) {
                                                    var _a, _b;
                                                    return e.key == "Enter" &&
                                                        handleAddFilter(columns, state, dispatchState, token, variant == "normal"
                                                            ? (_a = current === null || current === void 0 ? void 0 : current.source) === null || _a === void 0 ? void 0 : _a.data_source_url_slug
                                                            : (_b = current === null || current === void 0 ? void 0 : current.dataView) === null || _b === void 0 ? void 0 : _b.data_sources[0], autoCompleteOptions, setAutoCompleteOptions);
                                                }, tabIndex: 0, sx: { display: "flex", cursor: "pointer" } }, { children: [_jsx("span", __assign({ style: { alignSelf: "center", margin: "0 10px" } }, { children: _jsx("img", { "aria-hidden": "true", src: plus, alt: "plus icon" }) })), _jsx(Typography, __assign({ sx: {
                                                            color: "#004080",
                                                            fontWeight: 500,
                                                            fontSize: "16px",
                                                            alignSelf: "center",
                                                            margin: "0px",
                                                        } }, { children: "Add filter" }))] })), _jsxs(Box, __assign({ sx: {
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    width: "185px",
                                                    marginRight: "10px",
                                                } }, { children: [_jsx(Button, __assign({ onClick: handleReset, variant: "contained", color: "secondary" }, { children: "Reset" })), _jsx(Button, __assign({ onClick: handleSave, variant: "contained", color: "primary" }, { children: "Apply" }))] }))] }))] })) })) })) })), _jsx(Snackbar, __assign({ open: disable, autoHideDuration: 6000, onClose: handleCloseSnack }, { children: _jsx(Alert, __assign({ variant: "filled", onClose: handleCloseSnack, severity: "error" }, { children: "Select some columns before applying filter" })) }))] })) })));
};
export default FilterMenu;
