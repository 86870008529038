var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Checkbox } from "@inspera/component-library";
import useStore from "../../../../Store/store";
import _ from "lodash";
var VirtualColumns = function () {
    var _a = useStore(), fetchVirtualColumns = _a.fetchVirtualColumns, setFetchVirtualColumns = _a.setFetchVirtualColumns, virtualColumnsMetadata = _a.virtualColumnsMetadata, setVirtualColumnsMetadata = _a.setVirtualColumnsMetadata;
    var handleFetch = function (virtualColName) {
        if (!fetchVirtualColumns) {
            setFetchVirtualColumns(true);
        }
        var changedLiveColMetadata = _.cloneDeep(virtualColumnsMetadata);
        changedLiveColMetadata.forEach(function (col) {
            if (col.column_name == virtualColName) {
                col.show = !col.show;
            }
        });
        setVirtualColumnsMetadata(changedLiveColMetadata);
    };
    var showStatus = function (liveColName) {
        var status = false;
        virtualColumnsMetadata.forEach(function (col) {
            if (col.column_name == liveColName)
                status = col.show;
        });
        return status;
    };
    return (_jsxs(Box, __assign({ height: 285 }, { children: [_jsx(Checkbox, { checked: showStatus("item_status-current"), onChange: function () { return handleFetch("item_status-current"); }, onKeyDown: function (event) {
                    event.key == "Enter" && handleFetch("item_status-current");
                }, label: "Item status (Current) ", style: { color: "#272727", fontWeight: "400", fontSize: "16px" } }), _jsx(Checkbox, { checked: showStatus("item_lifecycle_state-current"), onChange: function () { return handleFetch("item_lifecycle_state-current"); }, onKeyDown: function (event) {
                    event.key == "Enter" && handleFetch("item_lifecycle_state-current");
                }, label: "Item lifecycle state (Current) ", style: { color: "#272727", fontWeight: "400", fontSize: "16px" } })] })));
};
export default VirtualColumns;
