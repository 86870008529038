var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Divider, MenuItem } from "@inspera/component-library";
import { ListSubheader, Typography } from "@mui/material";
import { Api } from "../../../../Api/api";
import { ACTIONS, } from "../../../../Model/model";
import _ from "lodash";
export var initialFilterState = function (columns) {
    return {
        apply: false,
        error: false,
        errorMsg: "",
        filterCount: 1,
        appliedFilterCount: 0,
        rowType: [columns[0]["column_data_type"]],
        filterRows: [
            {
                column: columns[0]["column_name"],
                criteria: "is",
                value: "",
            },
        ],
        open: false,
        changeColumn: [false],
        tooltipOpen: -1,
    };
};
export var colDropdown = function (columns) {
    var options = [];
    options.push(_jsx(ListSubheader, __assign({ sx: {
            fontSize: "12px",
            color: "#272727",
            lineHeight: "120%",
            margin: "10px 0",
        } }, { children: "Columns in your view" }), "Show"));
    options.push(_jsx(Divider, {}, "divider1"));
    columns.map(function (col) {
        if (col.show) {
            options.push(_jsx(MenuItem, __assign({ sx: {
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#404040",
                    lineHeight: "20px",
                    padding: "13px",
                }, value: col["column_name"] }, { children: col["human_friendly_column_name"] }), col["column_name"]));
        }
    });
    options.push(_jsx(ListSubheader, __assign({ sx: {
            fontSize: "12px",
            color: "#272727",
            lineHeight: "120%",
            margin: "10px 0",
        } }, { children: "Other columns" }), "Hide"));
    options.push(_jsx(Divider, {}, "divider2"));
    columns.map(function (col) {
        if (!col.show) {
            options.push(_jsx(MenuItem, __assign({ sx: {
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#404040",
                    lineHeight: "20px",
                }, value: col["column_name"] }, { children: col["human_friendly_column_name"] }), col["column_name"]));
        }
    });
    return options;
};
export var getColumnIndex = function (colName, columns) {
    for (var i = 0; i < columns.length; i++) {
        if (columns[i]["column_name"] == colName)
            return i;
    }
    return -1;
};
export var showFilterError = function (state) {
    var eMsg = state.errorMsg;
    eMsg = eMsg.trim();
    var output = eMsg.split(".").map(function (x, idx) { return (_jsx(Typography, __assign({ sx: {
            margin: "5px 0",
            color: "#d10000",
            fontSize: "13px",
            padding: "2px 5px",
            borderRadius: "4px",
            backgroundColor: "#edc0c0",
            width: "fit-content",
        } }, { children: x }), idx)); });
    output.pop();
    return output;
};
export var handleChangeColumn = function (e, index, columns, state, dispatchState, token, source, autoCompleteObject, setAutoCompleteOptions, dataViewQueryOptions) { return __awaiter(void 0, void 0, void 0, function () {
    var newChangeColumn, val, copyFilterRows, idx, flag, copyRowType;
    return __generator(this, function (_a) {
        //If the column is changed by user, mark it as changed
        if (!state.changeColumn[index]) {
            newChangeColumn = _.cloneDeep(state.changeColumn);
            newChangeColumn[index] = true;
            dispatchState({
                type: ACTIONS.CHANGE_COLUMN,
                payload: newChangeColumn,
            });
        }
        val = e.target.value;
        copyFilterRows = _.cloneDeep(state.filterRows);
        copyFilterRows[index] = __assign(__assign({}, copyFilterRows[index]), { column: val });
        idx = getColumnIndex(val, columns);
        if (idx == -1)
            return [2 /*return*/];
        flag = 1;
        //Special case for metadata objects
        if (e.target.value == "section_metadata" ||
            e.target.value == "question_metadata") {
            copyFilterRows[index] = __assign(__assign({}, copyFilterRows[index]), { criteria: "includes" });
            flag = 0;
        }
        if (flag && columns[idx]["column_data_type"] != state.rowType[index]) {
            copyFilterRows[index] = __assign(__assign({}, copyFilterRows[index]), { criteria: "is" });
        }
        dispatchState({ type: ACTIONS.FILTER_ROWS, payload: copyFilterRows });
        copyRowType = _.cloneDeep(state.rowType);
        copyRowType[index] = columns[idx]["column_data_type"];
        dispatchState({ type: ACTIONS.ROW_TYPE, payload: copyRowType });
        //Fetch auto complete options for a column, if it's marked autocomplete and not fetched previously
        getAutoCompleteOptions(val, columns, token, source, autoCompleteObject, setAutoCompleteOptions, dataViewQueryOptions !== null && dataViewQueryOptions !== void 0 ? dataViewQueryOptions : undefined);
        return [2 /*return*/];
    });
}); };
export var handleChangeCriteria = function (e, index, state, dispatchState) {
    //If the column is changed by user, mark it as changed
    if (!state.changeColumn[index]) {
        var newChangeColumn = _.cloneDeep(state.changeColumn);
        newChangeColumn[index] = true;
        dispatchState({
            type: ACTIONS.CHANGE_COLUMN,
            payload: newChangeColumn,
        });
    }
    var copyFilterRows = _.cloneDeep(state.filterRows);
    copyFilterRows[index] = __assign(__assign({}, copyFilterRows[index]), { criteria: e.target.value });
    dispatchState({ type: ACTIONS.FILTER_ROWS, payload: copyFilterRows });
};
export var handleChangeValue = function (e, index, columns, state, dispatchState, val) {
    var finalVal = val ? val : e.target.value ? e.target.value : "";
    //If the column is changed by user, mark it as changed
    if (!state.changeColumn[index]) {
        var newChangeColumn = _.cloneDeep(state.changeColumn);
        newChangeColumn[index] = true;
        dispatchState({
            type: ACTIONS.CHANGE_COLUMN,
            payload: newChangeColumn,
        });
    }
    var copyFilterRows = _.cloneDeep(state.filterRows);
    // copyFilterRows[index] = { ...copyFilterRows[index], value: e.target.value };
    copyFilterRows[index] = __assign(__assign({}, copyFilterRows[index]), { value: finalVal });
    dispatchState({ type: ACTIONS.FILTER_ROWS, payload: copyFilterRows });
};
export var handleAddFilter = function (columns, state, dispatchState, token, source, autoCompleteObject, setAutoCompleteOptions, dataViewQueryOptions) {
    var copyFilterRows = _.cloneDeep(state.filterRows);
    var columnIndex = getColumnIndex(state.filterRows[state.filterCount - 1].column, columns);
    //the new filter will have the column set as the last filter row column
    copyFilterRows[state.filterCount] = __assign(__assign({}, copyFilterRows[state.filterCount]), { column: state.filterRows[state.filterCount - 1].column, criteria: ["metadata", "object"].includes(columns[columnIndex].column_data_type)
            ? "is null"
            : "is", value: "" });
    dispatchState({ type: ACTIONS.FILTER_ROWS, payload: copyFilterRows });
    var copyRowType = _.cloneDeep(state.rowType);
    copyRowType[state.filterCount] = columns[0]["column_data_type"];
    dispatchState({ type: ACTIONS.ROW_TYPE, payload: copyRowType });
    var newChangeColumn = __spreadArray(__spreadArray([], state.changeColumn, true), [false], false);
    dispatchState({
        type: ACTIONS.CHANGE_COLUMN,
        payload: newChangeColumn,
    });
    dispatchState({
        type: ACTIONS.FILTER_COUNT,
        payload: state.filterCount + 1,
    });
    getAutoCompleteOptions(state.filterRows[state.filterCount - 1].column, columns, token, source, autoCompleteObject, setAutoCompleteOptions, dataViewQueryOptions !== null && dataViewQueryOptions !== void 0 ? dataViewQueryOptions : undefined);
};
export var handleRemoveFilter = function (e, index, columns, state, dispatchState) {
    var copyFilterRows = _.cloneDeep(state.filterRows);
    if (copyFilterRows.length == 1) {
        dispatchState({
            type: ACTIONS.FILTER_ROWS,
            payload: [
                {
                    column: columns[0]["column_name"],
                    criteria: "is",
                    value: "",
                },
            ],
        });
        dispatchState({
            type: ACTIONS.CHANGE_COLUMN,
            payload: [false],
        });
        return;
    }
    copyFilterRows.splice(index, 1);
    dispatchState({ type: ACTIONS.FILTER_ROWS, payload: copyFilterRows });
    var newRowType = _.cloneDeep(state.rowType);
    newRowType.splice(index, 1);
    dispatchState({ type: ACTIONS.ROW_TYPE, payload: newRowType });
    var newChangeColumn = _.cloneDeep(state.changeColumn);
    newChangeColumn.splice(index, 1);
    dispatchState({
        type: ACTIONS.CHANGE_COLUMN,
        payload: newChangeColumn,
    });
    dispatchState({ type: ACTIONS.ERROR, payload: false });
    dispatchState({
        type: ACTIONS.FILTER_COUNT,
        payload: state.filterCount - 1,
    });
};
export var handleInputChange = function (e, index, state, dispatchState) {
    if (!e)
        return;
    //Hide error if error is present
    if (state.error) {
        dispatchState({ type: ACTIONS.ERROR, payload: false });
        dispatchState({ type: ACTIONS.ERROR_MSG, payload: "" });
    }
    //If the column is changed by user, mark it as changed
    if (!state.changeColumn[index]) {
        var newChangeColumn = _.cloneDeep(state.changeColumn);
        newChangeColumn[index] = true;
        dispatchState({
            type: ACTIONS.CHANGE_COLUMN,
            payload: newChangeColumn,
        });
    }
    var copyFilterRows = _.cloneDeep(state.filterRows);
    copyFilterRows[index] = __assign(__assign({}, copyFilterRows[index]), { value: e.target.value });
    // copyFilterRows[index] = { ...copyFilterRows[index], value: finalVal };
    dispatchState({ type: ACTIONS.FILTER_ROWS, payload: copyFilterRows });
};
export var getAutoCompleteOptions = function (column, columns, token, source, autoCompleteObject, setAutoCompleteOptions, dataViewQueryOptions) { return __awaiter(void 0, void 0, void 0, function () {
    var idx, newAutoCompleteOptions, autoCompleteOptions, filters, body, autoCompleteData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                //When source is still undefined for dataviews (1st render), return it, as it will work after 1st re-render
                if (source == undefined)
                    return [2 /*return*/];
                idx = getColumnIndex(column, columns);
                newAutoCompleteOptions = _.cloneDeep(autoCompleteObject);
                autoCompleteOptions = [];
                if (columns[idx].column_data_type == "boolean") {
                    autoCompleteOptions = ["true", "false"];
                    newAutoCompleteOptions[columns[idx].column_name] = autoCompleteOptions;
                    setAutoCompleteOptions(newAutoCompleteOptions);
                    return [2 /*return*/];
                }
                if (!(columns[idx].provide_auto_selection &&
                    !autoCompleteObject[columns[idx].column_name])) return [3 /*break*/, 2];
                filters = [];
                if (dataViewQueryOptions) {
                    if (dataViewQueryOptions.itemBankId) {
                        filters = [
                            {
                                field_name: "item_bank_id",
                                allowed_values: [dataViewQueryOptions.itemBankId],
                            },
                        ];
                    }
                    else
                        return [2 /*return*/];
                }
                body = {
                    size: 0,
                    filters: filters,
                    aggs: {
                        group_by: [columns[idx].column_name],
                    },
                };
                return [4 /*yield*/, Api.post("/source/".concat(source), token, body)];
            case 1:
                autoCompleteData = _a.sent();
                if (autoCompleteData &&
                    autoCompleteData.aggs &&
                    autoCompleteData.aggs.rows.length > 0) {
                    autoCompleteOptions = autoCompleteData.aggs.rows.map(function (group) {
                        return String(group[column]);
                    });
                }
                autoCompleteOptions = autoCompleteOptions.filter(function (group) {
                    return group !== undefined && group != "";
                });
                autoCompleteOptions.sort();
                newAutoCompleteOptions[columns[idx].column_name] = autoCompleteOptions;
                setAutoCompleteOptions(newAutoCompleteOptions);
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); };
export var checkError = function (filterRows, columns) {
    var error = "";
    for (var i = 0; i < filterRows.length; i++) {
        var currentFilter = filterRows[i];
        var colIndex = getColumnIndex(currentFilter.column, columns);
        var colMetadata = columns[colIndex];
        if (colMetadata.column_data_type == "short" ||
            colMetadata.column_data_type == "long" ||
            colMetadata.column_data_type == "float") {
            if (currentFilter.value.trim().length == 0 &&
                !["is null", "is not null"].includes(currentFilter.criteria)) {
                error = "".concat(colMetadata.human_friendly_column_name, " cannot be empty.");
                return error;
            }
        }
    }
    return error;
};
