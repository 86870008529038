var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, Divider, IconButton, Stack, TextareaAutosize, } from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import useStore from "../../Store/store";
import { curlGenerate } from "../../Utils/util";
import { URL } from "../../Utils/constants";
import { useState } from "react";
import _ from "lodash";
var ViewCurl = function (_a) {
    var _b, _c;
    var view = _a.view;
    var _d = useStore(), token = _d.token, page = _d.page, current = _d.current, queryOptions = _d.queryOptions, columns = _d.columns;
    var _e = useState(false), copied = _e[0], setCopied = _e[1];
    var url = "";
    var curl = "";
    if (((_b = current === null || current === void 0 ? void 0 : current.viewDef) === null || _b === void 0 ? void 0 : _b.view_id) == (view === null || view === void 0 ? void 0 : view.view_id)) {
        // if the current view is changed, then reflect the current page and size selected in curl request
        url = "".concat(URL, "/source/").concat(view === null || view === void 0 ? void 0 : view.data_source, "?csv=false&page=").concat(page);
        var newQueryOptions = _.cloneDeep(queryOptions);
        var visibleCol_1 = [];
        columns.forEach(function (col) {
            if (col.show)
                visibleCol_1.push(col.column_name);
        });
        newQueryOptions.columns = visibleCol_1;
        curl = curlGenerate(url, token, newQueryOptions);
    }
    else {
        url = "".concat(URL, "/source/").concat(view === null || view === void 0 ? void 0 : view.data_source, "?csv=false&page=1\"");
        curl = curlGenerate(url, token, (_c = view === null || view === void 0 ? void 0 : view.parameters) === null || _c === void 0 ? void 0 : _c.body);
    }
    var copyHandler = function () {
        navigator.clipboard.writeText(curl);
        setCopied(true);
    };
    return (_jsxs(Box, { children: [_jsxs(Stack, __assign({ spacing: 3 }, { children: [_jsx(Divider, {}), _jsxs(Stack, __assign({ alignItems: "center", direction: "row" }, { children: [_jsx(TextareaAutosize, { maxRows: 4, value: curl, "aria-label": "maximum height", placeholder: "Share link", style: { width: "100%", height: 125 } }), _jsx(IconButton, __assign({ onClick: copyHandler }, { children: _jsx(ContentCopyRoundedIcon, {}) }))] })), _jsx(Stack, { direction: "row", spacing: 3 })] })), _jsx(Box, __assign({ sx: { height: "25px" } }, { children: copied && (_jsx(Typography, __assign({ sx: { fontSize: "12px", fontWeight: "400", color: "black" } }, { children: "Copied to clipboard !" }))) }))] }));
};
export default ViewCurl;
