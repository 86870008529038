var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableContainer } from "@inspera/component-library";
import useStore from "../../../Store/store";
import Footer from "../../Footer";
import { ModeType, } from "../../../Model/model";
import { AggregationTable } from "./Aggregation/AggregationTable";
import { DataTable } from "./Data/DataTable";
import TableLoading from "./Utils/TableLoading";
import { TableInfoPanel } from "./Utils/TableInfoPanel";
import Visualisation from "./Visualisation/Visualisation";
import _ from "lodash";
import { useEffect } from "react";
import dayjs from "dayjs";
import { initialDDTChartState } from "../../../Model/initialStates";
import { hashCode } from "../../../Utils/util";
import { VISUALISATION_VERSION } from "../../../Utils/constants";
export var StickyTable = function (_a) {
    var variableToFriendlyColumn = _a.variableToFriendlyColumn, loading = _a.loading;
    var _b = useStore(), visibleColumns = _b.visibleColumns, columns = _b.columns, data = _b.data, aggHeaders = _b.aggHeaders, setAggHeaders = _b.setAggHeaders, current = _b.current, mode = _b.mode, domain = _b.domain, chart = _b.chart, queryOptions = _b.queryOptions, setQueryOptions = _b.setQueryOptions, fetchVirtualColumns = _b.fetchVirtualColumns, columnCategories = _b.columnCategories, virtualColumnsMetadata = _b.virtualColumnsMetadata, restoreState = _b.restoreState, userId = _b.userId, deduplicate = _b.deduplicate, setRestoreState = _b.setRestoreState;
    useEffect(function () {
        var _a, _b;
        if (restoreState)
            return;
        if (queryOptions.filters.length == 0 &&
            queryOptions.sort.length == 0 &&
            queryOptions.aggs == null) {
            return;
        }
        var chartOptions = _.cloneDeep(chart);
        (_a = chartOptions.dataset) === null || _a === void 0 ? true : delete _a.source;
        if (current === null || current === void 0 ? void 0 : current.viewDef) {
            // Check if the current queryOptions and chart are equal as the current selected view
            var viewChange = false;
            if (current.viewDef.parameters.ddt_frontend.visualization) {
                if (!_.isEqual(current.viewDef.parameters.ddt_frontend.visualization.chart, chartOptions)) {
                    //Chart changed in current view
                    viewChange = true;
                }
            }
            else {
                //View didn't have visualization in it orignially
                if (!_.isEqual(chartOptions, initialDDTChartState)) {
                    viewChange = true;
                }
            }
            //Check for queryOption change
            if (!_.isEqual(current.viewDef.parameters.body, queryOptions)) {
                viewChange = true;
            }
            //Check if deduplicate option is changed
            //in view definition deduplicate means false, if it's undefined
            var viewDeduplicate = (_b = current.viewDef.parameters.ddt_frontend.deduplicate) !== null && _b !== void 0 ? _b : false;
            if (deduplicate != viewDeduplicate) {
                viewChange = true;
            }
            //If we switched to a view, then there's no change, so delete localStorage options and return
            if (!viewChange) {
                var hashUserId_1 = hashCode(String(userId));
                localStorage.removeItem("options".concat(hashUserId_1));
                return;
            }
        }
        var visibleColCat = [];
        columnCategories.forEach(function (cat) {
            if (cat.show)
                visibleColCat.push(cat.categoryName);
        });
        var visibleCol = [];
        columns.forEach(function (col) {
            if (col.show)
                visibleCol.push(col.column_name);
        });
        var newQueryOptions = JSON.parse(JSON.stringify(queryOptions));
        newQueryOptions.columns = visibleCol;
        var restoreStateOptions = {
            current: current,
            parameters: {
                body: newQueryOptions,
                ddt_frontend: {
                    column_categories: visibleColCat,
                    agg_headers: aggHeaders,
                    mode: mode,
                    deduplicate: deduplicate,
                },
            },
            timestamp: dayjs().format(),
        };
        if (!_.isEqual(chartOptions, initialDDTChartState)) {
            restoreStateOptions.parameters.ddt_frontend.visualization = {
                version: VISUALISATION_VERSION,
                chart: chartOptions,
            };
        }
        if (fetchVirtualColumns) {
            restoreStateOptions.parameters.ddt_frontend.virtual_columns = {
                fetchVirtualColumns: fetchVirtualColumns,
                virtualColumnsMetadata: virtualColumnsMetadata,
            };
        }
        var hashUserId = hashCode(String(userId));
        localStorage.setItem("options".concat(hashUserId), JSON.stringify(restoreStateOptions));
    }, [queryOptions, JSON.stringify(chart), deduplicate, aggHeaders]);
    var handleUpdateHeader = function (oldKey, newKey) {
        // aggs columns are of 2 types : 1. 'group by' columns & count 2. agg function column
        // 1st is renamed using aggHeaders for which we need original names of columns
        // 2nd can be renamed by renaming aggs in queryOption
        var _a;
        var _b, _c;
        var aggGroupbyColumns = (_b = data === null || data === void 0 ? void 0 : data.aggs.grouped_by) !== null && _b !== void 0 ? _b : [];
        if (restoreState)
            setRestoreState(false);
        if (aggGroupbyColumns.includes(oldKey) || oldKey == "count") {
            setAggHeaders(__assign(__assign({}, aggHeaders), (_a = {}, _a[oldKey] = newKey, _a)));
        }
        else if (oldKey != newKey &&
            ((_c = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.aggs) === null || _c === void 0 ? void 0 : _c.aggs) != null &&
            newKey != "") {
            var newAggsOptions = JSON.parse(JSON.stringify(queryOptions));
            newAggsOptions.aggs.aggs[newKey] = newAggsOptions.aggs.aggs[oldKey];
            delete newAggsOptions.aggs.aggs[oldKey];
            setQueryOptions(newAggsOptions);
        }
    };
    var getPanel = function (mode) {
        var _a, _b, _c, _d;
        switch (mode) {
            case ModeType.data:
                // Add other irt columns fetched from content here
                var newColumns = _.cloneDeep(columns);
                if (((_a = current === null || current === void 0 ? void 0 : current.source) === null || _a === void 0 ? void 0 : _a.data_source_url_slug) == "irt_value") {
                    newColumns = newColumns.concat(virtualColumnsMetadata);
                }
                return (_jsx(DataTable, { headers: newColumns, data: data, domain: domain, colVisible: visibleColumns == 0 }));
            case ModeType.agg:
                return (_jsx(AggregationTable, { headers: columns, groupedBy: ((_b = data === null || data === void 0 ? void 0 : data.aggs) === null || _b === void 0 ? void 0 : _b.grouped_by)
                        ? (_c = data === null || data === void 0 ? void 0 : data.aggs) === null || _c === void 0 ? void 0 : _c.grouped_by.map(function (x) { return variableToFriendlyColumn[x]; })
                        : [], aggNames: (_d = data === null || data === void 0 ? void 0 : data.aggs) === null || _d === void 0 ? void 0 : _d.agg_names, data: data, domain: domain, updateHeaders: handleUpdateHeader, ddtHeaders: aggHeaders }));
            case ModeType.vis:
                return _jsx(Visualisation, {});
            default:
                return (_jsx(DataTable, { headers: columns, data: data, domain: domain, colVisible: visibleColumns == 0 }));
        }
    };
    return (_jsxs(TableContainer, __assign({ sx: {
            width: "100%",
            overflowX: "auto",
            height: "100%",
        } }, { children: [data == null && !loading ? (_jsx(TableInfoPanel, { isSourceSelected: (current === null || current === void 0 ? void 0 : current.source) != null })) : loading ? (_jsx(TableLoading, {})) : (getPanel(mode.active)), !(visibleColumns == 0 || mode.active != ModeType.data) &&
                (data === null || data === void 0 ? void 0 : data.rows.length) > 0 && _jsx(Footer, {})] })));
};
