var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { useFetchColumns } from "../../Hook/useFetchColumns";
import { useFetchData } from "../../Hook/useFetchData";
import useStore from "../../Store/store";
import Titlebar from "../Titlebar.tsx/Titlebar";
import TableLoading from "./Table/Utils/TableLoading";
import { TableInfoPanel } from "./Table/Utils/TableInfoPanel";
import { DataViewTable } from "./Table/Data/DataViewTable";
import ItemBankSelector from "../FilterWizard/Filters/DataViewFilters/ItemBankSelector/ItemBankSelector";
import { FilterMenu } from "../FilterWizard/Filters";
import { TableContainer } from "@inspera/component-library";
import ShowInteraction from "../FilterWizard/Filters/DataViewFilters/ShowInteraction/ShowInteraction";
export var DataViewDashboard = function () {
    var _a = useStore(), data = _a.data, variableToFriendlyColumn = _a.variableToFriendlyColumn, loading = _a.loading, columns = _a.columns, domain = _a.domain, visibleColumns = _a.visibleColumns, dataViewData = _a.dataViewData;
    var isColumnsLoading = useFetchColumns()[0];
    var _b = useFetchData(), _ = _b[0], isTableLoading = _b[1];
    var _c = useState(false), collapse = _c[0], setCollapse = _c[1];
    return (_jsxs(Box, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sx: {
                    border: 1,
                    borderColor: "#EDEDED",
                } }, { children: _jsx(Titlebar, {}) })), _jsxs(Grid, __assign({ item: true, container: true, xs: 12 }, { children: [_jsx(Box, __assign({ sx: {
                            "@media (max-height: 915px)": {
                                overflowX: "hidden",
                                overflowY: "auto",
                            },
                        } }, { children: _jsxs(Grid, __assign({ item: true, width: collapse ? "1rem" : "264px", height: "calc(100vh - 229px)", bgcolor: "white", borderRight: "1px solid #EDEDED", sx: { transition: "width 0.2s" } }, { children: [_jsx(ItemBankSelector, {}), _jsx(ShowInteraction, {}), _jsx(FilterMenu, { variableToFriendlyColumn: variableToFriendlyColumn, variant: "dataview" })] })) })), _jsx(Grid, __assign({ xs: true, item: true, height: "calc(100vh - 229px)", sx: {
                            backgroundColor: "#F7F7F7",
                            transition: "all 0.4s",
                            overflowY: "hidden",
                            width: "100px",
                            padding: "23px",
                            paddingBottom: "0px",
                        } }, { children: _jsx(TableContainer, __assign({ sx: {
                                width: "100%",
                                overflowX: "auto",
                                height: "100%",
                            } }, { children: dataViewData == null && !isTableLoading ? (_jsx(TableInfoPanel, {})) : isTableLoading ? (_jsx(TableLoading, {})) : (_jsx(DataViewTable, { headers: columns, data: dataViewData, domain: domain })) })) }))] }))] }));
};
