var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect, useState } from "react";
import { apiCore } from "../Api/apiCore";
import { ErrorType, ModeType } from "../Model/model";
import useStore from "../Store/store";
import _ from "lodash";
export var useFetchData = function () {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useStore(), data = _b.data, page = _b.page, queryOptions = _b.queryOptions, current = _b.current, token = _b.token, setTableData = _b.setTableData, setSortCount = _b.setSortCount, setError = _b.setError, fetchVirtualColumns = _b.fetchVirtualColumns, deduplicate = _b.deduplicate, mode = _b.mode, setMode = _b.setMode, dataViewQueryOptions = _b.dataViewQueryOptions, setDataViewData = _b.setDataViewData, setLoading = _b.setLoading;
    useEffect(function () {
        //If data is fetched for dataview, use the other useEffect
        if (current === null || current === void 0 ? void 0 : current.dataView)
            return;
        var newSortCount = 0;
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var options, finalDeduplicate;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        options = _.cloneDeep(queryOptions);
                        setIsLoading(true);
                        setLoading(true);
                        if (options && options.columns)
                            options.columns = [];
                        // by default data should be sorted by timestamp in descending order, for that sort key shouldn't be present in queryOptions when sending api request
                        if (options.sort && options.sort.length == 0) {
                            //@ts-ignore
                            delete options["sort"];
                        }
                        finalDeduplicate = deduplicate;
                        if (queryOptions.deduplication_columns &&
                            queryOptions.deduplication_columns.length > 0)
                            finalDeduplicate = true;
                        if (!((current === null || current === void 0 ? void 0 : current.source) != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, apiCore
                                .fetchData(token, current.source.data_source_url_slug, page, options, fetchVirtualColumns, finalDeduplicate)
                                .then(function (data) {
                                setTableData(data);
                                //add AGG mode if we have aggs data
                                //also checking if this was an aggs data, not the agg result for autocomplete in filters
                                if (data.aggs && data.metadata.current_page != 0) {
                                    var newMode = _.cloneDeep(mode);
                                    if (!mode.availableModes.includes(ModeType.agg)) {
                                        newMode.availableModes.push(ModeType.agg);
                                        newMode.active = ModeType.agg;
                                        setMode(newMode);
                                    }
                                }
                            })
                                .catch(function (err) {
                                var _a, _b;
                                //This is where error thrown from api.ts is caught and set in global store
                                setError({
                                    message: err.message,
                                    code: (_a = err.code) !== null && _a !== void 0 ? _a : "500",
                                    type: ErrorType.ERROR,
                                    title: "An error occured",
                                    "ddt-api-request-id": (_b = err["ddt-api-request-id"]) !== null && _b !== void 0 ? _b : undefined,
                                });
                            })];
                    case 1:
                        _a.sent();
                        setIsLoading(false);
                        setLoading(false);
                        _a.label = 2;
                    case 2:
                        setIsLoading(false);
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        if (queryOptions) {
            fetchData();
            if (queryOptions.sort.length == 0)
                setSortCount(0);
            else {
                queryOptions.sort.forEach(function (sort) {
                    if (sort.field_name)
                        newSortCount++;
                });
                setSortCount(newSortCount);
            }
        }
    }, [queryOptions, page, fetchVirtualColumns, deduplicate]);
    useEffect(function () {
        //Use effect for handling fetching data for dataviews
        if (!(current === null || current === void 0 ? void 0 : current.dataView) || !(dataViewQueryOptions === null || dataViewQueryOptions === void 0 ? void 0 : dataViewQueryOptions.itemBankId))
            return;
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var options;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        options = _.cloneDeep(dataViewQueryOptions);
                        setIsLoading(true);
                        setLoading(true);
                        if (!current.dataView) return [3 /*break*/, 2];
                        return [4 /*yield*/, apiCore
                                .fetchDataView(token, (options === null || options === void 0 ? void 0 : options.itemBankId) ? Number(options.itemBankId) : null, options === null || options === void 0 ? void 0 : options.body, (_a = options === null || options === void 0 ? void 0 : options.showInteractions) !== null && _a !== void 0 ? _a : false)
                                .then(function (data) {
                                // setTableData(data);
                                setDataViewData(data);
                            })
                                .catch(function (err) {
                                var _a, _b;
                                //This is where error thrown from api.ts is caught and set in global store
                                setError({
                                    message: err.message,
                                    code: (_a = err.code) !== null && _a !== void 0 ? _a : "500",
                                    type: ErrorType.ERROR,
                                    title: "An error occured",
                                    "ddt-api-request-id": (_b = err["ddt-api-request-id"]) !== null && _b !== void 0 ? _b : undefined,
                                });
                            })];
                    case 1:
                        _b.sent();
                        setIsLoading(false);
                        setLoading(false);
                        _b.label = 2;
                    case 2:
                        setIsLoading(false);
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        if (dataViewQueryOptions) {
            fetchData();
        }
    }, [dataViewQueryOptions, page]);
    return [data, isLoading];
};
