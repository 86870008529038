var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import useStore from "../../../Store/store";
import { DataViewModalCard } from "./DataViewModalCard";
import { Input, Typography } from "@inspera/component-library";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
var DataViewTable = function (_a) {
    var _b;
    var handleClose = _a.handleClose;
    var dataViews = useStore().dataViews;
    var _c = useState(""), search = _c[0], setSearch = _c[1];
    var _d = useState(dataViews !== null && dataViews !== void 0 ? dataViews : []), searchedDataViews = _d[0], setSearchedDataViews = _d[1];
    useEffect(function () {
        setSearchedDataViews(dataViews !== null && dataViews !== void 0 ? dataViews : []);
    }, [dataViews]);
    var handleSearchInput = function (e) {
        var searchInput = e.target.value;
        var searchDataViewResult = getSearchedDataViews(searchInput);
        setSearchedDataViews(searchDataViewResult);
        setSearch(searchInput);
    };
    var getSearchedDataViews = function (search) {
        var result = dataViews === null || dataViews === void 0 ? void 0 : dataViews.filter(function (x) {
            return (x.title.toUpperCase().includes(search.toUpperCase()) ||
                x.description.toUpperCase().includes(search.toUpperCase()));
        });
        return result !== null && result !== void 0 ? result : [];
    };
    return (_jsxs(Box, { children: [_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center" } }, { children: [_jsx(Typography, __assign({ variant: "h4", marginBottom: 0, color: "#272727" }, { children: "Dataviews" })), _jsx(Chip, { sx: { marginLeft: "8px", fontSize: "14px" }, label: (_b = dataViews === null || dataViews === void 0 ? void 0 : dataViews.length) !== null && _b !== void 0 ? _b : 0 })] })), _jsx(Box, __assign({ sx: { margin: "10px 0 40px" } }, { children: _jsx(Typography, __assign({ variant: "body1" }, { children: "Dataviews are based on the regular data sources. They let you see the data from a different, usually friendlier, perspective." })) })), _jsx(Box, __assign({ sx: { margin: "10px 0 40px" } }, { children: _jsx(Input, { placeholder: "Search for ...", sx: { width: "238px" }, startIcon: _jsx(SearchTwoToneIcon, {}), onChange: handleSearchInput }) })), _jsx(Box, __assign({ sx: { height: "300px" } }, { children: searchedDataViews === null || searchedDataViews === void 0 ? void 0 : searchedDataViews.map(function (dataView, i) {
                    return (_jsx(DataViewModalCard, { dataView: dataView, handleClose: handleClose }, i));
                }) }))] }));
};
export default DataViewTable;
