var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { useFetchColumns } from "../../Hook/useFetchColumns";
import { useFetchData } from "../../Hook/useFetchData";
import useStore from "../../Store/store";
import { FilterWizard } from "../FilterWizard/FilterWizard";
import Titlebar from "../Titlebar.tsx/Titlebar";
import Datamenu from "../Datamenu/Datamenu";
import { StickyTable } from "./Table";
export var Dashboard = function (_a) {
    var hidden = _a.hidden;
    var _b = useStore(), data = _b.data, variableToFriendlyColumn = _b.variableToFriendlyColumn;
    var isColumnsLoading = useFetchColumns()[0];
    var _c = useFetchData(), _ = _c[0], isTableLoading = _c[1];
    var _d = useState(false), collapse = _d[0], setCollapse = _d[1];
    return (_jsxs(Box, __assign({ sx: { display: hidden ? "none" : "initial" } }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sx: {
                    border: 1,
                    borderColor: "#EDEDED",
                } }, { children: _jsx(Titlebar, {}) })), _jsx(Grid, __assign({ item: true, xs: 12, sx: {
                    border: 1,
                    borderColor: "#EDEDED",
                    borderTop: "none",
                } }, { children: _jsx(Datamenu, { metadata: data === null || data === void 0 ? void 0 : data.metadata }) })), _jsxs(Grid, __assign({ item: true, container: true, xs: 12 }, { children: [_jsx(Box, __assign({ sx: {
                            "@media (max-height: 915px)": {
                                overflowX: "hidden",
                                overflowY: "auto",
                            },
                        } }, { children: _jsx(FilterWizard, { collapse: collapse, setCollapse: setCollapse, isColumnsLoading: isColumnsLoading }) })), _jsx(Grid, __assign({ xs: true, item: true, height: "calc(100vh - 229px)", sx: {
                            backgroundColor: "#F7F7F7",
                            transition: "all 0.4s",
                            overflowY: "hidden",
                            width: "100px",
                            padding: "23px",
                            paddingBottom: "0px",
                        } }, { children: _jsx(StickyTable, { variableToFriendlyColumn: variableToFriendlyColumn, collapse: collapse, loading: isTableLoading }) }))] }))] })));
};
