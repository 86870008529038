var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { Navigate, useLocation, useParams, useSearchParams, } from "react-router-dom";
import { apiCore } from "../Api/apiCore";
import Navbar from "../Components/Navbar";
import { ErrorType, StatusCode } from "../Model/model";
import useStore from "../Store/store";
import { decodeUserInfo } from "../Utils/util";
var LoginPage = function () {
    var _a = useStore(), isAuth = _a.isAuth, sharedView = _a.sharedView, setUser = _a.setUser, setMarketplaceId = _a.setMarketplaceId, setAuth = _a.setAuth, setError = _a.setError, setSharedView = _a.setSharedView, setSource = _a.setSource, setView = _a.setView, setQueryOptions = _a.setQueryOptions, setDeepLinkQuery = _a.setDeepLinkQuery, setDeepLinkQueryOption = _a.setDeepLinkQueryOption, setPresets = _a.setPresets, setDataViews = _a.setDataViews;
    var location = useLocation();
    var viewId = useParams().viewId;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useSearchParams(), searchParams = _c[0], setSearchParams = _c[1];
    //Useeffect has to be made async, when shared link is opened, init() has to be finished before sharedView is set, so await init()
    //@ts-ignore
    useEffect(function () {
        function initialFetch() {
            return __awaiter(this, void 0, void 0, function () {
                var searchParams, token, mpId, source, query, sources, userInfo, fetchPresets, fetchDataViews, error_1, init, fetchSharedView, newQueryOption;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            searchParams = new URLSearchParams(location.search);
                            token = searchParams.get("token");
                            if (token) {
                                //remove token query parmas from URL
                                searchParams.delete("token");
                                setSearchParams(searchParams);
                            }
                            mpId = searchParams.get("mp");
                            source = searchParams.get("source");
                            query = searchParams.get("q");
                            //Get Token
                            if (token == null)
                                token = localStorage.getItem("ddt_token");
                            //If user doesn't have token in params as well as local storage
                            if (!token) {
                                setError({
                                    type: ErrorType.ERROR,
                                    code: StatusCode.UAUTHENTICATED,
                                    message: "Please login through inspera platform",
                                    title: "Login error",
                                });
                                setLoading(false);
                                return [2 /*return*/];
                            }
                            try {
                                userInfo = decodeUserInfo(token);
                            }
                            catch (error) {
                                //When there's an error while decoding the token
                                setError({
                                    type: ErrorType.ERROR,
                                    code: StatusCode.UAUTHENTICATED,
                                    message: "Please login through inspera platform",
                                    title: "Invalid token",
                                });
                                setLoading(false);
                                return [2 /*return*/];
                            }
                            setUser(token, userInfo);
                            //If url doesn't have a marketplaceId set, set it using token info
                            mpId
                                ? setMarketplaceId(parseInt(mpId))
                                : setMarketplaceId(userInfo.data.marketplaceId);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 4, , 5]);
                            return [4 /*yield*/, apiCore.getPresets(token)];
                        case 2:
                            fetchPresets = _a.sent();
                            return [4 /*yield*/, apiCore.getDataViews(token)];
                        case 3:
                            fetchDataViews = _a.sent();
                            setPresets(fetchPresets);
                            setDataViews(fetchDataViews);
                            return [3 /*break*/, 5];
                        case 4:
                            error_1 = _a.sent();
                            setPresets([]);
                            return [3 /*break*/, 5];
                        case 5:
                            if (!(userInfo.data.itemBankIds.length == 0)) return [3 /*break*/, 7];
                            //check if it's a valid token and set it in localStorage inside apiCore
                            return [4 /*yield*/, apiCore.tokenStatus(token).catch(function (err) {
                                    var _a;
                                    setError({
                                        type: ErrorType.ERROR,
                                        code: (_a = err.code) !== null && _a !== void 0 ? _a : "500",
                                        message: "",
                                        title: "",
                                    });
                                })];
                        case 6:
                            //check if it's a valid token and set it in localStorage inside apiCore
                            _a.sent();
                            setError({
                                type: ErrorType.ERROR,
                                code: StatusCode.ITEMBANKEMPTY,
                                message: "You don't have access to any item bank. Please contact your admin and re-login",
                                title: "Item banks inaccessible",
                            });
                            setLoading(false);
                            return [2 /*return*/];
                        case 7:
                            init = function () { return __awaiter(_this, void 0, void 0, function () {
                                var err_1;
                                var _a, _b;
                                return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            _c.trys.push([0, 4, , 5]);
                                            return [4 /*yield*/, apiCore.tokenStatus(token)];
                                        case 1:
                                            _c.sent();
                                            return [4 /*yield*/, apiCore.getDataSource(token).then(function (res) {
                                                    sources = res;
                                                    setSource(res);
                                                })];
                                        case 2:
                                            _c.sent();
                                            return [4 /*yield*/, apiCore.getViews(token).then(function (res) {
                                                    setView(res);
                                                })];
                                        case 3:
                                            _c.sent();
                                            return [3 /*break*/, 5];
                                        case 4:
                                            err_1 = _c.sent();
                                            setSharedView({
                                                id: viewId == null ? sharedView.id : viewId,
                                                source: null,
                                                viewDef: null,
                                            });
                                            setAuth(false);
                                            setError({
                                                message: (_a = err_1.message) !== null && _a !== void 0 ? _a : "",
                                                code: (_b = err_1.code) !== null && _b !== void 0 ? _b : "500",
                                                type: ErrorType.ERROR,
                                                title: "An error occured",
                                            });
                                            setLoading(false);
                                            return [3 /*break*/, 5];
                                        case 5: return [2 /*return*/];
                                    }
                                });
                            }); };
                            fetchSharedView = function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, apiCore
                                                .fetchView(token, viewId)
                                                .then(function (res) {
                                                return setSharedView({
                                                    id: res.view_id,
                                                    source: res.data_source,
                                                    viewDef: res,
                                                });
                                            })
                                                .catch(function (err) {
                                                var _a;
                                                setError({
                                                    message: err.message,
                                                    code: (_a = err.code) !== null && _a !== void 0 ? _a : "500",
                                                    type: ErrorType.ERROR,
                                                    title: "An error occured while fetching shared view",
                                                });
                                            })];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); };
                            //Fetch Data
                            return [4 /*yield*/, init()];
                        case 8:
                            //Fetch Data
                            _a.sent();
                            // Shared View
                            if (viewId) {
                                fetchSharedView();
                            }
                            if (source && query) {
                                newQueryOption = JSON.parse(window.atob(query));
                                setDeepLinkQuery(true);
                                setDeepLinkQueryOption(newQueryOption);
                                setQueryOptions(newQueryOption);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        initialFetch();
    }, []);
    return (_jsx(Box, __assign({ zIndex: 1 }, { children: loading ? (!isAuth ? (_jsxs(_Fragment, { children: [_jsx(Navbar, { title: "Data discovery" }), _jsx(Box, __assign({ sx: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "calc(50vh - 89px)",
                    } }, { children: _jsx(CircularProgress, {}) }))] })) : (_jsx(Navigate, { to: "/" }))) : (_jsx(Navigate, { to: "/error/" })) })));
};
export default LoginPage;
