var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import create from "zustand";
import { devtools } from "zustand/middleware";
import { createAppSlice } from "./app-slice";
import { createAuthSlice } from "./auth-slice";
import { createDataSlice } from "./data-slice";
var useStore = create()(devtools(function () {
    var a = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        a[_i] = arguments[_i];
    }
    return (__assign(__assign(__assign({}, createDataSlice.apply(void 0, a)), createAppSlice.apply(void 0, a)), createAuthSlice.apply(void 0, a)));
}));
export default useStore;
