var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, Select, MenuItem, } from "@mui/material";
import { Button, Input } from "@inspera/component-library";
import { useState } from "react";
import useStore from "../../../../Store/store";
import _ from "lodash";
var Relative = function (_a) {
    var dmy = _a.dmy, setDmy = _a.setDmy, lastNum = _a.lastNum, setLastNum = _a.setLastNum, handleClose = _a.handleClose, handleReset = _a.handleReset, commonlyUsed = _a.commonlyUsed, setAppliedLabel = _a.setAppliedLabel, selectedColumn = _a.selectedColumn;
    var _b = useStore(), queryOptions = _b.queryOptions, setQueryOptions = _b.setQueryOptions, restoreState = _b.restoreState, setRestoreState = _b.setRestoreState;
    var _c = useState(false), error = _c[0], setError = _c[1];
    var _d = useState(""), errorMsg = _d[0], setErrorMsg = _d[1];
    var handleSelect = function (e) {
        var val = e.target.value;
        setDmy(val);
    };
    var handleSave = function () {
        if (lastNum == "") {
            setError(true);
            setErrorMsg("Enter a value or Select a Commonly used option");
            return;
        }
        if (isNaN(Number(lastNum)) || Number(lastNum) < 1) {
            setError(true);
            setErrorMsg("Enter a valid number");
            return;
        }
        var esDate = "";
        switch (dmy) {
            case "d":
                esDate = "now-".concat(Number(lastNum), "d/d");
                Number(lastNum) > 1
                    ? setAppliedLabel("Last ".concat(lastNum, " days"))
                    : setAppliedLabel("Last ".concat(lastNum, " day"));
                break;
            case "M":
                esDate = "now-".concat(Number(lastNum), "M/M");
                Number(lastNum) > 1
                    ? setAppliedLabel("Last ".concat(lastNum, " months"))
                    : setAppliedLabel("Last ".concat(lastNum, " month"));
                break;
            case "y":
                esDate = "now-".concat(Number(lastNum), "y/y");
                Number(lastNum) > 1
                    ? setAppliedLabel("Last ".concat(lastNum, " years"))
                    : setAppliedLabel("Last ".concat(lastNum, " year"));
                break;
        }
        var newQueryOptions = _.cloneDeep(queryOptions);
        newQueryOptions.date_range = { gte: esDate, field_name: selectedColumn };
        if (restoreState)
            setRestoreState(false);
        setQueryOptions(newQueryOptions);
        handleClose();
    };
    var lastNumHandler = function (e) {
        setLastNum(e.target.value);
    };
    var handleCommon = function (common, key) {
        var newQueryOptions = _.cloneDeep(queryOptions);
        newQueryOptions.date_range = { gte: common, field_name: selectedColumn };
        setQueryOptions(newQueryOptions);
        setAppliedLabel(key);
        handleClose();
    };
    return (_jsxs(Box, __assign({ sx: { display: "flex", flexDirection: "column" } }, { children: [_jsxs(Box, __assign({ sx: { margin: "10px 0" } }, { children: [_jsx(Typography, __assign({ sx: {
                            color: "#404040",
                            fontWeight: "400",
                            fontSize: "16px",
                            marginBottom: "8px",
                        } }, { children: "Commonly used" })), _jsx(Box, __assign({ sx: { display: "flex", flexWrap: "wrap", width: "300px" } }, { children: Object.keys(commonlyUsed).map(function (com, i) { return (_jsx(Button, __assign({ sx: { margin: "4px 0" }, onClick: function () { return handleCommon(commonlyUsed[com], com); }, variant: "contained", color: "info" }, { children: com }), i)); }) }))] })), _jsxs(Box, { children: [_jsx(Typography, __assign({ sx: {
                            color: "#404040",
                            fontWeight: "400",
                            fontSize: "16px",
                            marginBottom: "8px",
                        } }, { children: "Quick select" })), _jsxs(Box, __assign({ sx: {
                            display: "flex",
                            margin: "10px 0",
                            width: "300px",
                            alignItems: "center",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        } }, { children: [_jsx(Typography, { children: "Last" }), _jsx(Box, __assign({ sx: { width: "110px" } }, { children: _jsx(Input, { value: lastNum, onChange: lastNumHandler, placeholder: "Number of", "aria-label": lastNum }) })), _jsxs(Select, __assign({ sx: { width: "107px", fontSize: "14px", fontWeight: "400" }, onChange: handleSelect, value: dmy }, { children: [_jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: "d" }, { children: "day(s)" }), "d"), _jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: "M" }, { children: "month(s)" }), "M"), _jsx(MenuItem, __assign({ sx: { fontSize: "14px" }, value: "y" }, { children: "year(s)" }), "y")] }))] })), _jsx(Box, __assign({ minHeight: "30px" }, { children: error && (_jsx(Typography, __assign({ sx: {
                                margin: "5px 0",
                                color: "#d10000",
                                fontSize: "13px",
                                padding: "2px 8px",
                                borderRadius: "4px",
                                backgroundColor: "#edc0c0",
                                width: "fit-content",
                            } }, { children: errorMsg }))) })), _jsxs(Box, __assign({ sx: {
                            display: "flex",
                            justifyContent: "space-between",
                            width: "175px",
                            marginTop: "8px",
                        } }, { children: [_jsx(Button, __assign({ onClick: handleSave, variant: "contained", color: "primary" }, { children: "Apply" })), _jsx(Button, __assign({ onClick: function () { return handleReset(); }, variant: "contained", color: "secondary" }, { children: "Reset" }))] }))] })] })));
};
export default Relative;
