var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, ListItem, ListItemText, Menu, TableCell, Alert, Snackbar, Box, ClickAwayListener, Icon, Tooltip, } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import infoIcon from "../../../../Assets/images/info_empty.svg";
import React, { useState } from "react";
import { Input, MenuItem } from "@inspera/component-library";
import useStore from "../../../../Store/store";
export var AggHeaderCell = function (_a) {
    var value = _a.value, align = _a.align, updateCell = _a.updateCell, ddtHeaders = _a.ddtHeaders, aggNames = _a.aggNames, groupedBy = _a.groupedBy;
    var aggHeaders = useStore().aggHeaders;
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var _c = React.useState(""), name = _c[0], setName = _c[1];
    var _d = React.useState(false), hover = _d[0], sethover = _d[1];
    var _e = React.useState(false), disable = _e[0], setDisable = _e[1];
    var _f = React.useState(false), editable = _f[0], setEditable = _f[1];
    var _g = useState(false), tooltipState = _g[0], setTooltipState = _g[1];
    var open = Boolean(anchorEl);
    var handleDropdownClick = function (event) {
        setAnchorEl(event.currentTarget);
        sethover(true);
    };
    var handleTooltipClose = function () {
        setTooltipState(false);
    };
    var handleTooltipOpen = function () {
        setTooltipState(true);
    };
    var handleChange = function (event) {
        setName(event.target.value);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleMenuClick = function () {
        setEditable(true);
        setAnchorEl(null);
    };
    var headerValidator = function () {
        var testSymbols = /[.><\[\]]+/;
        var match = testSymbols.test(name);
        if (!["count", "group"].includes(value) &&
            ["count", "group"].includes(name))
            match = true;
        if (aggNames.includes(value) && aggNames.includes(name))
            match = true;
        if (__spreadArray(__spreadArray([], aggNames, true), [ddtHeaders === null || ddtHeaders === void 0 ? void 0 : ddtHeaders.group, ddtHeaders === null || ddtHeaders === void 0 ? void 0 : ddtHeaders.count], false).includes(name))
            match = true;
        if (["count", "group"].includes(value) && ["count", "group"].includes(name))
            match = true;
        if (["count", "group"].includes(value) &&
            ((ddtHeaders === null || ddtHeaders === void 0 ? void 0 : ddtHeaders.group) == name || (ddtHeaders === null || ddtHeaders === void 0 ? void 0 : ddtHeaders.count) == name))
            match = true;
        return match;
    };
    var handleCellUpdate = function () {
        //For renaming the aggregation columns
        if (value == name || name == "") {
            setEditable(false);
            setName("");
            return;
        }
        if (headerValidator()) {
            setEditable(false);
            setName("");
            setDisable(true);
        }
        else {
            if (name == "") {
                setEditable(false);
                return;
            }
            setName(name);
            setEditable(false);
            updateCell(value, name);
        }
    };
    var handleEnterKeyPress = function (e, value) {
        if (e.keyCode == 13)
            handleCellUpdate();
    };
    var handleInputClickAway = function (e) {
        handleCellUpdate();
    };
    var handleMoveOver = function () {
        if (!editable)
            sethover(true);
    };
    var handleMouseLeave = function () {
        if (!editable)
            sethover(false);
    };
    var handleCloseSnack = function () {
        setDisable(false);
    };
    var getHeaderName = function () {
        if (aggHeaders) {
            if (value in aggHeaders)
                return aggHeaders[value];
        }
        return name == "" ? value : name;
    };
    return (_jsxs(TableCell, __assign({ sx: {
            p: "0px",
            m: "0px",
            whiteSpace: "nowrap",
        }, align: "right" }, { children: [_jsx(Snackbar, __assign({ open: disable, autoHideDuration: 4000, onClose: handleCloseSnack, ClickAwayListenerProps: { onClickAway: function () { return null; } } }, { children: _jsx(Alert, __assign({ variant: "filled", onClose: handleCloseSnack, severity: "error" }, { children: "Aggregation header must be unqiue and cannot be named as group, count or any symbols" })) })), _jsx(ListItem, __assign({ onMouseOver: handleMoveOver, onMouseLeave: handleMouseLeave, secondaryAction: editable ? null : (_jsxs(Box, __assign({ sx: { display: "flex" } }, { children: [value == "group" && (_jsx(ClickAwayListener, __assign({ onClickAway: handleTooltipClose }, { children: _jsx(Box, { children: _jsx(Tooltip, __assign({ PopperProps: {
                                        disablePortal: true,
                                    }, componentsProps: {
                                        tooltip: {
                                            style: {
                                                width: "300px",
                                                whiteSpace: "pre-line",
                                            },
                                        },
                                    }, onClose: handleTooltipClose, open: tooltipState, disableFocusListener: true, disableHoverListener: true, arrow: true, sx: {
                                        "& .MuiTooltip-tooltip": {
                                            whiteSpace: "pre-line",
                                            width: "300px",
                                        },
                                    }, disableTouchListener: true, placement: "bottom", title: groupedBy.join(", ") }, { children: _jsx(IconButton, __assign({ sx: { padding: "7px" }, disableRipple: true, edge: "end", "aria-controls": open ? "basic-menu" : undefined, "aria-haspopup": "true", "aria-expanded": open ? "true" : undefined, onClick: handleTooltipOpen }, { children: _jsx(Icon, { children: _jsx("img", { src: infoIcon, alt: "grid icon", "aria-hidden": "true" }) }) })) })) }) }))), _jsx(IconButton, __assign({ edge: "end", "aria-controls": open ? "basic-menu" : undefined, "aria-haspopup": "true", "aria-expanded": open ? "true" : undefined, onClick: handleDropdownClick, sx: {
                                visibility: hover ? "visible" : "hidden",
                            } }, { children: _jsx(MoreVertIcon, {}) }))] }))) }, { children: editable ? (_jsx(ClickAwayListener, __assign({ onClickAway: handleInputClickAway }, { children: _jsx(Input, { inputProps: {
                            style: { padding: "5px" },
                        }, 
                        // inputRef={(node: any) => node && node.focus()}
                        id: "reaname-header-input", onChange: handleChange, onBlur: handleInputClickAway, onKeyDown: function (e) { return handleEnterKeyPress(e, value); }, type: "text", placeholder: value, value: name }) }))) : (_jsx(ListItemText, { primaryTypographyProps: {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        margin: "0px",
                        textAlign: { align: align },
                    }, primary: getHeaderName() })) })), _jsx(Menu, __assign({ elevation: 1, id: "basic-menu", anchorEl: anchorEl, open: open, onMouseOver: handleMoveOver, onClose: handleClose, MenuListProps: {
                    "aria-labelledby": "basic-button",
                } }, { children: _jsx(MenuItem, __assign({ sx: { pr: "5rem", py: "10px", fontSize: "14px" }, onClick: handleMenuClick }, { children: "Rename" })) }))] })));
};
