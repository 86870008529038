export var getSourceObject = function (source, sources) {
    if (source) {
        var value = sources.find(function (x) {
            return x.data_source_url_slug == source;
        });
        return value;
    }
};
export function descendingComparator(a, b, orderBy) {
    var x = b[orderBy];
    var y = a[orderBy];
    if (x.toLowerCase() < y.toLowerCase()) {
        return -1;
    }
    if (x.toLowerCase() > y.toLowerCase()) {
        return 1;
    }
    return 0;
}
export function getComparator(order, orderBy) {
    return order === "desc"
        ? function (a, b) { return descendingComparator(a, b, orderBy); }
        : function (a, b) { return -descendingComparator(a, b, orderBy); };
}
export function stableSort(array, comparator) {
    var stabilizedThis = array.map(function (el, index) { return [el, index]; });
    stabilizedThis.sort(function (a, b) {
        var order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map(function (el) { return el[0]; });
}
