var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { initialDDTChartState, initialSharedView, } from "../Model/initialStates";
export var createDataSlice = function (set, get) { return ({
    sharedView: initialSharedView,
    setSharedView: function (view) {
        var src = get().sources.filter(function (obj) {
            var _a;
            return obj.data_source_url_slug == ((_a = view.source) === null || _a === void 0 ? void 0 : _a.data_source_url_slug);
        });
        set(function (state) { return (__assign(__assign({}, state), { sharedView: {
                id: view.id,
                source: src[0],
                viewDef: view.viewDef,
            } })); });
    },
    sortCount: 0,
    setSortCount: function (num) {
        set(function (state) { return (__assign(__assign({}, state), { sortCount: num })); });
    },
    sources: [],
    setSource: function (data) {
        set(function (state) { return (__assign(__assign({}, state), { sources: data })); });
    },
    views: [],
    setView: function (data) {
        set(function (state) { return (__assign(__assign({}, state), { views: data })); });
    },
    getSidebarData: function () {
        var data = [];
        var sources = get().sources;
        var views = get().views;
        sources.forEach(function (source) {
            if (source != undefined) {
                data.push({ source: source, views: [] });
            }
        });
        views.forEach(function (view) {
            if (view.data_source != undefined) {
                data[data.findIndex(function (x) { return x.source.data_source_url_slug == view.data_source; })].views.push(view);
            }
        });
        return data;
    },
    data: null,
    setTableData: function (data) {
        set(function (state) { return (__assign(__assign({}, state), { data: data })); });
    },
    visibleColumns: 0,
    setVisibleColumns: function (num) {
        set(function (state) { return (__assign(__assign({}, state), { visibleColumns: num })); });
    },
    visibleCategories: 0,
    setVisibleCategories: function (num) {
        set(function (state) { return (__assign(__assign({}, state), { visibleCategories: num })); });
    },
    chart: initialDDTChartState,
    setChart: function (chart) {
        set(function (state) { return (__assign(__assign({}, state), { chart: chart })); });
    },
    dataViewData: null,
    setDataViewData: function (dataViewData) {
        set(function (state) { return (__assign(__assign({}, state), { dataViewData: dataViewData })); });
    },
}); };
