import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorPageLayout } from "../Layouts/ErrorPageLayout";
import Title from "./Title";
import Desc from "./Desc";
import warningTriangle from "../../Assets/images/warning-triangle.svg";
import { APP_URL, IA_LOGIN_URL } from "../../Utils/constants";
import useStore from "../../Store/store";
var DefaultError = function (_a) {
    var _b, _c;
    var error = _a.error, insperaLogin = _a.insperaLogin;
    var _d = useStore(), marketplaceId = _d.marketplaceId, domain = _d.domain;
    var ia_login_url = new URL(IA_LOGIN_URL);
    var url = insperaLogin
        ? domain == ia_login_url.hostname
            ? "".concat(IA_LOGIN_URL, "?marketplaceId=").concat(marketplaceId, "&returnTo=").concat(APP_URL, "/auth")
            : "https://".concat(domain, "/admin?returnTo=").concat(APP_URL, "/auth")
        : APP_URL;
    var requestId = (_b = error["ddt-api-request-id"]) !== null && _b !== void 0 ? _b : undefined;
    return (_jsxs(ErrorPageLayout, { children: [_jsx(Title, { children: (_c = error.title) !== null && _c !== void 0 ? _c : "An error occurred" }), _jsx(Desc, { src: warningTriangle, alt: "Warning", desc: error.message, requestId: requestId, url: url })] }));
};
export default DefaultError;
