var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem, Select } from "@inspera/component-library";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchUserItemBanks } from "./utils";
import useStore from "../../../../../Store/store";
import _ from "lodash";
var ItemBankSelector = function () {
    var _a = useStore(), token = _a.token, setDataViewQueryOptions = _a.setDataViewQueryOptions, dataViewQueryOptions = _a.dataViewQueryOptions;
    //key-value pair of itemBankName,itemBankId
    var _b = useState([]), itemBanks = _b[0], setItemBanks = _b[1];
    var _c = useState(""), selectedItemBank = _c[0], setSelectedItemBank = _c[1];
    useEffect(function () {
        fetchUserItemBanks(setItemBanks, token);
    }, []);
    var itemBankMenuList = function () {
        var options = [];
        if (itemBanks.length > 0) {
            for (var _i = 0, itemBanks_1 = itemBanks; _i < itemBanks_1.length; _i++) {
                var itemBank = itemBanks_1[_i];
                options.push(_jsx(MenuItem, __assign({ sx: {
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#404040",
                        lineHeight: "20px",
                        padding: "13px",
                    }, value: itemBank.id }, { children: itemBank.name }), "".concat(itemBank.name, "-").concat(itemBank.id)));
            }
        }
        return options;
    };
    var handleItemBankSelection = function (e) {
        setSelectedItemBank(e.target.value);
        var newDataViewQueryOptions = _.cloneDeep(dataViewQueryOptions);
        if (newDataViewQueryOptions != null) {
            newDataViewQueryOptions.itemBankId = e.target.value;
            setDataViewQueryOptions(newDataViewQueryOptions);
        }
    };
    return (_jsxs(Box, __assign({ sx: { padding: "17px  17px 0px 17px", width: "227px" } }, { children: [_jsx(Typography, __assign({ variant: "body2", sx: {
                    color: "#595959",
                    marginBottom: "6px",
                } }, { children: "Item bank" })), _jsx(Select, __assign({ variant: "outlined", value: selectedItemBank, displayEmpty: true, renderValue: selectedItemBank !== ""
                    ? undefined
                    : function () { return (_jsx(Typography, __assign({ sx: {
                            color: "#595959",
                            fontSize: "14px",
                            margin: "0px",
                        } }, { children: "Select item bank" }))); }, sx: {
                    width: "227px",
                }, onChange: handleItemBankSelection }, { children: itemBankMenuList() }))] })));
};
export default ItemBankSelector;
