var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch, Tag, Tooltip } from "@inspera/component-library";
import { Box, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography, } from "@mui/material";
import { ModeType } from "../../Model/model";
import useStore from "../../Store/store";
import { InfoOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import _ from "lodash";
import { createJsonFilters } from "../FilterWizard/Filters/Filter/ESFilterToJson";
var Datamenu = function (_a) {
    var metadata = _a.metadata;
    var _b = useState(false), deduplicateStatus = _b[0], setDeduplicateStatus = _b[1];
    var _c = useStore(), visibleColumns = _c.visibleColumns, visibleCategories = _c.visibleCategories, mode = _c.mode, setModeType = _c.setModeType, columns = _c.columns, columnCategories = _c.columnCategories, queryOptions = _c.queryOptions, fetchVirtualColumns = _c.fetchVirtualColumns, virtualColumnsMetadata = _c.virtualColumnsMetadata, setDeduplicate = _c.setDeduplicate, restoreState = _c.restoreState, setRestoreState = _c.setRestoreState, deduplicate = _c.deduplicate, setQueryOptions = _c.setQueryOptions;
    var totalCol = fetchVirtualColumns
        ? virtualColumnsMetadata.length + columns.length
        : columns.length;
    var colLabel = "".concat(visibleColumns, "/").concat(totalCol, " columns");
    var catLabel = "".concat(visibleCategories, "/").concat(columnCategories.length, " categories");
    var filterCount = createJsonFilters(queryOptions.filters).length;
    useEffect(function () {
        if (deduplicate != deduplicateStatus)
            setDeduplicateStatus(deduplicate);
    }, [deduplicate]);
    var handleToggleChange = function (event, newAlignment) {
        if (newAlignment == null)
            return;
        setModeType(newAlignment);
    };
    var toggleBtnStyle = {
        textTransform: "none",
        "&.MuiButtonBase-root, &.MuiToggleButton-root": {
            borderColor: "#949494",
            padding: "12px 16px",
        },
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: "#404040",
        },
    };
    var handleDedpulicate = function () {
        var newDeduplicate = !deduplicateStatus;
        setDeduplicateStatus(newDeduplicate);
        setDeduplicate(newDeduplicate);
        var newQueryOption = _.cloneDeep(queryOptions);
        if (newDeduplicate) {
            var selectedCols = columns.map(function (col) {
                if (col.show)
                    return col.column_name;
            });
            selectedCols = selectedCols.filter(Boolean);
            newQueryOption.deduplication_columns = selectedCols;
        }
        else {
            if (newQueryOption.deduplication_columns)
                delete newQueryOption.deduplication_columns;
        }
        setQueryOptions(newQueryOption);
        if (restoreState)
            setRestoreState(false);
    };
    return (_jsxs(Grid, __assign({ container: true, sx: {
            backgroundColor: "white",
        }, p: "13px 16px", justifyContent: "space-between", wrap: "nowrap" }, { children: [_jsxs(Box, __assign({ sx: { display: "flex" } }, { children: [_jsxs(ToggleButtonGroup, __assign({ sx: {
                            ml: "0px",
                            height: "44px",
                            "&.MuiToggleButton-standard": {
                                border: "1px soild",
                                borderColor: "#949494",
                            },
                        }, onChange: handleToggleChange, exclusive: true, value: mode.active }, { children: [_jsx(ToggleButton, __assign({ value: ModeType.data, disabled: !mode.availableModes.includes(ModeType.data), tabIndex: 3, sx: toggleBtnStyle }, { children: "Data" })), _jsx(ToggleButton, __assign({ disabled: !mode.availableModes.includes(ModeType.agg), value: ModeType.agg, sx: toggleBtnStyle }, { children: "Aggregation" })), _jsx(ToggleButton, __assign({ disabled: !mode.availableModes.includes(ModeType.vis), value: ModeType.vis, tabIndex: 5, sx: toggleBtnStyle }, { children: "Visualization" }))] })), _jsxs(Box, __assign({ display: "flex", alignItems: "center", marginLeft: "20px" }, { children: [_jsx(Switch, { checked: deduplicateStatus, onClick: handleDedpulicate }), _jsx(Tooltip, __assign({ title: "Show only distinct rows. Results might be slower when it is applied", placement: "top" }, { children: _jsxs(Typography, __assign({ sx: { margin: "0 0 0 8px", fontSize: "16px", cursor: "pointer" } }, { children: ["Distinct rows", _jsx(InfoOutlined, { sx: { width: "15px", height: "15px", marginBottom: "4px" } })] })) }))] }))] })), _jsxs(Stack, __assign({ alignItems: "center", direction: "row", spacing: 2 }, { children: [_jsx(Tag, { children: filterCount <= 1
                            ? "".concat(filterCount, " filter")
                            : "".concat(filterCount, " filters"), variant: "filled", color: "info" }), _jsx(Tag, { children: queryOptions.sort.length <= 1
                            ? "".concat(queryOptions.sort.length, " sort")
                            : "".concat(queryOptions.sort.length, " sorts"), variant: "filled", color: "pink" }), _jsx(Tag, { children: (metadata === null || metadata === void 0 ? void 0 : metadata.total_rows) >= 10000
                            ? "10000+ rows"
                            : (metadata === null || metadata === void 0 ? void 0 : metadata.total_rows) != null
                                ? (metadata === null || metadata === void 0 ? void 0 : metadata.total_rows) + " rows"
                                : "0 rows", variant: "filled", color: "success" }), _jsx(Tag, { children: catLabel, "aria-label": catLabel, variant: "filled", color: "warning" }), _jsx(Tag, { children: colLabel, "aria-label": colLabel, variant: "filled", color: "purple" })] }))] })));
};
export default Datamenu;
