import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorPageLayout } from "../Layouts/ErrorPageLayout";
import Desc from "./Desc";
import Title from "./Title";
import pageNotFound from "../../Assets/images/404.svg";
import { APP_URL } from "../../Utils/constants";
var PageNotFound = function (props) {
    var msg = "Something went wrong, we can't find the page you are looking for";
    return (_jsxs(ErrorPageLayout, { children: [_jsx(Title, { children: "Page not found" }), _jsx(Desc, { src: pageNotFound, alt: "Warning", desc: msg, url: APP_URL, btnText: "To Overview" })] }));
};
export default PageNotFound;
