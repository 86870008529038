var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, Stack } from "@mui/material";
import useStore from "../../../Store/store";
import { Input, MenuItem, Select } from "@inspera/component-library";
import { PresetModalCard } from "./PresetModalCard";
import { Search } from "@mui/icons-material";
import { useEffect, useState } from "react";
var PresetTable = function (_a) {
    var handleClose = _a.handleClose;
    var _b = useStore(), sources = _b.sources, presets = _b.presets;
    var _c = useState([]), filteredPresets = _c[0], setFilteredPresets = _c[1];
    var _d = useState(""), sourceSelected = _d[0], setSourceSelected = _d[1];
    var _e = useState(""), search = _e[0], setSearch = _e[1];
    useEffect(function () {
        setFilteredPresets(presets !== null && presets !== void 0 ? presets : []);
    }, [presets]);
    var handleSourceSelector = function (event) {
        var sourceSelected = event.target.value;
        var searchViewResult = getSearchedPresets(sourceSelected, search);
        setFilteredPresets(searchViewResult);
        setSourceSelected(sourceSelected);
    };
    var handleSeachInput = function (e) {
        var searchInput = e.target.value;
        var searchViewResult = getSearchedPresets(sourceSelected, searchInput);
        setFilteredPresets(searchViewResult);
        setSearch(searchInput);
    };
    var getSearchedPresets = function (source, search) {
        if (presets)
            return presets.filter(function (x) {
                //either name or description matches the search query
                if (source == "")
                    return (x.title.toUpperCase().includes(search.toUpperCase()) ||
                        x.description.toUpperCase().includes(search.toUpperCase()));
                else
                    return (source == x.parameters.data_source &&
                        (x.title.toUpperCase().includes(search.toUpperCase()) ||
                            x.description.toUpperCase().includes(search.toUpperCase())));
            });
        else
            return [];
    };
    return (_jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsxs(Box, __assign({ sx: { display: "flex" } }, { children: [_jsx(Typography, __assign({ sx: { fontSize: "18px", color: "#272727", fontWeight: "500" } }, { children: "Presets" })), _jsx(Typography, __assign({ sx: {
                            borderRadius: "50%",
                            width: "16px",
                            height: "16px",
                            padding: "8px",
                            background: "rgba(0, 0, 0, 0.08)",
                            color: "#262626",
                            margin: "0 0 0 5px",
                            textAlign: "center",
                            lineHeight: "1",
                        } }, { children: presets ? presets.length : 0 }))] })), _jsxs(Box, { children: [_jsx(Input, { startIcon: _jsx(Search, { style: { color: "#595959" } }), placeholder: "Search for...", onChange: handleSeachInput }), _jsxs(Select, __assign({ sx: { marginLeft: "10px" }, value: sourceSelected, onChange: handleSourceSelector, displayEmpty: true, renderValue: sourceSelected !== ""
                            ? undefined
                            : function () { return (_jsx(Typography, __assign({ component: "span", fontSize: "14px", color: "#595959" }, { children: "Source" }))); } }, { children: [_jsx(MenuItem, __assign({ value: "" }, { children: _jsx(Typography, __assign({ sx: {
                                        color: "#595959",
                                        fontSize: "14px",
                                        margin: "0px",
                                    } }, { children: "All" })) })), sources
                                .sort(function (a, b) {
                                return a.data_source_display_order_rank -
                                    b.data_source_display_order_rank;
                            })
                                .map(function (source, index) { return (_jsx(MenuItem, __assign({ value: source.data_source_url_slug }, { children: _jsx(Typography, __assign({ sx: {
                                        color: "#595959",
                                        fontSize: "14px",
                                        margin: "0px",
                                    } }, { children: source.human_friendly_data_source_name })) }), index)); })] }))] }), _jsx(Typography, __assign({ sx: { fontSize: "16px", color: "#272727" } }, { children: "List of presets" })), _jsx(Box, __assign({ sx: { display: "flex", flexWrap: "wrap" } }, { children: filteredPresets.map(function (preset, i) {
                    return (_jsx(PresetModalCard, { handleClose: handleClose, preset: preset }, i));
                }) }))] })));
};
export default PresetTable;
