var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import graphUp from "../../../../Assets/images/graph-up.svg";
import { Box, Typography, Popper, ClickAwayListener, Snackbar, Alert, FormControl, Icon, } from "@mui/material";
import FocusTrap from "@mui/base/TrapFocus";
import { CloseButton, Button, MenuItem, Select, InputLabel, } from "@inspera/component-library";
import { ModeType, } from "../../../../Model/model";
import useStore from "../../../../Store/store";
import { addBarGraph, addLineGraph, addPieGraph, setDataset, } from "../../../Dashboard/Table/Visualisation/utils";
import { initialDDTChartState } from "../../../../Model/initialStates";
import _ from "lodash";
import { hashCode } from "../../../../Utils/util";
var VisualisationMenu = function (_a) {
    var variableToFriendlyColumn = _a.variableToFriendlyColumn;
    var _b = useState(false), vizApplied = _b[0], setVizApplied = _b[1];
    var _c = useStore(), current = _c.current, visibleColumns = _c.visibleColumns, mode = _c.mode, data = _c.data, chart = _c.chart, setChart = _c.setChart, setModeType = _c.setModeType, addModes = _c.addModes, restoreState = _c.restoreState, removeModes = _c.removeModes, setRestoreState = _c.setRestoreState, userId = _c.userId;
    var _d = useState(false), disable = _d[0], setDisable = _d[1];
    var _e = useState(null), anchorEl = _e[0], setAnchorEl = _e[1];
    var _f = useState(""), warning = _f[0], setWarning = _f[1];
    var open = Boolean(anchorEl);
    var id = open ? "simple-popper" : undefined;
    var _g = useState("bar"), chartSelected = _g[0], setChartSelected = _g[1];
    var _h = useState(""), xSelected = _h[0], setXSelected = _h[1];
    var _j = useState(""), ySelected = _j[0], setYSelected = _j[1];
    var graphMap = { bar: "Bar chart", pie: "Pie chart", line: "Line chart" };
    useEffect(function () {
        if ((current === null || current === void 0 ? void 0 : current.viewDef) != null || (current === null || current === void 0 ? void 0 : current.preset) || restoreState)
            return;
        setChart(initialDDTChartState);
        setVizApplied(false);
    }, [current === null || current === void 0 ? void 0 : current.source]);
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        var hashUserId = hashCode(String(userId));
        var savedState = localStorage.getItem("options".concat(hashUserId));
        var options;
        if (savedState &&
            restoreState &&
            ((_a = JSON.parse(savedState).parameters.ddt_frontend
                .visualization) === null || _a === void 0 ? void 0 : _a.chart)) {
            options = (_b = JSON.parse(savedState).parameters.ddt_frontend
                .visualization) === null || _b === void 0 ? void 0 : _b.chart;
        }
        else if ((current === null || current === void 0 ? void 0 : current.viewDef) == null ||
            !((_e = (_d = (_c = current === null || current === void 0 ? void 0 : current.viewDef) === null || _c === void 0 ? void 0 : _c.parameters) === null || _d === void 0 ? void 0 : _d.ddt_frontend.visualization) === null || _e === void 0 ? void 0 : _e.chart)) {
            // resets here
            setChart(initialDDTChartState);
            setVizApplied(false);
            return;
        }
        else {
            options = JSON.parse(JSON.stringify(current.viewDef.parameters.ddt_frontend.visualization.chart));
        }
        setChart(options);
        setChartSelected(options.series.type);
        setVizApplied(true);
        setXSelected(options.x);
        setYSelected(options.y);
    }, [current === null || current === void 0 ? void 0 : current.viewDef]);
    useEffect(function () {
        var _a;
        if (current === null || current === void 0 ? void 0 : current.preset) {
            var options = void 0;
            if (!((_a = current === null || current === void 0 ? void 0 : current.preset.parameters.ddt_frontend) === null || _a === void 0 ? void 0 : _a.visualization)) {
                // resets here
                setVizApplied(false);
                return;
            }
            else {
                options = JSON.parse(JSON.stringify(current.preset.parameters.ddt_frontend.visualization.chart));
            }
            setChart(options);
            setChartSelected(options.series.type);
            setVizApplied(true);
            setXSelected(options.x);
            setYSelected(options.y);
        }
    }, [current === null || current === void 0 ? void 0 : current.preset]);
    useEffect(function () {
        if (!data)
            return;
        //If agg data is null/empty, vis isn't applicable
        if (!(data === null || data === void 0 ? void 0 : data.aggs) || (data === null || data === void 0 ? void 0 : data.aggs.rows.length) == 0) {
            removeModes(ModeType.vis);
            setChart(initialDDTChartState);
            if (mode.active == ModeType.vis)
                setModeType(ModeType.data);
            setVizApplied(false);
            return;
        }
        //If aggregation data changes while vis is applied, re-apply vis with new data
        if ((data === null || data === void 0 ? void 0 : data.aggs.rows.length) > 0 &&
            mode.availableModes.includes(ModeType.vis)) {
            // setTabSwitching(true);
            //Todo: refactor
            var newChart = _.cloneDeep(chart);
            if (!newChart) {
                return;
            }
            switch (chartSelected) {
                case "bar":
                    addBarGraph(newChart, "group", ySelected);
                    break;
                case "pie":
                    addPieGraph(newChart, "group", ySelected);
                    break;
                case "line":
                    addLineGraph(newChart, "group", ySelected);
                    break;
                default:
                    break;
            }
            var dimensions = Object.keys(data === null || data === void 0 ? void 0 : data.aggs.rows[0]);
            newChart.y = ySelected;
            newChart.title = "Add title";
            newChart.description = "Add description";
            setDataset(newChart, dimensions, data === null || data === void 0 ? void 0 : data.aggs.rows, data === null || data === void 0 ? void 0 : data.aggs.grouped_by);
            setChart(newChart);
            addModes(ModeType.vis);
            setVizApplied(true);
        }
    }, [data === null || data === void 0 ? void 0 : data.aggs]);
    var handleClose = function () { return setAnchorEl(null); };
    var handleCloseSnack = function () {
        setDisable(false);
    };
    var handleClick = function (event) {
        if (visibleColumns == 0) {
            setDisable(true);
            setWarning("Select some columns and apply aggregation before applying visualisation");
            return;
        }
        if (!mode.availableModes.includes(ModeType.agg)) {
            setDisable(true);
            setWarning("Apply an aggregation before using visualisation");
            return;
        }
        if (!(data === null || data === void 0 ? void 0 : data.aggs) || data.aggs.length == 0) {
            setDisable(true);
            setWarning("Apply valid aggregations before using visualisation");
        }
        if (!(data === null || data === void 0 ? void 0 : data.aggs.grouped_by)) {
            setDisable(true);
            setWarning("Use group by in aggregation before using visualisation");
            return;
        }
        if (data && data.aggs) {
            if (ySelected == "" ||
                (!data.aggs.agg_names.includes(ySelected) && ySelected != "count"))
                setYSelected(data === null || data === void 0 ? void 0 : data.aggs.agg_names[0]);
            if (xSelected == "" || !data.aggs.grouped_by.includes(xSelected))
                setXSelected(data === null || data === void 0 ? void 0 : data.aggs.grouped_by[0]);
        }
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    var handleSave = function () {
        var newChart = _.cloneDeep(chart);
        if (!newChart) {
            return;
        }
        switch (chartSelected) {
            case "bar":
                addBarGraph(newChart, "group", ySelected);
                break;
            case "pie":
                addPieGraph(newChart, "group", ySelected);
                break;
            case "line":
                addLineGraph(newChart, "group", ySelected);
                break;
            default:
                break;
        }
        var dimensions = Object.keys(data === null || data === void 0 ? void 0 : data.aggs.rows[0]);
        newChart.labelXAxis = variableToFriendlyColumn[xSelected];
        newChart.labelYAxis = ySelected;
        delete newChart.visualMap;
        delete newChart.color;
        newChart.y = ySelected;
        newChart.title = "Add title";
        newChart.description = "Add description";
        setDataset(newChart, dimensions, data === null || data === void 0 ? void 0 : data.aggs.rows, data === null || data === void 0 ? void 0 : data.aggs.grouped_by);
        if (restoreState)
            setRestoreState(false);
        setChart(newChart);
        addModes(ModeType.vis);
        setModeType(ModeType.vis);
        setVizApplied(true);
        handleClose();
    };
    var handleReset = function () {
        setChartSelected("bar");
        removeModes(ModeType.vis);
        setVizApplied(false);
        setChart(initialDDTChartState);
        setModeType(ModeType.agg);
        handleClose();
        return;
    };
    var handleChangeGraph = function (e) {
        setChartSelected(e.target.value);
    };
    var handleChangeX = function (e) {
        setXSelected(e.target.value);
    };
    var handleChangeY = function (e) {
        setYSelected(e.target.value);
    };
    var xAxisColumns = function () {
        var options = [];
        if (data && data.aggs && data.aggs.grouped_by) {
            data.aggs.grouped_by.map(function (group) {
                options.push(_jsx(MenuItem, __assign({ sx: {
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#404040",
                        lineHeight: "20px",
                        padding: "13px",
                    }, value: group }, { children: variableToFriendlyColumn[group] }), group));
            });
        }
        return options;
    };
    var yAxisColumns = function () {
        var options = [];
        options.push(_jsx(MenuItem, __assign({ sx: {
                fontSize: "16px",
                fontWeight: "400",
                color: "#404040",
                lineHeight: "20px",
                padding: "13px",
            }, value: "count" }, { children: "Count" }), "count"));
        if (data && data.aggs) {
            data.aggs.agg_names.map(function (aggHead) {
                options.push(_jsx(MenuItem, __assign({ sx: {
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#404040",
                        lineHeight: "20px",
                        padding: "13px",
                    }, value: aggHead }, { children: aggHead }), aggHead));
            });
        }
        return options;
    };
    var selectStyle = {
        fontSize: "16px",
        fontWeight: "400",
        color: "#404040",
        lineHeight: "20px",
        padding: "13px",
    };
    return (_jsx(ClickAwayListener, __assign({ onClickAway: handleClose, mouseEvent: "onMouseUp" }, { children: _jsxs(Box, __assign({ sx: { padding: "17px  17px 0px 17px", width: "227px" } }, { children: [_jsx(Typography, __assign({ sx: {
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#595959",
                        marginBottom: "6px",
                    } }, { children: "Visualisation" })), _jsxs(Typography, __assign({ onClick: handleClick, variant: "body2", role: "button", onKeyDown: function (event) {
                        event.key == "Enter" && handleClick(event);
                    }, tabIndex: 0, sx: {
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "12px 17px",
                        boxSizing: "border-box",
                        cursor: "pointer",
                        width: "227px",
                        height: "44px",
                        alignItems: "center",
                        border: "1px solid #949494",
                        borderRadius: "3px",
                        fontWeight: "400",
                        "&:hover": {
                            boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(0deg 0% 74%) 0 0 0 3px",
                        },
                        "&:active": {
                            boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(210deg 100% 35%) 0 0 0 2px, hsl(210deg 100% 85%) 0 0 0 4px",
                        },
                        "&:focus-visible": {
                            boxShadow: "rgb(255 255 255) 0 0 0 1px, hsl(210deg 100% 35%) 0 0 0 2px, hsl(210deg 100% 85%) 0 0 0 4px",
                            outline: "none",
                        },
                    } }, { children: [_jsx(Typography, __assign({ component: "span", variant: "body2", sx: { color: "#404040" } }, { children: (chart === null || chart === void 0 ? void 0 : chart.series.type) && vizApplied
                                ? graphMap[chart === null || chart === void 0 ? void 0 : chart.series.type]
                                : "Select Visualisation" })), _jsx(Icon, { children: _jsx("img", { src: graphUp, alt: "grid icon", "aria-hidden": "true" }) })] })), _jsx(Popper, __assign({ id: id, open: open, anchorEl: anchorEl, placement: "auto-start", modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 20],
                            },
                        },
                    ], sx: { zIndex: "1000" } }, { children: _jsx(FocusTrap, __assign({ open: open }, { children: _jsx(Box, __assign({ sx: {
                                border: "1px solid #EDEDED",
                                width: "456px",
                                background: "#FFFFFF",
                                boxShadow: "1",
                            }, tabIndex: -1 }, { children: _jsxs(Box, __assign({ sx: { margin: "24px" } }, { children: [_jsxs(Box, __assign({ sx: {
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0 2px",
                                        }, className: "header" }, { children: [_jsx(Typography, __assign({ sx: {
                                                    color: "#272727",
                                                    fontWeight: "400",
                                                    fontSize: "16px",
                                                    marginBottom: 0,
                                                    alignSelf: "center",
                                                } }, { children: "Visualisation" })), _jsx(CloseButton, { size: "small", "aria-label": "close", onClick: handleClose })] })), _jsx(Box, __assign({ className: "chartSelection", sx: { margin: "24px 0 17px 0" } }, { children: _jsxs(FormControl, { children: [_jsx(InputLabel, __assign({ htmlFor: "chart-label" }, { children: "Chart type" })), _jsxs(Select, __assign({ onChange: function (e) { return handleChangeGraph(e); }, sx: {
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        width: "198px",
                                                    }, value: chartSelected, id: "chart-label" }, { children: [_jsx(MenuItem, __assign({ sx: selectStyle, value: "pie" }, { children: "Pie Chart" })), _jsx(MenuItem, __assign({ sx: selectStyle, value: "line" }, { children: "Line graph" })), _jsx(MenuItem, __assign({ sx: selectStyle, value: "bar" }, { children: "Bar graph" }))] }))] }) })), _jsxs(Box, __assign({ className: "XYselection", sx: {
                                            display: "flex",
                                            justifyContent: "space-between",
                                            marginBottom: "26px",
                                        } }, { children: [_jsxs(FormControl, { children: [_jsx(InputLabel, __assign({ htmlFor: "x-label" }, { children: "x axis" })), _jsx(Select, __assign({ onChange: function (e) { return handleChangeX(e); }, sx: {
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            width: "198px",
                                                        }, value: xSelected, id: "x-label" }, { children: xAxisColumns() }))] }), _jsxs(FormControl, { children: [_jsx(InputLabel, __assign({ htmlFor: "y-label" }, { children: "y axis" })), _jsx(Select, __assign({ onChange: function (e) { return handleChangeY(e); }, sx: {
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            maxWidth: "192px",
                                                        }, value: ySelected, id: "y-label" }, { children: yAxisColumns() }))] })] })), _jsxs(Box, __assign({ sx: {
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "185px",
                                            marginRight: "10px",
                                        }, className: "buttonsFooter" }, { children: [_jsx(Button, __assign({ onClick: handleReset, variant: "contained", color: "secondary" }, { children: "Reset" })), _jsx(Button, __assign({ onClick: handleSave, variant: "contained", color: "primary" }, { children: "Apply" }))] }))] })) })) })) })), _jsx(Snackbar, __assign({ open: disable, autoHideDuration: 6000, onClose: handleCloseSnack }, { children: _jsx(Alert, __assign({ variant: "filled", onClose: handleCloseSnack, severity: "error" }, { children: warning })) }))] })) })));
};
export default VisualisationMenu;
