var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect, useState } from "react";
import { ModeType } from "../Model/model";
import useStore from "../Store/store";
import { initialVirtualColumnMetadata } from "../Model/initialStates";
import { hashCode } from "../Utils/util";
export var useFetchColumns = function () {
    var _a = useState(false), isLoading = _a[0], setLoading = _a[1];
    var _b = useStore(), current = _b.current, setPage = _b.setPage, setQueryOptions = _b.setQueryOptions, setAggHeaders = _b.setAggHeaders, deepLinkQuery = _b.deepLinkQuery, setDeepLinkQuery = _b.setDeepLinkQuery, setMode = _b.setMode, setTabSwitching = _b.setTabSwitching, setFetchVirtualColumns = _b.setFetchVirtualColumns, setVirtualColumnsMetadata = _b.setVirtualColumnsMetadata, restoreState = _b.restoreState, userId = _b.userId, setDeduplicate = _b.setDeduplicate;
    useEffect(function () {
        var fetchColumns = function () { return __awaiter(void 0, void 0, void 0, function () {
            var options, hashUserId, sessionData, restoreSessionData;
            return __generator(this, function (_a) {
                options = null;
                setLoading(true);
                if ((current === null || current === void 0 ? void 0 : current.source) != null) {
                    hashUserId = hashCode(String(userId));
                    sessionData = localStorage.getItem("options".concat(hashUserId));
                    restoreSessionData = sessionData
                        ? JSON.parse(sessionData)
                        : null;
                    setTabSwitching(false);
                    // Check whether session has to be restored or it's a view
                    if (restoreState)
                        options = restoreSessionData.parameters;
                    else if (current.viewDef)
                        options = current.viewDef.parameters;
                    else if (current.preset)
                        options = current.preset.parameters;
                    setTabSwitching(false);
                    if (options)
                        loadDDTMetadata(options);
                    else {
                        setDefaultDDTMetadata(current.source);
                    }
                }
                return [2 /*return*/];
            });
        }); };
        fetchColumns();
        setPage(1);
        setLoading(false);
    }, [current]);
    var loadDDTMetadata = function (options) {
        //set dedpulicate option
        var _a;
        setDeduplicate((_a = options.ddt_frontend.deduplicate) !== null && _a !== void 0 ? _a : false);
        // if ("deduplicate" in options.ddt_frontend) {
        //   setDeduplicate(options.ddt_frontend.deduplicate ?? false);
        // } else setDeduplicate(false);
        //Set Query Options
        setQueryOptions(options.body);
        //Set Mode
        if (options.ddt_frontend.mode) {
            setMode(options.ddt_frontend.mode);
            //For backward compatability, display_aggregation is deprecated
        }
        else if (options.ddt_frontend.display_aggregation) {
            setMode({
                active: ModeType.agg,
                availableModes: [ModeType.data, ModeType.agg],
            });
        }
        else {
            setMode({
                active: ModeType.data,
                availableModes: [ModeType.data],
            });
        }
        // SetDeeplink
        // if (deepLinkQuery) {
        //   columns.forEach((col) => {
        //     col.show = true;
        //     varibleHumanCol[col.column_name] = col.human_friendly_column_name;
        //   });
        //   setDeepLinkQuery(false);
        // }
        //Set agg headers
        if (options.ddt_frontend.agg_headers)
            setAggHeaders(options === null || options === void 0 ? void 0 : options.ddt_frontend.agg_headers);
        else
            setAggHeaders(null);
    };
    var setDefaultDDTMetadata = function (source) {
        if ((source === null || source === void 0 ? void 0 : source.data_source_url_slug) != "irt_value") {
            setFetchVirtualColumns(false);
            setVirtualColumnsMetadata(initialVirtualColumnMetadata);
        }
        // if (deepLinkQuery) {
        //   setDeepLinkQuery(false);
        // } else
        setQueryOptions({ filters: [], size: 10, sort: [] });
        //Set Mode
        setMode({
            active: ModeType.data,
            availableModes: [ModeType.data],
        });
        //Set agg headers
        setAggHeaders(null);
        var hashUserId = hashCode(String(userId));
        localStorage.removeItem("options".concat(hashUserId));
    };
    return [isLoading];
};
