var aggRowsToJson = function (aggRows, queryOptions, groupByColumns, variableToFriendlyColumn) {
    var copyAggRows = JSON.parse(JSON.stringify(aggRows));
    var newQuery = JSON.parse(JSON.stringify(queryOptions));
    newQuery.aggs = { group_by: groupByColumns, aggs: {} };
    var _loop_1 = function (i) {
        var obj = copyAggRows[i];
        var aggObj = {
            field_name: obj.functionColumn,
            function: obj.function.toLowerCase().replace(" ", "_"),
        };
        if (obj.distinctByColumn != "None")
            aggObj.distinct_by = obj.distinctByColumn;
        var functionName = obj.function.charAt(0).toUpperCase() +
            obj.function.slice(1).replace("_", " ");
        var aggName = "".concat(functionName, " (").concat(variableToFriendlyColumn[obj.functionColumn], ")");
        newQuery.aggs.aggs[aggName] = aggObj;
        if (obj.sortOrder != "None") {
            var exist_1 = 0;
            newQuery.sort.forEach(function (row) {
                if (row.agg_name == aggName) {
                    row.order = obj.sortOrder;
                    exist_1 = 1;
                }
            });
            if (!exist_1) {
                newQuery.sort.push({
                    agg_name: aggName,
                    order: obj.sortOrder,
                });
            }
        }
    };
    for (var i = 0; i < copyAggRows.length; i++) {
        _loop_1(i);
    }
    return newQuery;
};
export default aggRowsToJson;
