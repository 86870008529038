import { createTheme } from "@mui/material";
export var theme = createTheme({
    typography: {
        fontFamily: ["Inter", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#004080",
        },
        secondary: {
            main: "#F7F7F7",
        },
    },
});
